import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../const';
import useAuth from '../../hooks/providers/useAuth';
import useBanner from '../../hooks/providers/useBanner';
import usePayment from '../../hooks/providers/usePayment';
import useProfile from '../../hooks/providers/useProfile';
import SubscriptionBanner from './SubscriptionBanner';

const getTrialEndingSoonBannerText = numberOfDaysLeft => {
  if (numberOfDaysLeft > 1) {
    return ` Profitez de votre essai gratuit pendant encore ${numberOfDaysLeft} jours.`;
  }

  return `Votre période d'essai expire ${numberOfDaysLeft === 1 ? 'demain' : "aujourd'hui"}.`;
};

// eslint-disable-next-line complexity
const TrialBanner = () => {
  const {shouldDisplayTrialExpiredBanner, shouldDisplayTrialInProgressBanner} = useBanner();
  const {setIsContactFormOpen} = useAuth();
  const {isPartnerPolicy} = useProfile();
  const {numberOfTrialDaysLeft} = usePayment();

  if (!shouldDisplayTrialExpiredBanner && !shouldDisplayTrialInProgressBanner) return null;

  const isExpiredBanner = shouldDisplayTrialExpiredBanner;

  const title = isExpiredBanner ? "Version d'essai expirée." : "Version d'essai.";
  const severity = isExpiredBanner ? 'warning' : 'success';

  const linkText = isPartnerPolicy ? 'Contactez-nous pour bénéficier de votre tarif préférentiel' : 'Sélectionner un abonnement';
  const linkHref = isPartnerPolicy ? '#' : APP_ROUTES.subscription;
  const linkOnclick = isPartnerPolicy ? () => setIsContactFormOpen(true) : null;

  return (
    <SubscriptionBanner title={title} severity={severity}>
      {!isExpiredBanner && getTrialEndingSoonBannerText(numberOfTrialDaysLeft)}&nbsp;
      <Link to={linkHref} onClick={linkOnclick}>
        {linkText}
      </Link>
      {isPartnerPolicy && isExpiredBanner && ' pour profiter pleinement des services de Drivn'}
    </SubscriptionBanner>
  );
};

export default TrialBanner;
