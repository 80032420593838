import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {IconButton, Tooltip} from '@mui/material';
import {grey} from '@mui/material/colors';
import {bool, func, string} from 'prop-types';

import useCreateReportOrWorkspaceButton from '../../hooks/components/useCreateReportOrWorkspaceButton';
import useProjects from '../../hooks/providers/useProjects';
import Button from '../form/buttons/Button';

// eslint-disable-next-line complexity
const CreateReportOrWorkspaceButton = ({maxLimitReached, disabled, onClick, tooltipTitle, label, size = 'small'}) => {
  const {
    responsive,
    handleClick,
    tooltipTitle: updatedTooltipTitle
  } = useCreateReportOrWorkspaceButton({
    maxLimitReached,
    onClick,
    tooltipTitle
  });
  const {isGuestMode} = useProjects();

  if (isGuestMode) return null;

  const disabledStyle = {
    border: `2px solid ${grey[500]} !important`,
    background: 'white !important',
    color: `${grey[500]} !important`
  };

  if (responsive) {
    return (
      <Tooltip placement="left-end" enterTouchDelay={100} title={updatedTooltipTitle}>
        <span>
          <IconButton disabled={maxLimitReached || disabled} sx={{mr: 1.5}} onClick={handleClick} edge="end" aria-label={label}>
            <AddCircleOutlinedIcon sx={{fontSize: 30}} color={maxLimitReached || disabled ? 'inherit' : 'primary'} />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <Button
      hasTooltip={maxLimitReached}
      tooltip={updatedTooltipTitle}
      size={size}
      onClick={handleClick}
      disabled={maxLimitReached || disabled}
      startIcon={<AddRoundedIcon />}
      color={maxLimitReached ? 'inherit' : 'primary'}
      variant={maxLimitReached ? 'contained' : 'outlined'}
      sx={{
        whiteSpace: 'nowrap',
        mr: 1,
        background: 'white',
        ...((maxLimitReached || disabled) && disabledStyle)
      }}
    >
      {label}
    </Button>
  );
};

CreateReportOrWorkspaceButton.defaultProps = {
  size: 'small',
  onClick: null,
  disabled: false
};

CreateReportOrWorkspaceButton.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool,
  maxLimitReached: bool.isRequired,
  onClick: func,
  tooltipTitle: string.isRequired,
  label: string.isRequired,
  size: string
};

export default CreateReportOrWorkspaceButton;
