import {useEffect, useState} from 'react';

import {Grid} from '@mui/material';
import {oneOfType, node, func} from 'prop-types';

import Spinner from '../components/spinner/Spinner';
import useAuth from '../hooks/providers/useAuth';
import useLoading from '../hooks/providers/useLoading';
import usePayment from '../hooks/providers/usePayment';

const RequireProducts = ({children}) => {
  const {getProductsList, products, productsLoading} = usePayment();
  const {globalLoading, setCompletedApiCalls} = useLoading();
  const auth = useAuth();

  useEffect(() => {
    (async () => {
      if (products?.length < 1 && !productsLoading) {
        await getProductsList();
        setCompletedApiCalls(prevCompleted => prevCompleted + 1);
      }
    })();
  }, [auth?.user?.tokenAad]);

  if ((products?.length < 1 || productsLoading) && !globalLoading) {
    return (
      <Grid container alignItems="center" sx={{height: 'calc(100vh - 64px)'}}>
        <Spinner withoutText isLoading />
      </Grid>
    );
  }

  if (products?.length < 1 || productsLoading) return null;

  return children;
};

RequireProducts.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default RequireProducts;
