import {Alert, Box, Grid, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';

import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button';
import SirenTextField from '../../form/SirenTextField';
import UploadDropzone from '../../upload/UploadDropzone';
import InputLabel from './InputLabel';

const ManualConnectorForm = ({projectCreatedName, fileUploadError, setManualProjectFiles, isSubmitting, handleSubmit}) => {
  const {isSirenValid, handleSirenChange, projectCreated} = useProjects();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={projectCreated ? 12 : 10}>
          <SirenTextField
            disabled={projectCreated}
            onChange={handleSirenChange}
            onKeyDown={async e => {
              e.stopPropagation(); // see : https://github.com/mui/material-ui/issues/19116
              if (e.key === 'Enter' && isSirenValid) {
                await handleSubmit();
              }
            }}
          />
        </Grid>
        {!projectCreated && (
          <Grid item xs={2}>
            <Button disabled={isSubmitting || !isSirenValid} onClick={handleSubmit} color="primary" variant="contained">
              Valider
            </Button>
          </Grid>
        )}
      </Grid>

      {projectCreated && (
        <Box mt={1.5} mb={1}>
          <Alert severity="success">
            Votre projet {projectCreatedName} est créé, vous pouvez maintenant importer vos données. Vous pourrez également les ajouter plus tard depuis votre rapport DeFi Gestion ou depuis la carte
            du projet dans "mes projets"
          </Alert>
          <Typography fontSize={13} mb={0.5} mt={2} fontFamily="InstrumentSansBold">
            Glisser-déposer un ou plusieurs fichiers FEC.{' '}
            <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000027804775" target="_blank" rel="noreferrer">
              En savoir plus sur le format FEC
            </a>
          </Typography>
          <UploadDropzone error={fileUploadError} onFilesUpload={setManualProjectFiles} />
        </Box>
      )}
    </>
  );
};

ManualConnectorForm.defaultProps = {
  projectCreatedName: ''
};

ManualConnectorForm.propTypes = {
  projectCreatedName: string,
  fileUploadError: string.isRequired,
  isSubmitting: bool.isRequired,
  setManualProjectFiles: func.isRequired,
  handleSubmit: func.isRequired
};

export default ManualConnectorForm;
