import {CloudUpload, Refresh, Update, UpdateDisabled, EventRepeat} from '@mui/icons-material';
import {MenuItem, ListItemIcon, Divider, Menu} from '@mui/material';
import Typography from '@mui/material/Typography';
import {NestedMenuItem} from 'mui-nested-menu';
import {bool, element, func, node, object, oneOf, oneOfType} from 'prop-types';

import {CONNECTORS_WITH_REFRESH} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import {getLastDayOfPreviousMonthFrenchFormatted} from '../../../../utils';

// eslint-disable-next-line react/prop-types
const MenuContainer = ({isNested, ...props}) => {
  const WrapperComponent = isNested ? NestedMenuItem : Menu;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrapperComponent {...props} />;
};

const ActualizeDataMenu = ({isNested, anchorEl, isOpen, onClose}) => {
  const {selectedProject: project, projectsSirenCurrentlyRefreshing, setActualizeProjectDataDatePickerOpen, closeProjectMenu} = useProjects();
  const {runManualProjectDataRefresh, enableOrDisableProjectAutoRefresh} = useProjects();
  const shouldRender = CONNECTORS_WITH_REFRESH.includes(project?.connector?.toUpperCase());

  if (!shouldRender) {
    return null;
  }

  const isProjectRefreshing = projectsSirenCurrentlyRefreshing.includes(project.siren);
  const lastDayOfPreviousMonth = getLastDayOfPreviousMonthFrenchFormatted();
  const classname = isNested ? 'nested-menu-item' : '';

  return (
    <MenuContainer
      id="project-menu-listview"
      anchorEl={isNested ? null : anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={onClose}
      isNested={isNested}
      className={classname}
      leftIcon={<Refresh fontSize="small" color="primary" />}
      label="Actualiser les données"
      parentMenuOpen
    >
      <MenuItem
        key={0}
        onClick={() => {
          runManualProjectDataRefresh(project);
          closeProjectMenu();
        }}
        disabled={isProjectRefreshing}
      >
        <ListItemIcon>
          <Refresh fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography>Actualiser au {lastDayOfPreviousMonth}</Typography>
      </MenuItem>
      <MenuItem
        key={1}
        disabled={isProjectRefreshing}
        onClick={() => {
          setActualizeProjectDataDatePickerOpen(true);
        }}
      >
        <ListItemIcon>
          <EventRepeat fontSize="small" color="primary" />
        </ListItemIcon>
        <Typography>Actualiser à une date choisie...</Typography>
      </MenuItem>
      <Divider />
      <MenuItem
        key={2}
        onClick={() => {
          enableOrDisableProjectAutoRefresh(project.siren, project.auto_update ? 0 : 1);
          closeProjectMenu();
        }}
      >
        <ListItemIcon>{project.auto_update ? <UpdateDisabled fontSize="small" color="primary" /> : <Update fontSize="small" color="primary" />}</ListItemIcon>
        <Typography>{project.auto_update ? 'Désactiver' : 'Activer'} la mise à jour automatique</Typography>
      </MenuItem>
    </MenuContainer>
  );
};

ActualizeDataMenu.defaultProps = {
  anchorEl: null
};

ActualizeDataMenu.propTypes = {
  isNested: bool.isRequired,
  isOpen: bool.isRequired,
  anchorEl: oneOfType([object, element, node, oneOf([null])]),
  onClose: func.isRequired
};

export default ActualizeDataMenu;
