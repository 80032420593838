import {useEffect, useState} from 'react';

import {createTheme, useTheme, ThemeProvider, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Lottie from 'lottie-react';
import {bool, func} from 'prop-types';
import {Link} from 'react-router-dom';

import {APP_ROUTES} from '../../../const';
import useAuth from '../../../hooks/providers/useAuth';
import useProfile from '../../../hooks/providers/useProfile';
import useProjects from '../../../hooks/providers/useProjects';
import deleteAnimation from '../../../lotties/deleteAnimation.json';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            color: parentTheme.palette.error.main,
            textAlign: 'center',
            padding: '24px'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            margin: '12px auto'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: parentTheme.palette.error.main,
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 18
          }
        }
      }
    }
  });
};

const DeleteProfileModal = ({deleteProfile, onClose, isOpen}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const auth = useAuth();
  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);
  const {projects, fetchProjects, isGuestMode} = useProjects();
  const {deleteError} = useProfile();

  const resetState = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setIsLoading(false);
    setIsDeleted(false);
    onClose();
  };

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await deleteProfile();
      setIsDeleted(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      (async () => {
        try {
          setIsLoading(true);
          await fetchProjects();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [isOpen]);

  const userCanDeleteAccount = isGuestMode || (projects || []).length < 1;

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog maxWidth="sm" onClose={resetState} open={isOpen} fullWidth>
        {isDeleted && !deleteError ? (
          <>
            <DialogTitle>Compte supprimé</DialogTitle>
            <DialogContent dividers>
              <Lottie style={{height: '160px', width: '160px', margin: '24px auto'}} loop={false} animationData={deleteAnimation} />
              <p>Votre compte a bien été supprimé !</p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" size="large" color="secondary" onClick={auth.logout}>
                Retour à l'accueil
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Supprimer votre compte</DialogTitle>
            <DialogContent dividers>
              {userCanDeleteAccount ? (
                <p>Voulez-vous vraiment supprimer définitivement votre compte ?</p>
              ) : (
                <p>
                  Vous devez supprimer tous vos projets depuis la page{' '}
                  <Link style={{color: process.env.REACT_APP_SECONDARY_COLOR}} to={APP_ROUTES.projects}>
                    Gérer mes projets
                  </Link>{' '}
                  avant de supprimer votre compte.
                </p>
              )}
            </DialogContent>
            <DialogActions>
              <Button disabled={isLoading} variant="outlined" size="large" color="primary" autoFocus onClick={resetState}>
                Annuler
              </Button>
              <Button disabled={!userCanDeleteAccount || isLoading} variant="contained" size="large" color="error" onClick={onConfirm}>
                Supprimer
              </Button>
            </DialogActions>
            <Box px={2} pb={2}>
              {deleteError && (
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: parentTheme.palette.error.main
                  }}
                >
                  {deleteError}
                </Typography>
              )}
            </Box>
          </>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

DeleteProfileModal.defaultProps = {
  isOpen: false
};

DeleteProfileModal.propTypes = {
  onClose: func.isRequired,
  deleteProfile: func.isRequired,
  isOpen: bool
};

export default DeleteProfileModal;
