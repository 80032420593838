import {useEffect, useLayoutEffect, useRef, useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoubleChevronLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DoubleChevronRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import DotsIcon from '@mui/icons-material/MoreVertOutlined';
import {Divider, Grid, Hidden, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import {styled as muiStyled} from '@mui/material/styles';
import {useWindowSize} from '@uidotdev/usehooks';
import {func, node, oneOfType} from 'prop-types';
import styled from 'styled-components';

import {APP_ROUTES, CHATBOT_SIDEBAR_WIDTH, MAXIMUM_NUMBER_OF_CHABOT_THREADS} from '../../const';
import useChatbot from '../../hooks/providers/useChatbot';
import useLoading from '../../hooks/providers/useLoading';
import usePayment from '../../hooks/providers/usePayment';
import LogoSvg from '../../img/company-logo';
import CustomDimmer from '../dimmer/Dimmer';
import Button from '../form/buttons/Button';
import Link from '../link/Link';
import Spinner from '../spinner/Spinner';
import AssistantList from './AssistantList';
import ChatbotDimmerWarningDataPrivacy from './ChatbotDimmerWarningDataPrivacy';
import ChatbotMainMenu from './ChatbotMainMenu';
import ChatbotMessageInput from './ChatbotMessageInput/ChatbotMessageInput';
import ChatbotShareDataButton from './ChatbotShareDataButton';
import MaximumNumberOfTrialMessagesBanner from './MaximumNumberOfTrialMessagesBanner/MaximumNumberOfTrialMessagesBanner';
import ScrollToBottomArrow from './ScrollToBottomArrow';
import SwitchAssistantWidget from './SwitchAssistantWidget';
import Talk from './Talk';
import TalksList from './TalksList';

const LogoContainer = styled.div`
  svg {
    max-height: 130px;
    width: 100%;
  }
`;

const Main = muiStyled('main', {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginRight: -CHATBOT_SIDEBAR_WIDTH,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  }),
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: 'relative'
}));

const DrawerHeader = muiStyled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 1),
  justifyContent: 'flex-start',
  position: 'sticky',
  background: 'white',
  top: 0,
  zIndex: 9
}));

const TopBarIconButton = muiStyled(IconButton)(() => ({
  width: 28,
  height: 28
}));

const TopBarDividerContainer = muiStyled(Box)(() => ({
  width: '95%',
  margin: '0 auto',
  position: 'sticky',
  top: 54,
  zIndex: 9
}));

// eslint-disable-next-line complexity
const ChatbotSidebar = ({children}) => {
  const {
    isChatbotSidebarOpen: open,
    setChatbotSidebarOpen: setOpen,
    getChatbotData,
    selectedAssistant,
    createThread,
    isWarningAboutDataPrivacyBackdropOpen,
    threadMessages,
    addMessageToThread,
    isFullScreenMode,
    setIsFullScreenMode,
    currentThreadId,
    isThreadLoading,
    scrollConversationToBottom,
    conversationContainerRef,
    setUserHasScrolledUp,
    setIsAtBottom,
    setIsSendingMessage,
    assistants,
    threads,
    sendFileInProgress
  } = useChatbot();
  const {isTrial} = usePayment();
  const {setCompletedApiCalls} = useLoading();

  const [message, setMessage] = useState('');
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState(null);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [isTalksListDrawerOpen, setIsTalksListDrawerOpen] = useState(false);
  const [st, setScrollTop] = useState(0);

  const windowSize = useWindowSize();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMainMenu = e => {
    setMainMenuAnchorEl(e.currentTarget);
    setIsMainMenuOpen(true);
  };

  const closeMainMenu = () => {
    setMainMenuAnchorEl(null);
    setIsMainMenuOpen(false);
  };

  const handleSendMessage = async () => {
    const isNewThread = threadMessages.length === 0;

    setIsSendingMessage(true);
    const res = isNewThread ? await createThread(message) : await addMessageToThread(message);
    setIsSendingMessage(false);

    if (res.success) {
      setMessage('');
    }
  };

  useEffect(() => {
    (async () => {
      await getChatbotData();
      setCompletedApiCalls(prevCompleted => prevCompleted + 1);
    })();
  }, []);

  // This hook aims to scroll conversation to bottom once conversation is loaded
  useLayoutEffect(() => {
    if (!isThreadLoading) {
      setTimeout(() => scrollConversationToBottom('smooth'), 250);
    }
  }, [isThreadLoading]);

  // This hook aims to scroll conversation to bottom once user has checked the privacy warning
  useLayoutEffect(() => {
    if (!isWarningAboutDataPrivacyBackdropOpen) {
      setTimeout(() => scrollConversationToBottom('smooth'), 250);
    }
  }, [isWarningAboutDataPrivacyBackdropOpen]);

  const containerRef = useRef(null);
  // This hooks tracks scrollTop to check if user has scrolled up.
  // It's used to display the back to bottom arrow in conversations.
  useEffect(() => {
    const handleScroll = () => {
      const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
      setScrollTop(prevScrollTop => {
        const atBottom = scrollTop + clientHeight + 15 >= scrollHeight; // Sometimes there's a small missing part on macOS
        const userHasScrolledUp = scrollTop + 15 < prevScrollTop;
        setIsAtBottom(atBottom);

        if (userHasScrolledUp) {
          setUserHasScrolledUp(true);
        }

        return scrollTop;
      });
    };

    containerRef.current.addEventListener('scroll', handleScroll);

    return () => containerRef?.current?.removeEventListener('scroll', handleScroll);
  }, [containerRef]);

  const renderTalksList = () => (
    <>
      {isFullScreenMode && (
        <Hidden lgDown>
          <TalksList closeDrawer={() => setIsTalksListDrawerOpen(false)} />
        </Hidden>
      )}

      <Hidden lgUp>
        <Drawer
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)'
            }
          }}
          open={isTalksListDrawerOpen}
          onClose={() => setIsTalksListDrawerOpen(false)}
        >
          <TalksList closeDrawer={() => setIsTalksListDrawerOpen(false)} />
          <TopBarIconButton sx={{position: 'fixed', top: 12, right: 8}} onClick={() => setIsTalksListDrawerOpen(false)}>
            <CloseIcon color="white" fontSize="medium" />
          </TopBarIconButton>
        </Drawer>
      </Hidden>
    </>
  );

  const renderTopBar = () => (
    <DrawerHeader>
      <Grid container justifyContent="space-between" alignItems="center">
        <Hidden lgDown>
          <TopBarIconButton onClick={() => setIsFullScreenMode(!isFullScreenMode)}>
            {isFullScreenMode ? <DoubleChevronRightIcon color="primary" fontSize="medium" /> : <DoubleChevronLeftIcon color="primary" fontSize="small" />}
          </TopBarIconButton>
        </Hidden>
        <Hidden lgUp>
          <TopBarIconButton onClick={() => setIsTalksListDrawerOpen(true)}>
            <MenuIcon color="primary" fontSize="medium" />
          </TopBarIconButton>
        </Hidden>
        <Typography fontFamily="SoehneBreitKraftig" fontSize={20} color="primary">
          Copilote (Bêta)
        </Typography>
        <Grid item>
          <Grid container>
            <TopBarIconButton onClick={openMainMenu}>
              <DotsIcon color="primary" fontSize="medium" />
            </TopBarIconButton>
            <TopBarIconButton onClick={handleDrawerClose}>
              <CloseIcon color="primary" fontSize="medium" />
            </TopBarIconButton>
          </Grid>
        </Grid>
      </Grid>
    </DrawerHeader>
  );

  const renderMaximumNumberOfConversationContent = () => (
    <Grid item flex={1} display="flex" position="relative" alignItems="center">
      <ChatbotDimmerWarningDataPrivacy />

      {!currentThreadId && (
        <Grid container flexDirection="column" alignItems="center" flex={1}>
          <Typography variant="h3" fontSize={20} fontFamily="SoehneBreitKraftig" textAlign="center" px={1}>
            Vous souhaitez créer de nouvelles conversations ?
          </Typography>

          <Button onClick={handleDrawerClose} to={APP_ROUTES.profile} component={Link} sx={{mt: 4}} variant="contained" color="secondary">
            Sélectionner un abonnement
          </Button>
          <Button
            onClick={() => {
              // Open drawer talks list for mobile
              setIsTalksListDrawerOpen(true);
              // Sets full screen for desktop
              setIsFullScreenMode(true);
            }}
            sx={{textDecoration: 'underline'}}
            size="small"
            variant="text"
            color="primary"
          >
            Voir mes conversations
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const renderNewConversationContent = () => (
    <Grid item flex={isWarningAboutDataPrivacyBackdropOpen || !currentThreadId ? 1 : 'none'} display="flex" position="relative" alignItems="center">
      <ChatbotDimmerWarningDataPrivacy />
      {!selectedAssistant && !currentThreadId && (
        <Grid container flexDirection="column" alignItems="center" flex={1}>
          {assistants.length === 0 ? (
            <Spinner size={96} withoutText isLoading />
          ) : (
            <>
              <LogoContainer>
                <LogoSvg isSymbol />
              </LogoContainer>
              <Typography px={1} textAlign="center" fontFamily="SoehneBreitKraftig" fontSize={22} color="primary" mt={0} mb={0}>
                Avec qui souhaitez-vous discuter ?
              </Typography>
              <AssistantList />
            </>
          )}
        </Grid>
      )}
    </Grid>
  );

  const renderConversationContent = () => (
    <>
      <Grid item sx={{overflow: 'hidden', display: 'flex', flex: isThreadLoading ? 1 : 'none'}}>
        {isThreadLoading ? (
          <Grid container justifyContent="center" alignItems="center" flex={1}>
            <Spinner text="Chargement de la conversation" size={128} isLoading />
          </Grid>
        ) : (
          <Talk />
        )}
      </Grid>

      {selectedAssistant && !isThreadLoading && (
        <Grid item mb={2} flex={1}>
          <SwitchAssistantWidget />
        </Grid>
      )}
    </>
  );

  const userHasReachedMaximumNumberOfConversations = isTrial && threads.length >= MAXIMUM_NUMBER_OF_CHABOT_THREADS;

  const shouldRenderNewConversation = (isWarningAboutDataPrivacyBackdropOpen || !selectedAssistant) && !userHasReachedMaximumNumberOfConversations;
  const shouldRenderNormalConversation = !isWarningAboutDataPrivacyBackdropOpen && (selectedAssistant || currentThreadId);
  const shouldRenderMaximumThreadsReached = !shouldRenderNewConversation && !shouldRenderNormalConversation && (isWarningAboutDataPrivacyBackdropOpen || userHasReachedMaximumNumberOfConversations);

  return (
    <Box sx={{display: 'flex'}}>
      <ChatbotMainMenu onClose={closeMainMenu} anchorEl={mainMenuAnchorEl} isOpen={isMainMenuOpen} />

      <Main
        sx={{
          padding: 0,
          maxWidth: `calc(100% - ${open ? CHATBOT_SIDEBAR_WIDTH : 0}px)`
        }}
        open={open}
      >
        {children}
      </Main>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'row',
            width: {
              xs: '100%',
              lg: isFullScreenMode ? windowSize.width : CHATBOT_SIDEBAR_WIDTH
            },
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {renderTalksList()}

        <Grid item flex={1} display="flex" flexDirection="column" overflow="scroll" ref={containerRef}>
          {renderTopBar()}

          <TopBarDividerContainer>
            <Divider />
          </TopBarDividerContainer>

          <Grid container flexDirection="column" justifyContent="space-between" flex={1} ref={conversationContainerRef} position="relative">
            <MaximumNumberOfTrialMessagesBanner handleDrawerClose={handleDrawerClose} />

            {shouldRenderNewConversation && renderNewConversationContent()}

            {shouldRenderNormalConversation && renderConversationContent()}

            {shouldRenderMaximumThreadsReached && renderMaximumNumberOfConversationContent()}

            <Grid item flex={1} pl={0.5} pr={2} mb={2} flexGrow={0}>
              <Grid spacing={1} container alignItems="center" maxWidth={1200} margin="0 auto">
                <Grid item flex="unset">
                  <ChatbotShareDataButton />
                </Grid>
                <Grid item flex={1}>
                  <ChatbotMessageInput message={message} setMessage={setMessage} sendMessage={handleSendMessage} />
                </Grid>
              </Grid>
            </Grid>

            <CustomDimmer isOpen={sendFileInProgress} spinnerText="Envoi du fichier en cours" />
            <ScrollToBottomArrow />
          </Grid>
        </Grid>
      </Drawer>
    </Box>
  );
};

ChatbotSidebar.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ChatbotSidebar;
