import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, func, string} from 'prop-types';

const TabLabel = ({label, onIconClick, isActive = false}) => {
  if (!isActive) {
    return label;
  }

  console.log({label});
  return (
    <Box>
      <Grid container alignItems="center" flexWrap="nowrap">
        <Grid item>{label}</Grid>
        <Grid item ml={1.5} display="flex" onClick={onIconClick}>
          <SettingsOutlinedIcon color="secondary" sx={{fontSize: 18}} />
        </Grid>
      </Grid>
    </Box>
  );
};

TabLabel.defaultProps = {
  isActive: false,
  onIconClick: null
};

TabLabel.propTypes = {
  label: string.isRequired,
  isActive: bool,
  onIconClick: func
};

export default TabLabel;
