import {useEffect, useReducer} from 'react';

import {Divider, Dialog, DialogTitle, Box, Alert} from '@mui/material';
import {bool, func} from 'prop-types';

import useProfile from '../../../hooks/providers/useProfile';
import useProjects from '../../../hooks/providers/useProjects';
import ErrorMessage from '../../form/ErrorMessage';
import Spinner from '../../spinner/Spinner';
import AddProjectUser from './AddProjectUser';
import DeleteUserModal from './DeleteUserModal';
import ProjectUsersList from './ProjectUsersList';
import PromoteUserToOwnerModal from './PromoteUserToOwnerModal';

const ACTIONS_TYPES = {
  SET_EMAIL_ERROR: 'SET_EMAIL_ERROR',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
  SET_USERS: 'SET_USERS',
  DELETE_USER: 'DELETE_USER',
  SET_DELETE_USER_SUCCESS: 'SET_DELETE_USER_SUCCESS',
  ADD_USER: 'ADD_USER',
  SET_ADD_USER_SUCCESS: 'SET_ADD_USER_SUCCESS',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  TOGGLE_DELETE_USER_MODAL: 'TOGGLE_DELETE_USER_MODAL',
  SET_USER_TO_DELETE: 'SET_USER_TO_DELETE',
  TOGGLE_PROMOTE_USER_MODAL: 'TOGGLE_PROMOTE_USER_MODAL',
  SET_USER_TO_PROMOTE: 'SET_USER_TO_PROMOTE',
  PROMOTE_OR_DEMOTE_SUCCESS: 'PROMOTE_OR_DEMOTE_SUCCESS'
};

const ActionCreators = dispatch => ({
  setEmailError: errorText => {
    dispatch({type: ACTIONS_TYPES.SET_EMAIL_ERROR, payload: errorText});
  },
  setIsSubmitting: submitting => {
    dispatch({type: ACTIONS_TYPES.SET_IS_SUBMITTING, payload: submitting});
  },
  setUsers: users => {
    dispatch({type: ACTIONS_TYPES.SET_USERS, payload: users});
  },
  deleteUser: userId => {
    dispatch({type: ACTIONS_TYPES.DELETE_USER, payload: userId});
  },
  setDeletedSuccessfully: () => {
    dispatch({type: ACTIONS_TYPES.SET_DELETE_USER_SUCCESS});
  },
  addUser: user => {
    dispatch({type: ACTIONS_TYPES.ADD_USER, payload: user});
  },
  setAddedSuccessfully: newUser => {
    dispatch({type: ACTIONS_TYPES.SET_ADD_USER_SUCCESS, payload: newUser});
  },
  setUserInvitedSuccessMessage: message => {
    dispatch({type: ACTIONS_TYPES.INVITE_USER_SUCCESS, payload: message});
  },
  setDeleteUserModalOpen: isOpen => {
    dispatch({type: ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL, payload: isOpen});
  },
  setUserToDelete: userId => {
    dispatch({type: ACTIONS_TYPES.SET_USER_TO_DELETE, payload: userId});
  },
  setPromoteModalOpen: isOpen => {
    dispatch({type: ACTIONS_TYPES.TOGGLE_PROMOTE_USER_MODAL, payload: isOpen});
  },
  setUserToPromote: userId => {
    dispatch({type: ACTIONS_TYPES.SET_USER_TO_PROMOTE, payload: userId});
  },
  promoteOrDemoteUser: userId => {
    dispatch({type: ACTIONS_TYPES.PROMOTE_OR_DEMOTE_SUCCESS, payload: userId});
  }
});

const initialState = {
  emailError: '',
  users: [],
  isSubmitting: false,
  userInvitedSuccessMessage: '',
  deleteUserModalOpen: false,
  userToDelete: null,
  promoteModalOpen: false,
  userToPromote: null
};

// eslint-disable-next-line complexity
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_EMAIL_ERROR:
      return {...state, emailError: action.payload};
    case ACTIONS_TYPES.SET_IS_SUBMITTING:
      return {...state, isSubmitting: action.payload};
    case ACTIONS_TYPES.SET_USERS:
      return {...state, users: action.payload};
    case ACTIONS_TYPES.DELETE_USER:
      return {
        ...state,
        isSubmitting: true,
        deleteUserModalOpen: false,
        userInvitedSuccessMessage: ''
      };
    case ACTIONS_TYPES.SET_DELETE_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        users: state.users.filter(u => u.user_id !== state.userToDelete)
      };
    case ACTIONS_TYPES.ADD_USER:
      return {
        ...state,
        isSubmitting: true,
        userInvitedSuccessMessage: ''
      };
    case ACTIONS_TYPES.SET_ADD_USER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        users: [...state.users, action.payload]
      };
    case ACTIONS_TYPES.PROMOTE_OR_DEMOTE_SUCCESS: {
      const newUsers = [...state.users];
      const updateUserIndex = newUsers.findIndex(u => u.user_id === action.payload);
      newUsers[updateUserIndex].is_owner = newUsers[updateUserIndex].is_owner === false;
      return {
        ...state,
        users: newUsers
      };
    }
    case ACTIONS_TYPES.INVITE_USER_SUCCESS:
      return {...state, userInvitedSuccessMessage: action.payload};
    case ACTIONS_TYPES.TOGGLE_DELETE_USER_MODAL:
      return {...state, deleteUserModalOpen: action.payload};
    case ACTIONS_TYPES.SET_USER_TO_DELETE:
      return {...state, userToDelete: action.payload};
    case ACTIONS_TYPES.TOGGLE_PROMOTE_USER_MODAL:
      return {...state, promoteModalOpen: action.payload};
    case ACTIONS_TYPES.SET_USER_TO_PROMOTE:
      return {...state, userToPromote: action.payload};
    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};

const ManageProjectUsersModal = props => {
  const {onClose, open} = props;
  const projectsContext = useProjects();
  const {profile} = useProfile();
  const {selectedProject: project} = projectsContext;

  const [state, dispatch] = useReducer(reducer, initialState);
  const {emailError, users, isSubmitting, userInvitedSuccessMessage, deleteUserModalOpen, userToDelete, promoteModalOpen, userToPromote} = state;
  const {
    setEmailError,
    setIsSubmitting,
    setUsers,
    setDeletedSuccessfully,
    setAddedSuccessfully,
    setUserInvitedSuccessMessage,
    setDeleteUserModalOpen,
    setUserToDelete,
    setPromoteModalOpen,
    setUserToPromote,
    promoteOrDemoteUser: promoteOrDemoteUserActionCreator,
    deleteUser: deleteUserActionCreator,
    addUser: addUserActionCreator
  } = ActionCreators(dispatch);

  const addUser = async mail => {
    const {siren} = project;

    addUserActionCreator();

    const result = await projectsContext.addUserToProject(mail.toLowerCase(), siren);

    if (result.status === 200) {
      const newUser = {
        username: result.user.username,
        user_id: result.user.userId,
        is_owner: false
      };
      setAddedSuccessfully(newUser);
    }

    if (result.status === 402) {
      setUserInvitedSuccessMessage(result.message);
    }

    setEmailError('');
    setIsSubmitting(false);

    return result;
  };

  const deleteUser = async userId => {
    const {siren} = project;
    deleteUserActionCreator(userId);
    const isUserDeletingHimSelf = userId.toLowerCase() === profile.id.toLowerCase();
    const result = await projectsContext.deleteUserFromProject(userId, siren, isUserDeletingHimSelf);

    if (result.status === 200) {
      setDeletedSuccessfully();
      if (isUserDeletingHimSelf) {
        onClose();
      }
    }
  };

  const openDeleteModal = userId => {
    setUserToDelete(userId);
    setDeleteUserModalOpen(true);
  };

  const closeDeleteModal = () => {
    setUserToDelete(null);
    setDeleteUserModalOpen(false);
  };

  const openPromoteModal = userId => {
    setUserToPromote(userId);
    setPromoteModalOpen(true);
  };

  const closePromoteModal = () => {
    setUserToPromote(null);
    setPromoteModalOpen(false);
  };

  const promoteOrDemote = async (userId, isPromoting) => {
    const {siren} = project;
    setIsSubmitting(true);
    const res = await projectsContext.promoteOrDemoteUser({siren, userId, isOwner: isPromoting ? 1 : 0});

    if (res.status === 200) {
      promoteOrDemoteUserActionCreator(userId);
    }
    setIsSubmitting(false);
  };

  const promoteUser = async userId => {
    closePromoteModal();
    await promoteOrDemote(userId, true);
  };

  const demoteUser = async userId => {
    await promoteOrDemote(userId, false);
  };

  useEffect(() => {
    const initialUsers = project.users;
    projectsContext.setUpdateProjectUsersError('');
    projectsContext.setUpdateProjectUsersSuccess(false);
    setUsers(initialUsers);
  }, []);

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} sx={{padding: 1}} fullWidth PaperProps={{sx: {maxHeight: 'calc(100% - 8px)'}}}>
      {deleteUserModalOpen && <DeleteUserModal isOpen username={users?.find(u => u.user_id === userToDelete)?.username} onSubmit={() => deleteUser(userToDelete)} onCancel={closeDeleteModal} />}

      {promoteModalOpen && (
        <PromoteUserToOwnerModal username={users?.find(u => u.user_id === userToPromote)?.username} isOpen onSubmit={() => promoteUser(userToPromote)} onCancel={closePromoteModal} />
      )}

      <DialogTitle>Gérer les utilisateurs</DialogTitle>
      <Divider sx={{my: 0.5}} />
      <Box sx={{padding: 2, marginTop: 2}}>
        <Box px={3} pb={2}>
          <AddProjectUser addUser={addUser} emailError={emailError} setEmailError={setEmailError} isSubmitting={isSubmitting} />

          <ProjectUsersList
            siren={project.siren}
            users={users.filter(u => !u.is_owner)}
            onChipDelete={openDeleteModal}
            promoteUser={openPromoteModal}
            isSubmitting={isSubmitting}
            title="Liste des invités"
            subtitle="Pour ajouter un propriétaire, cliquez sur le mail d'un utilisateur ci dessous, puis sélectionnez : 'Rendre propriétaire du projet' "
            titleTooltip="Vous pouvez supprimer un utilisateur en cliquant sur l'icône croix à côté de son email"
          />

          <ProjectUsersList
            siren={project.siren}
            users={users.filter(u => u.is_owner)}
            onChipDelete={openDeleteModal}
            demoteUser={demoteUser}
            isSubmitting={isSubmitting}
            title="Liste des propriétaires"
            titleTooltip="Vous pouvez supprimer un propriétaire en cliquant sur l'icône croix à côté de son email"
          />

          <Spinner isLoading={isSubmitting} />
        </Box>

        {projectsContext?.updateProjectUsersError && <ErrorMessage message={projectsContext?.updateProjectUsersError} />}
      </Box>
      {userInvitedSuccessMessage && (
        <Alert variant="filled" severity="success">
          {userInvitedSuccessMessage}
        </Alert>
      )}
    </Dialog>
  );
};

ManageProjectUsersModal.propTypes = {
  onClose: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  open: bool.isRequired
};

export default ManageProjectUsersModal;
