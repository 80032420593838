import {useState} from 'react';

import {Grid, TextField} from '@mui/material';
import {bool, func, string} from 'prop-types';

import {REGULAR_EXPRESSIONS} from '../../../const';
import Button from '../../form/buttons/Button';
import TitleWithTooltip from '../TitleWithTooltip';

const AddProjectUser = ({addUser, isSubmitting, emailError, setEmailError}) => {
  const [mail, setMail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const checkEmailAdress = email => {
    const isValid = REGULAR_EXPRESSIONS.email.test(email);

    setIsEmailValid(isValid);
    if (!isValid) setEmailError('Adresse email invalide');
  };

  const handleSubmit = async () => {
    const res = await addUser(mail.toLowerCase());
    if (res.status === 200 || res.status === 402) {
      setMail('');
    }
  };

  return (
    <Grid container alignItems="flex-start" spacing={1} mb={5}>
      <Grid item xs={12} container alignItems="center" mb={1}>
        <TitleWithTooltip title="Ajouter un invité" tooltip="L'invité devra créer un compte Drivn pour accéder au projet" />
      </Grid>
      <Grid item xs={12} sm={10}>
        <TextField
          error={!isEmailValid && mail !== ''}
          size="small"
          value={mail}
          label="Nouvel invité"
          color="primary"
          placeholder="bob@example.com"
          fullWidth
          helperText={isEmailValid || mail === '' ? "Renseignez l'adresse email de l'utilisateur à inviter" : emailError}
          onChange={event => {
            setMail(event.target.value);
            checkEmailAdress(event.target.value);
          }}
          onKeyDown={async e => {
            e.stopPropagation(); // see : https://github.com/mui/material-ui/issues/19116
            if (e.key === 'Enter' && isEmailValid) {
              await handleSubmit();
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button disabled={!isEmailValid || isSubmitting} onClick={handleSubmit} color="primary" variant="contained">
          Ajouter
        </Button>
      </Grid>
    </Grid>
  );
};

AddProjectUser.propTypes = {
  addUser: func.isRequired,
  emailError: string.isRequired,
  setEmailError: func.isRequired,
  isSubmitting: bool.isRequired
};

export default AddProjectUser;
