// utils / consts for ForecastSpreadsheet and BudgetSpreadsheet
import dayjs from 'dayjs';

import {getInternationalStandardDateFormat} from './data-formatting';

const mappingCellTypeAndPropertyToSet = {
  number: 'value',
  text: 'text',
  dropdown: 'selectedValue',
  date: 'date'
};

const taxRatesDropdownOptions = [
  {label: '0 %', value: '0 %'},
  {label: '5.5 %', value: '5.5 %'},
  {label: '10 %', value: '10 %'},
  {label: '20 %', value: '20 %'}
];

const MOVEMENT_TYPES = ['Ventes', 'Achats', 'Charges de fonctionnement', 'Charges de personnel', 'Autres'];

const movementTypesDropdownOptions = MOVEMENT_TYPES.map(type => ({
  label: type,
  value: type
}));

const FORECAST_COLUMNS_NAMES = {
  taxRate: 'txTVA',
  date: 'date',
  label: 'label',
  amount: 'amount',
  movementType: 'typeMouvement'
};

const SEND_DOCUMENT_REQUEST_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Date pièce', className: 'header-cell'},
    {type: 'header', text: 'Journal', className: 'header-cell'},
    {type: 'header', text: 'Libellé', className: 'header-cell'},
    {type: 'header', text: 'Montant', className: 'header-cell'},
    {type: 'header', text: ' ', className: 'header-cell'}
  ]
};

const SEND_DOCUMENT_REQUEST_COLUMNS_NAMES = {
  date: 'date',
  journal: 'journal',
  amount: 'amount',
  label: 'label',
  delete: 'delete'
};

const FORECAST_COLUMNS_DEFAULT_WIDTHS = {
  txTVA: 150,
  date: 150,
  label: 150,
  amount: 150,
  typeMouvement: 250
};

const calculateLabelColumnWidth = (customColumnsWidth, dialogWidth) => {
  let widthAlreadyTaken = 0;
  Object.values(FORECAST_COLUMNS_NAMES)
    .filter(col => col !== FORECAST_COLUMNS_NAMES.label)
    .forEach(column => {
      const customColumnWidth = customColumnsWidth.find(col => col.id === column)?.width;
      const columnWidth = customColumnWidth || FORECAST_COLUMNS_DEFAULT_WIDTHS[column];
      widthAlreadyTaken += columnWidth;
    });
  const minimumWidth = 150;
  const calculatedWidth = dialogWidth - widthAlreadyTaken;
  return calculatedWidth < minimumWidth ? minimumWidth : calculatedWidth;
};

const getForecastColumns = (customWidths, dialogWidth) => [
  {
    resizable: true,
    columnId: FORECAST_COLUMNS_NAMES.date,
    width: customWidths.find(col => col.id === FORECAST_COLUMNS_NAMES.date)?.width || 150
  },
  {
    resizable: true,
    columnId: FORECAST_COLUMNS_NAMES.label,
    width: customWidths.find(col => col.id === FORECAST_COLUMNS_NAMES.label)?.width || calculateLabelColumnWidth(customWidths, dialogWidth)
  },
  {
    resizable: true,
    columnId: FORECAST_COLUMNS_NAMES.amount,
    width: customWidths.find(col => col.id === FORECAST_COLUMNS_NAMES.amount)?.width || 150
  },
  {
    resizable: true,
    columnId: FORECAST_COLUMNS_NAMES.taxRate,
    width: customWidths.find(col => col.id === FORECAST_COLUMNS_NAMES.taxRate)?.width || 150
  },
  {
    resizable: true,
    columnId: FORECAST_COLUMNS_NAMES.movementType,
    width: customWidths.find(col => col.id === FORECAST_COLUMNS_NAMES.movementType)?.width || 250
  }
];

const getDocumentRequestColumns = dialogWidth => {
  const defaultColumnWidth = 150;
  const deleteColumnWidth = 50;
  const widthAlreadyTaken = defaultColumnWidth * 3 + deleteColumnWidth;
  const widthLeft = dialogWidth - widthAlreadyTaken;

  return [
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.date,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.journal,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.label,
      width: widthLeft < 150 ? 150 : widthLeft
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.amount,
      width: defaultColumnWidth
    },
    {
      columnId: SEND_DOCUMENT_REQUEST_COLUMNS_NAMES.delete,
      width: deleteColumnWidth
    }
  ];
};

const FORECAST_HEADER_ROW = {
  rowId: 'header',
  height: 40,
  cells: [
    {type: 'header', text: 'Date', className: 'header-cell'},
    {type: 'header', text: 'Libellé', className: 'header-cell'},
    {type: 'header', text: 'Montant TTC', className: 'header-cell'},
    {type: 'header', text: 'Taux de TVA', className: 'header-cell'},
    {type: 'header', text: 'Type de mouvement', className: 'header-cell'}
  ]
};

// This is used to convert VTA tax written in human readable string (like : '20 %', '5.5 %' ...) to valid float format for backend (0.2 , 0.05 ...)
const convertHumanReadableTaxRateToFloat = humanizedTaxRate => {
  const withoutSpacesTaxRate = humanizedTaxRate.trim();
  const match = withoutSpacesTaxRate.match(/^(\d+(\.\d+)?)\s*%$/);
  if (match) {
    return parseFloat(match[1]) / 100;
  }
  return NaN;
};

const convertFloatTaxRateToHumanReadable = rate => {
  const parsedRate = parseFloat(rate);
  if (typeof parsedRate === 'number' && !Number.isNaN(rate) && rate >= 0 && rate <= 1) {
    const formattedRate = `${(rate * 100).toFixed(1).replace(/\.0$/, '')} %`;
    return formattedRate;
  }
  return rate;
};

const getOrderedFiscalMonths = firstFiscalMonth => {
  const defaultMonths = [...Array(12)].map((_, i) => i + 1);
  if (firstFiscalMonth === 1) {
    return {
      previousYearMonths: [],
      currentYearMonths: defaultMonths
    };
  }
  const monthsFromFirstFiscalMonth = defaultMonths.splice(firstFiscalMonth - 1);
  const monthsBeforeFirstFiscalMonth = defaultMonths.splice(0, firstFiscalMonth - 1);

  return {
    previousYearMonths: monthsFromFirstFiscalMonth,
    currentYearMonths: monthsBeforeFirstFiscalMonth
  };
};

const getFiscalYearColumns = (firstFiscalMonth, year) => {
  const orderedMonths = getOrderedFiscalMonths(firstFiscalMonth);
  const previousYear = (year - 1).toString();

  const currentYearColumns = orderedMonths.currentYearMonths.map(monthNumber => {
    const monthString = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return `${year}-${monthString}`;
  });
  const previousYearColumns = orderedMonths.previousYearMonths.map(monthNumber => {
    const monthString = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
    return `${previousYear}-${monthString}`;
  });
  return [...previousYearColumns, ...currentYearColumns];
};

const getHeaderRowCells = (budgetYears, expandedColumns, firstFiscalMonth, areRatiosActivated = false) => {
  const firstCell = {type: 'header', text: 'Libellés', className: 'first-column-title'};
  let cells = [firstCell];
  // eslint-disable-next-line no-restricted-syntax
  for (const year of budgetYears) {
    let monthsCells = [];
    const ratioId = `Ratio ${year}`;
    const isYearExpanded = expandedColumns.includes(year);
    if (isYearExpanded) {
      const columns = getFiscalYearColumns(firstFiscalMonth, year, budgetYears);
      monthsCells = columns.map(columnName => {
        return {
          type: 'chevron',
          nonEditable: true,
          text: columnName,
          parentId: year,
          className: 'cell-header-budget-month'
        };
      });
    }
    if (areRatiosActivated) {
      monthsCells.unshift({
        type: 'chevron',
        nonEditable: true,
        hasChildren: true,
        isExpanded: isYearExpanded,
        text: ratioId,
        className: 'cell-header-budget cell-header-budget-ratio'
      });
    }
    monthsCells.unshift({
      type: areRatiosActivated ? 'text' : 'chevron',
      hasChildren: !areRatiosActivated,
      nonEditable: true,
      isExpanded: !areRatiosActivated ? isYearExpanded : false,
      text: year,
      className: 'cell-header-budget cell-header-budget-year'
    });
    cells = [...cells, ...monthsCells];
  }
  return cells;
};

const getBudgetHeaderRow = (budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible) => ({
  rowId: 'header',
  height: 40,
  cells: getHeaderRowCells(budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible)
});

const getColumnsNames = (budgetYears, expandedColumns, firstFiscalMonth, isRatiosColumnVisible) => {
  let columnsNames = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const year of budgetYears) {
    let yearColumns = [];
    if (expandedColumns.includes(year)) {
      yearColumns = getFiscalYearColumns(firstFiscalMonth, year, budgetYears);
    }
    if (isRatiosColumnVisible) {
      yearColumns.unshift(year); // Two columns have the year ID
    }
    yearColumns.unshift(year);
    columnsNames = [...columnsNames, ...yearColumns];
  }
  columnsNames.unshift('Libellés');
  return columnsNames;
};

const getDistinctBudgetYearsFromData = data => {
  const budgetYears = [];

  if (data && data.length > 0) {
    const randomEntry = data[0];

    const properties = Object.keys(randomEntry);
    const datesProperty = properties.filter(p => p.includes('-01'));
    datesProperty.forEach(property => {
      const year = property.substring(0, 4);
      if (!budgetYears.includes(year)) {
        budgetYears.push(year);
      }
    });
  }

  return budgetYears;
};

const getPossibleBudgetYears = alreadyExistingBudgetYears => {
  let possibleBudgetYears = [];
  // Business rule : user can create a budget for year n+3 max and n-1 min
  for (let i = 0; i < 5; i++) {
    const dateYear = dayjs().year() - 1;
    const y = dateYear + i;
    possibleBudgetYears.push(y);
  }
  possibleBudgetYears = possibleBudgetYears.filter(y => !alreadyExistingBudgetYears.includes(y.toString()));
  return possibleBudgetYears;
};

const hasSpreadsheetDataChanged = (baseData, currentData, budget = null) => {
  let hasChanged = false;
  const changes = [];

  if (budget) {
    // eslint-disable-next-line no-param-reassign
    baseData = baseData.filter(entry => entry.budget_name === budget);
  }

  // Some lines have been removed
  if (currentData.length < baseData.length) {
    return true;
  }

  // We check for every spreadsheet line if its content has changed
  currentData.forEach(entry => {
    const baseDataEntry = baseData.find(e => e.line === entry.line && (budget ? e.budget_name === budget : true));
    if (JSON.stringify(entry) !== JSON.stringify(baseDataEntry)) {
      changes.push(entry);
      hasChanged = true;
    }
  });

  return hasChanged;
};

const removeEntriesInvalidProperties = baseData => {
  // eslint-disable-next-line no-param-reassign
  return baseData.map(({line, ...entry}) => entry);
};

const formatSpreadsheetDataForUpdate = (baseData, currentData, budget = null) => {
  let formattedData = [...baseData];
  const allEntries = budget ? baseData.filter(e => e.budget_name === budget) : baseData;

  const updatedEntries = currentData.filter(entry => {
    const baseDataEntry = allEntries.find(e => e.line === entry.line && (budget ? e.budget_name === budget : true));
    return JSON.stringify(entry) !== JSON.stringify(baseDataEntry);
  });

  updatedEntries.forEach(entry => {
    const updatedEntry = {...entry};
    const baseEntryIndex = formattedData.findIndex(item => item.line === entry.line && (budget ? item.budget_name === budget : true));
    if (baseEntryIndex !== -1) {
      // Forecast spreadsheet specific data (!budget means we are formatting data for forecast spreadsheet)
      if (entry.txTVA !== formattedData[baseEntryIndex].txTVA && !budget) {
        // VAT rates are stored as human-readable strings in spreadsheet (eg: 5.5 %) and must be converted to a float (eg: 0.055) before sent to backend
        updatedEntry.txTVA = convertHumanReadableTaxRateToFloat(entry.txTVA).toString();
        updatedEntry.amount = entry.amount.toString();
      }
      if (entry.date !== formattedData[baseEntryIndex].date) {
        updatedEntry.date = getInternationalStandardDateFormat(entry.date);
      }
      formattedData[baseEntryIndex] = updatedEntry; // existing entry updated
    } else {
      // Forecast spreadsheet specific data (!budget means we are formatting data for forecast spreadsheet)
      if (!budget) {
        updatedEntry.txTVA = entry.txTVA.toString();
        updatedEntry.amount = entry.amount.toString();
        updatedEntry.date = getInternationalStandardDateFormat(entry.date);
      }
      formattedData.push(updatedEntry); // new entry added
    }
  });

  if (currentData.length < baseData.length) {
    formattedData = formattedData.filter(entry => {
      const entryHasBeenDeleted = currentData.find(item => item.line === entry.line) === undefined;
      return !entryHasBeenDeleted;
    });
  }

  const dataWithoutInvalidBackendProperties = removeEntriesInvalidProperties(formattedData);
  return dataWithoutInvalidBackendProperties;
};

const getYearTotalCellCssClasses = (year, yearTotal, expandedYears) => {
  const baseCssClass = 'cell-total-year';
  const boldCssClass = expandedYears.includes(year) ? 'bold' : '';
  const negativeNumberCssClass = yearTotal < 0 ? 'negative' : '';

  return `${baseCssClass} ${boldCssClass} ${negativeNumberCssClass}`;
};

const getMonthTotalCellCssClasses = (rowId, rowDepth, monthTotal, expandedRows) => {
  const isCalculatedCell = rowDepth === 1 || rowDepth === 2;

  const baseCssClass = isCalculatedCell ? 'cell-total-month' : '';
  const negativeNumberCssClass = monthTotal < 0 ? 'negative' : '';
  let backgroundCssClass = '';
  let boldCssClass = '';

  if (rowDepth === 3) {
    backgroundCssClass = rowId % 2 === 0 ? 'constrast-bg-cell' : 'white-bg-cell';
  }
  if (rowDepth !== 3) {
    boldCssClass = expandedRows.find(r => r === rowId) ? 'bold' : '';
  }

  return `${baseCssClass} ${boldCssClass} ${backgroundCssClass} ${negativeNumberCssClass}`;
};

const getTotalColumnCssClasses = (total, isYear) => {
  const baseCssClass = isYear ? 'bold cell-total-year' : 'bold cell-total-month';
  const negativeNumberCssClass = total < 0 ? 'negative' : '';

  return `${baseCssClass} ${negativeNumberCssClass}`;
};

const addContextMenuOption = (currentOptions, id, label, handler) => {
  const newOption = {
    id,
    label,
    handler
  };
  return [...currentOptions, newOption];
};

export {
  mappingCellTypeAndPropertyToSet,
  taxRatesDropdownOptions,
  FORECAST_COLUMNS_NAMES,
  getForecastColumns,
  FORECAST_HEADER_ROW,
  convertHumanReadableTaxRateToFloat,
  convertFloatTaxRateToHumanReadable,
  getBudgetHeaderRow,
  getDistinctBudgetYearsFromData,
  getPossibleBudgetYears,
  getFiscalYearColumns,
  getColumnsNames,
  hasSpreadsheetDataChanged,
  formatSpreadsheetDataForUpdate,
  MOVEMENT_TYPES,
  movementTypesDropdownOptions,
  getYearTotalCellCssClasses,
  getMonthTotalCellCssClasses,
  getTotalColumnCssClasses,
  calculateLabelColumnWidth,
  addContextMenuOption,
  SEND_DOCUMENT_REQUEST_HEADER_ROW,
  getDocumentRequestColumns
};
