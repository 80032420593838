import {useEffect, useState} from 'react';

import {TextField, DialogContent, DialogActions} from '@mui/material';
import {func, bool} from 'prop-types';
import 'dayjs/locale/fr';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import Button from '../form/buttons/Button';
import BaseModal from '../modal/BaseModal';

const UpdateReportSummaryModal = ({isOpen, closeModal}) => {
  const {updateSummary, getSummaryTheme, selectedReport, selectedProject: project} = useReport();
  const {updateSummaryInProjects} = useProjects();

  const [summary, setSummary] = useState('');
  const [summaryBeforeUpdate, setSummaryBeforeUpdate] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setDefaultSummaryText = async () => {
    const theme = await getSummaryTheme(selectedReport);
    const summaryBeforeUpdating = project.summaries.find(s => s.themes === theme)?.summaries;
    setSummary(summaryBeforeUpdating);
    setSummaryBeforeUpdate(summaryBeforeUpdating);
  };

  const resetValues = async () => {
    await setDefaultSummaryText();
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const theme = await getSummaryTheme(selectedReport);
    closeModal();
    const res = await updateSummary({siren: project.siren, newSummary: summary, theme});

    if (res.success) {
      updateSummaryInProjects({siren: project.siren, newSummary: summary, theme});
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    (async () => {
      await resetValues();
    })();
  }, [isOpen]);

  const minimumNumberOrChars = 300;
  const maximumNumberOrChars = 2000;
  const hasSummaryTextError = summary !== '' && !REGULAR_EXPRESSIONS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars).test(summary);
  const submitButtonDisabled = summaryBeforeUpdate === summary || hasSummaryTextError || !summary || isSubmitting;

  return (
    <BaseModal onClose={closeModal} open={isOpen} title="Modifier l'analyse">
      <DialogContent>
        <TextField
          autoFocus
          multiline
          rows={10}
          sx={{mb: 2, mt: 3}}
          required
          value={summary}
          onChange={event => {
            setSummary(event.target.value);
          }}
          label="Résumé"
          placeholder="Ceci est un résumé de rapport ..."
          color="primary"
          fullWidth
          error={hasSummaryTextError}
          helperText={hasSummaryTextError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.textWithRangeChars(minimumNumberOrChars, maximumNumberOrChars) : ''}
        />
      </DialogContent>

      <DialogActions sx={{justifyContent: 'center'}}>
        <Button size="large" variant="contained" color="secondary" disabled={submitButtonDisabled} onClick={handleSubmit}>
          Mettre à jour
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

UpdateReportSummaryModal.defaultProps = {
  isOpen: false
};

UpdateReportSummaryModal.propTypes = {
  isOpen: bool,
  closeModal: func.isRequired
};

export default UpdateReportSummaryModal;
