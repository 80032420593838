import {useState} from 'react';

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {Box, Fab, Tooltip} from '@mui/material';
import {useLocation} from 'react-router-dom';

import {BREAKPOINTS} from '../../const';
import useBreakpoint from '../../hooks/dom/useBreakpoint';
import useChatbot from '../../hooks/providers/useChatbot';
import useReport from '../../hooks/providers/useReport';
import Button from '../form/buttons/Button';
import ChatbotShareDataMenu from './ChatbotShareDataMenu';

// eslint-disable-next-line react/prop-types
const ButtonContainer = ({isOnReportPage, ...props}) => {
  const WrapperComponent = !isOnReportPage ? Tooltip : Box;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrapperComponent {...props} />;
};

const ChatbotShareDataButton = () => {
  const {isTypingDisabled, sendableReportVisuals} = useChatbot();
  const {userCanUploadVisuals} = useReport();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const currentBreakpoint = useBreakpoint();
  const isExtraSmallScreen = currentBreakpoint === BREAKPOINTS.xs;

  const location = useLocation();
  const isOnReportPage = location.pathname.startsWith('/reports');
  const areVisualsLoaded = sendableReportVisuals.length > 0;

  // This button is disabled for the following conditions:
  // - chatbot input is disabled (isTypingDisabled)
  // - user is on report page but report and visuals are not yet loaded
  // - user is not on report page (userCanUploadVisuals)
  const isDisabled = isTypingDisabled || !userCanUploadVisuals || (userCanUploadVisuals && !areVisualsLoaded);

  const tooltipTitle = !userCanUploadVisuals ? 'Vous devez être dans un rapport pour partager des données' : '';

  const commonButtonProps = {
    sx: {fontSize: 12},
    disabled: isDisabled,
    variant: 'contained',
    color: 'secondary',
    onClick: e => {
      setAnchorEl(e.currentTarget);
      setIsMenuOpen(true);
    }
  };

  return (
    <>
      <ButtonContainer enterTouchDelay={0} arrow isOnReportPage={isOnReportPage} title={tooltipTitle} disableFocusListener>
        <span>
          {isExtraSmallScreen ? (
            <Fab {...commonButtonProps} size="small" aria-label="partager des données">
              <NoteAddOutlinedIcon fontSize="small" />
            </Fab>
          ) : (
            <Button {...commonButtonProps} startIcon={<NoteAddOutlinedIcon />}>
              Partager des données
            </Button>
          )}
        </span>
      </ButtonContainer>
      <ChatbotShareDataMenu anchorEl={anchorEl} isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};

export default ChatbotShareDataButton;
