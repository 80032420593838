import {useEffect, useState} from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import {DialogActions, DialogContent, Grid, IconButton, TextField} from '@mui/material';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../const';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';

// eslint-disable-next-line complexity
const CreateOrUpdateWorkspaceModal = () => {
  const {
    createOrUpdateWorkspaceModalOpen: isOpen,
    isExistingWorkspaceEdition: isUpdate,
    setCreateOrUpdateWorkspaceModalOpen,
    createWorkspace,
    selectedWorkspace,
    setDeleteWorkspaceOrReportModalOpen
  } = useWorkspaces();

  const [workspaceName, setWorkspaceName] = useState(selectedWorkspace.workspace_name);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalTitle = isUpdate ? `Paramètres ${selectedWorkspace.workspace_name}` : 'Créer un espace de travail';
  const buttonText = isUpdate ? `Sauvegarder l'espace` : 'Ajouter le nouvel espace';

  const hasNameError = workspaceName !== '' && !REGULAR_EXPRESSIONS.labelWithRangeChars(4, 30).test(workspaceName);
  const canSubmit = workspaceName && !hasNameError && !isSubmitting && (!isUpdate || (isUpdate && selectedWorkspace.workspace_name !== workspaceName));

  const closeModal = () => {
    setCreateOrUpdateWorkspaceModalOpen(false);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const result = await createWorkspace(workspaceName);
    setIsSubmitting(false);

    if (result.success) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen && isUpdate) {
      setWorkspaceName(selectedWorkspace.workspace_name);
    } else {
      setWorkspaceName('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isUpdate) {
      setWorkspaceName(selectedWorkspace.workspace_name);
    }
  }, [isUpdate]);
  return (
    <BaseModal onClose={closeModal} open={isOpen} title={modalTitle}>
      <DialogContent sx={{p: 3}}>
        <Grid container flexDirection="column" alignItems="center">
          <TextField
            autoFocus
            disabled={isSubmitting}
            required
            value={workspaceName}
            onChange={event => {
              setWorkspaceName(event.target.value);
            }}
            label="Nom"
            placeholder="Saisir le nom de cet espace de travail"
            color="primary"
            fullWidth
            error={hasNameError}
            helperText={hasNameError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.labelWithRangeChars(4, 30) : ''}
          />
        </Grid>
        {isSubmitting && <Spinner text="Création de l'espace" isLoading />}
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        <Button disabled={!canSubmit} onClick={onSubmit} fullWidth variant="contained" color="secondary" size="large">
          {buttonText}
        </Button>
      </DialogActions>

      {isUpdate && (
        <Grid container justifyContent="center">
          <IconButton
            onClick={() => setDeleteWorkspaceOrReportModalOpen(true)}
            edge="end"
            aria-label="menu"
            color="error"
            sx={{
              width: 50,
              height: 50,
              border: `2px solid`,
              mb: 3
            }}
          >
            <DeleteForeverIcon sx={{fontSize: 25}} />
          </IconButton>
        </Grid>
      )}
    </BaseModal>
  );
};

export default CreateOrUpdateWorkspaceModal;
