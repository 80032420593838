import {array, arrayOf, bool, number, object, shape, string} from 'prop-types';

const assistantShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  role: string.isRequired,
  url_photo: string.isRequired
});

const userShape = shape({
  is_owner: bool.isRequired,
  user_id: string.isRequired,
  username: string.isRequired
});

const textPropTypes = shape({
  annotations: arrayOf(string),
  value: string.isRequired
});

const messageShape = shape({
  assistant_id: string,
  content: arrayOf(
    shape({
      type: string.isRequired,
      text: textPropTypes.isRequired
    })
  ).isRequired,
  created_at: number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file_ids: array,
  id: string,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: object.isRequired,
  object: string.isRequired,
  role: string.isRequired,
  run_id: string,
  thread_id: string.isRequired
});

const summaryShape = shape({
  themes: string.isRequired,
  summaries: string.isRequired
});

const fyShape = shape({
  fy: string.isRequired
});

const projectShape = shape({
  project_name: string.isRequired,
  siren: string.isRequired,
  url_logo: string,
  connector: string.isRequired,
  first_fiscal_month: number.isRequired,
  is_owner: bool.isRequired,
  schema_name: string.isRequired,
  fy_list: arrayOf(fyShape),
  summaries: arrayOf(summaryShape).isRequired,
  users: arrayOf(userShape).isRequired,
  threshold: number,
  auto_update: bool
});

const reportShape = shape({
  report_name: string.isRequired,
  report_id: string.isRequired,
  is_notice_report: bool,
  isCustomModel: bool.isRequired
});

const workspaceShape = shape({
  workspace_name: string.isRequired,
  workspace_id: string.isRequired
});

export {assistantShape, userShape, messageShape, projectShape, reportShape, workspaceShape};
