import DeleteForever from '@mui/icons-material/DeleteForeverOutlined';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import {Divider, ListItemIcon, Menu, MenuItem, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';

import useWorkspaces from '../../hooks/providers/useWorkspaces';
import {workspaceShape} from '../../proptypes-shapes';

const WorkspacesTabsMenu = ({workspace}) => {
  const {setDeleteWorkspaceOrReportModalOpen, workspacesTabsMenuAchorEl: anchorEl, closeWorkspaceTabsMenu: handleClose} = useWorkspaces();
  const theme = useTheme();
  const open = Boolean(anchorEl);

  const renderMenuItems = () => {
    const menuItems = [
      // <MenuItem
      //   onClick={() => {
      //     setIsExistingWorkspaceEdition(true);
      //     setCreateOrUpdateWorkspaceModalOpen(true);
      //     handleClose();
      //   }}
      // >
      //   <ListItemIcon color={theme.palette.primary.main}>
      //     <SettingsOutlinedIcon fontSize="medium" color="primary" />
      //   </ListItemIcon>
      //   <Typography fontSize={14} variant="inherit">
      //     Paramètres de l'espace <br />
      //     <Typography variant="body2" fontSize={12}>
      //       {selectedWorkspace.workspace_name}
      //     </Typography>
      //   </Typography>
      // </MenuItem>,
      //
      // <Divider sx={{margin: '0 !important'}} />,
      <MenuItem disabled key={1} sx={{pt: 1.5}}>
        <ListItemIcon>
          <GroupRoundedIcon />
        </ListItemIcon>
        <Typography fontSize={14} variant="inherit">
          Gérer les utilisateurs
        </Typography>
      </MenuItem>,
      <Divider key={2} sx={{margin: '0 !important'}} />,
      <MenuItem key={3} sx={{pt: 1.5}} onClick={() => setDeleteWorkspaceOrReportModalOpen(true)}>
        <ListItemIcon>
          <DeleteForever color="error" />
        </ListItemIcon>
        <Typography fontSize={14} sx={{color: theme.palette.error.main}} variant="inherit">
          Supprimer {workspace?.workspace_name}
        </Typography>
      </MenuItem>
    ];

    return menuItems;
  };

  return (
    <Menu
      id="workspaces-tabs-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={handleClose}
      slotProps={{paper: {sx: {minWidth: 270}}}}
    >
      {renderMenuItems()}
    </Menu>
  );
};

WorkspacesTabsMenu.propTypes = {
  workspace: workspaceShape.isRequired
};

export default WorkspacesTabsMenu;
