/* eslint-disable react/jsx-props-no-spreading */
import {useCallback, useEffect, useState} from 'react';

import {CloudUploadOutlined} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTheme, Typography, Grid, Box, List, ListItem, ListItemText, IconButton} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';
import {func, number, shape, string} from 'prop-types';
import {useDropzone} from 'react-dropzone';

import {SNACKBAR_ACTIONS} from '../../const';
import useSnackbar from '../../hooks/providers/useSnackbar';
import Button from '../form/buttons/Button';
import ErrorMessage from '../form/ErrorMessage';

const DropzoneContainer = muiStyled(Box)(({theme}) => ({
  display: 'flex',
  padding: theme.spacing(4),
  minHeight: 100,
  border: 1,
  borderStyle: 'solid',
  borderRadius: 3,
  borderColor: theme.palette.primary.main
}));

const DropzoneText = muiStyled(Typography)(() => ({
  fontSize: 11
}));

const DropzoneSelectedFilesText = muiStyled(Typography)(({theme}) => ({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1)
}));

const PickFileButton = muiStyled(Button)(({theme}) => ({
  marginTop: theme.spacing(1)
}));

const CloudIcon = muiStyled(CloudUploadOutlined)(() => ({
  fontSize: 50
}));

const defaultFileFormatAccepted = {
  'text/plain': ['.txt']
};

const UploadDropzone = ({onFilesUpload, error, acceptedFileTypes = defaultFileFormatAccepted, maximumNumberOfFiles = 0}) => {
  const theme = useTheme();
  const {showSnackbar} = useSnackbar();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const onDrop = useCallback(
    files => {
      const allFiles = uploadedFiles.concat(files);

      files.forEach(newFile => {
        const fileAlreadyExists = uploadedFiles.find(f => f.path === newFile.path);
        if (fileAlreadyExists) {
          // remove already existing file from uploaded files
          setUploadedFiles(actualFiles => actualFiles.filter(f => f.path !== newFile.path));
        }
      });

      setUploadedFiles(actualFiles => actualFiles.concat(files));
      onFilesUpload(allFiles);
    },
    [uploadedFiles]
  );

  const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    eventBubblingEnabled: true,
    maxFiles: maximumNumberOfFiles,
    disabled: uploadedFiles.length === maximumNumberOfFiles && maximumNumberOfFiles !== 0,
    multiple: maximumNumberOfFiles !== 1
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      // Check if too many files uploaded
      if (fileRejections[0].errors[0].code === 'too-many-files') {
        showSnackbar(SNACKBAR_ACTIONS.UPLOAD_ERROR_TOO_MANY_FILES, {severity: 'error'});
        return;
      }

      // Check if one of the uploaded files does not match the accepted file extensions
      const hasSomeFileHasInvalidType = fileRejections.map(rejection => rejection.errors.find(e => e.code === 'file-invalid-type'));
      if (hasSomeFileHasInvalidType) {
        const acceptedFileExtensions = Object.values(acceptedFileTypes).flat().join(',');
        showSnackbar(SNACKBAR_ACTIONS.UPLOAD_ERROR_DOCUMENT_TYPE, {severity: 'error'}, {acceptedExtensions: acceptedFileExtensions});
      }
    }
  }, [fileRejections]);

  const deleteFile = (e, filePath) => {
    e.stopPropagation(); // We do not want to trigger dropzone onClick that opens an upload modal
    const filesAfterDeletion = uploadedFiles.filter(file => file.path !== filePath);
    onFilesUpload(filesAfterDeletion);
    setUploadedFiles(actualFiles => actualFiles.filter(file => file.path !== filePath));
  };

  const getDropzoneBackgroundColor = () => {
    const lightBackgroundColor = `${theme.palette.primary.light}`;
    const lightBackgroundColorError = `${theme.palette.error.light}30`;
    let backgroundColor = '#fff';

    if (isDragActive) {
      backgroundColor = lightBackgroundColor;
    } else if (uploadedFiles.length > 0 && !error) {
      backgroundColor = lightBackgroundColor;
    } else if (uploadedFiles.length > 0 && error) {
      backgroundColor = lightBackgroundColorError;
    }
    return backgroundColor;
  };

  const backgroundColor = getDropzoneBackgroundColor();
  const selectedFilesSentence = uploadedFiles.length > 1 ? 'Fichiers sélectionnés :' : 'Fichier sélectionné :';
  return (
    <>
      <DropzoneContainer {...getRootProps()} sx={{background: backgroundColor}}>
        <input {...getInputProps()} />
        {isDragActive && <DropzoneText>Déposer votre fichier ici</DropzoneText>}

        {uploadedFiles.length > 0 && !isDragActive && (
          <Grid container alignItems="center" justifyContent="center" direction="column">
            <DropzoneSelectedFilesText>{selectedFilesSentence}</DropzoneSelectedFilesText>
            <List dense>
              {uploadedFiles.map((file, i) => (
                <ListItem
                  key={i}
                  secondaryAction={
                    <IconButton onClick={e => deleteFile(e, file.path)} edge="end" aria-label="delete">
                      <DeleteIcon sx={{fontSize: 20}} color="error" />
                    </IconButton>
                  }
                >
                  <ListItemText primary={file.path} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {uploadedFiles.length === 0 && !isDragActive && (
          <Grid container direction="column" alignItems="center">
            <CloudIcon color="primary" />
            <DropzoneText>Déposer les fichiers ou</DropzoneText>
            <PickFileButton variant="contained" color="primary" size="small">
              Choisir un fichier
            </PickFileButton>
          </Grid>
        )}
      </DropzoneContainer>
      <ErrorMessage message={error} />
    </>
  );
};

UploadDropzone.defaultProps = {
  maximumNumberOfFiles: 0
};

UploadDropzone.propTypes = {
  onFilesUpload: func.isRequired,
  error: string.isRequired,
  acceptedFileTypes: shape({}).isRequired,
  maximumNumberOfFiles: number
};

export default UploadDropzone;
