import {Alert, AlertTitle} from '@mui/material';

import usePayment from '../../../hooks/providers/usePayment';
import OpenContactFormButton from '../../contact/OpenContactFormButton';

const ExpiredTrialAlert = () => {
  const {numberOfProjectsOwnedByUser, maxNumberOfProjects, hasBeenSubscribedOnce} = usePayment();

  const userHasEndedTrialAndIsOwnerOfProjects = numberOfProjectsOwnedByUser > 0 && maxNumberOfProjects === 0;

  const shouldDisplayAlert = userHasEndedTrialAndIsOwnerOfProjects && !hasBeenSubscribedOnce;
  return shouldDisplayAlert ? (
    <Alert severity="error" variant="standard">
      <AlertTitle fontFamily="InstrumentSans">Votre période d’essai est terminée</AlertTitle>
      <OpenContactFormButton text="Contactez-nous pour bénéficier de votre tarif préférentiel" />
    </Alert>
  ) : null;
};

export default ExpiredTrialAlert;
