import {useEffect, useState} from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {DialogActions, DialogContent, useTheme, Box, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {bool, func} from 'prop-types';

import {CONNECTORS, DEFAULT_PROJECTS_SCHEMAS, SNACKBAR_ACTIONS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import useTiimeProjects from '../../../hooks/providers/useTiimeProjects';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import {getManualConnectorFilename} from '../../../utils';
import CustomAlert from '../../alert/Alert';
import OpenContactFormButton from '../../contact/OpenContactFormButton';
import CustomDimmer from '../../dimmer/Dimmer';
import Button from '../../form/buttons/Button';
import ErrorMessage from '../../form/ErrorMessage';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import SubmitButton from '../CreateProjectSubmitButton';
import AcdConnectorForm from './AcdConnectorForm';
import CegidQuadraConnectorForm from './CegidQuadraConnectorForm';
import Connectors from './Connectors';
import CustomWorkspaceProjectForm from './CustomWorkspaceProjectForm';
import DefiPreviForm from './DefiPreviForm';
import ManualConnectorForm from './ManualConnectorForm';
import PennylaneConnectorForm from './PennlyaneConnectorForm';
import TiimeConnectorForm from './TiimeConnectorForm';
import UnisoftConnectorForm from './UnisoftConnectorForm';
import VectronConnectorForm from './VectronConnectorForm';

// eslint-disable-next-line complexity
const CreateProjectModal = props => {
  const {onClose, open} = props;
  const {
    isSubmitting,
    setIsSubmitting,
    setProjectCreated,
    setSiren,
    setUsername,
    setPassword,
    setCreateProjectSirenError,
    setApiKey,
    setImportFecFileError,
    setCreateError,
    setCreateProjectApiKeyError,
    siren,
    apiKey,
    createError,
    createProject,
    importFecFile,
    selectedReportTab,
    projectCreated,
    isSirenValid,
    username,
    password,
    isCegidFormValid,
    importFecFileError,
    connector,
    setConnector
  } = useProjects();
  const {
    isRedirectingToTiimeAuthenticationServer,
    setTiimeProjectCreationError,
    setTiimeProjectToLink,
    tiimeProjectSuccessfullyImported,
    setTiimeProjectSuccessfullyImported,
    checkingTokenDimmerText
  } = useTiimeProjects();
  const {isDefaultWorkspace} = useWorkspaces();
  const {showSnackbar} = useSnackbar();
  const parentTheme = useTheme();

  const [creationStep, setCreationStep] = useState(0);
  const [isImporting, setIsImporting] = useState(false);
  const [projectCreatedName, setProjectCreatedName] = useState('');
  const [manualProjectFiles, setManualProjectFiles] = useState([]);
  const [fileUploadError, setFileUploadError] = useState('');
  const [siteId, setSiteId] = useState('');
  const [cegidFolderId, setCegidFolderId] = useState('');
  const [siret, setSiret] = useState('');
  const [serverISuite, setServerISuite] = useState('');

  const resetFormState = () => {
    setProjectCreated(false);

    // Fields shared between multiple connectors
    setSiren('');
    setUsername('');
    setPassword('');
    setCreateProjectSirenError('');
    setApiKey('');

    // Manual connector specific fields
    setManualProjectFiles([]);
    setFileUploadError('');
    setImportFecFileError('');
    setCreateError('');

    // Vectron Connector specific fields
    setSiteId('');

    // ACD Connector specific fields
    setServerISuite('');

    // Cegid Quadra Connector specific fields
    setCegidFolderId('');
    setSiret('');

    // Tiime Connector specific fields
    setTiimeProjectCreationError('');
    setTiimeProjectToLink(null);
    setTiimeProjectSuccessfullyImported(false);
  };

  useEffect(() => {
    if (!open) {
      setConnector('');
      setCreationStep(0);
    }

    resetFormState();
  }, [open]);

  useEffect(() => {
    // Once user has selected a connector, we get to the next form step
    if (connector) {
      setCreationStep(1);
    }
  }, [connector]);

  useEffect(() => {
    setFileUploadError('');
    manualProjectFiles.forEach(async file => {
      try {
        await getManualConnectorFilename(file, siren);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setFileUploadError('Une erreur est survenue. Veuillez réessayer de joindre vos fichiers.');
      }
    });
  }, [manualProjectFiles]);

  // We reset errors when switching connector in form
  // because error is shared between all connectors
  useEffect(() => {
    setCreateProjectApiKeyError('');
    setCreateError('');
    resetFormState();
  }, [connector]);

  const handleSubmit = async () => {
    const data = {
      siren,
      myUnisoftApiKey: connector === CONNECTORS.unisoft ? apiKey : '',
      vectronApiKey: connector === CONNECTORS.vectron ? apiKey : '',
      vectronSiteId: siteId,
      cegidFolderId,
      siret: connector === CONNECTORS.cegidQuadra ? siret : '',
      serverISuite
    };

    setIsSubmitting(true);
    const result = await createProject(data);
    setIsSubmitting(false);

    const successfulStatusCodes = [200, 201, 202];
    if (successfulStatusCodes.includes(result.status)) {
      setProjectCreated(true);
      setProjectCreatedName(result.message);
    }
    // When selected connector is manual, we create the project,but we want to keep modal open.
    // User still has to upload his files (FEC)
    if (connector !== CONNECTORS.manual && successfulStatusCodes.includes(result.status)) {
      onClose();
    }
  };

  const handleImport = async () => {
    setIsSubmitting(true);
    setIsImporting(true);
    setImportFecFileError('');
    const importPromises = manualProjectFiles.map(file => importFecFile(siren, file));
    const result = await Promise.all(importPromises);
    setIsSubmitting(false);
    setIsImporting(false);
    const allFilesUploadedSuccessfully = result.every(res => res.status === 200);

    if (allFilesUploadedSuccessfully) {
      showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_SUCCESS);
      onClose();
    }
  };

  const getSpinnerText = () => {
    if (isSubmitting && isImporting) {
      return 'Import des données en cours';
    }
    return 'Création du projet en cours';
  };

  const shouldDisplayConnectors = selectedReportTab !== DEFAULT_PROJECTS_SCHEMAS.previ && creationStep === 0 && !isSubmitting && isDefaultWorkspace;
  const shouldDisplaySubmitButton = selectedReportTab !== DEFAULT_PROJECTS_SCHEMAS.previ && creationStep === 1 && isDefaultWorkspace && !tiimeProjectSuccessfullyImported;
  const shouldDisplayForm = creationStep === 1 || selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ || !isDefaultWorkspace;

  const title = !isDefaultWorkspace || selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ ? 'Ajouter un projet' : 'Créer un projet';
  const connectorsWithoutImportingDelay = [CONNECTORS.manual, CONNECTORS.tiime];

  return (
    <BaseModal onClose={onClose} open={open} title={title}>
      <CustomDimmer isOpen={isRedirectingToTiimeAuthenticationServer} spinnerText={checkingTokenDimmerText} />
      <DialogContent>
        {creationStep === 1 && (
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setConnector(null);
              setCreationStep(0);
            }}
          >
            Retour
          </Button>
        )}

        {shouldDisplayConnectors && <Connectors />}

        <Box pt={3}>
          {shouldDisplayForm && (
            <>
              {!isDefaultWorkspace && <CustomWorkspaceProjectForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />}

              {selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ && <DefiPreviForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />}

              {connector === CONNECTORS.unisoft && <UnisoftConnectorForm />}

              {connector === CONNECTORS.pennylane && <PennylaneConnectorForm isSubmitting={isSubmitting} />}

              {connector === CONNECTORS.cegidQuadra && <CegidQuadraConnectorForm cegidFolderId={cegidFolderId} setCegidFolderId={setCegidFolderId} siret={siret} setSiret={setSiret} />}

              {connector === CONNECTORS.vectron && <VectronConnectorForm siteId={siteId} setSiteId={setSiteId} />}

              {connector === CONNECTORS.acd && <AcdConnectorForm serverISuite={serverISuite} setServerISuite={setServerISuite} />}

              {connector === CONNECTORS.tiime && <TiimeConnectorForm handleClose={onClose} />}

              {connector === CONNECTORS.manual && (
                <ManualConnectorForm
                  projectCreatedName={projectCreatedName}
                  fileUploadError={fileUploadError}
                  setManualProjectFiles={setManualProjectFiles}
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                />
              )}
            </>
          )}
        </Box>
      </DialogContent>
      {isSubmitting && (
        <Box mt={2}>
          <Spinner text={getSpinnerText()} isLoading />
          {!isImporting && !connectorsWithoutImportingDelay.includes(connector) && (
            <Typography color="text.secondary" fontSize={13} textAlign="center">
              Merci de patienter, l'intégration des données peut durer 1 minute.
            </Typography>
          )}
        </Box>
      )}
      <DialogActions mt={0} sx={{flexDirection: 'column'}}>
        {shouldDisplaySubmitButton && (
          <SubmitButton
            files={manualProjectFiles}
            filesError={fileUploadError}
            connector={connector}
            projectCreated={projectCreated}
            handleSubmit={handleSubmit}
            handleImport={handleImport}
            isMyUnisoftFormValid={!!apiKey}
            isPennylaneFormValid={isSirenValid && username && password}
            isCegidFormValid={isCegidFormValid}
            isAcdFormValid={!!(isSirenValid && serverISuite && username && password)}
          />
        )}
        {createError !== '' && <ErrorMessage message={createError} />}
        {importFecFileError !== '' && <ErrorMessage message={importFecFileError} />}
      </DialogActions>
      <CustomAlert sx={{background: parentTheme.palette.primary.light, color: grey[700]}}>
        <Typography fontSize={14}>Vous souhaitez un nouveau connecteur ?&nbsp;</Typography>
        <OpenContactFormButton text="Contactez-nous !" />
      </CustomAlert>
    </BaseModal>
  );
};

CreateProjectModal.propTypes = {
  onClose: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  open: bool.isRequired
};

export default CreateProjectModal;
