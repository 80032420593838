import {Typography, Box} from '@mui/material';
import PropTypes from 'prop-types';

import {assistantShape} from '../../proptypes-shapes';

const AssistantWelcomeMessage = ({assistant}) => {
  return (
    <Box
      sx={{
        width: '33%', // Take up one-third of the container's width
        margin: '0 auto', // Center horizontally
        textAlign: 'center' // Center text within the box
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontStyle: 'italic',
          fontSize: '0.7rem' // Adjust the font size to make it smaller
        }}
      >
        &quot;{assistant.description}&quot;
      </Typography>
    </Box>
  );
};

AssistantWelcomeMessage.propTypes = {
  assistant: assistantShape.isRequired
};

export default AssistantWelcomeMessage;
