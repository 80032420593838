import {Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle, Divider} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

const BoldText = styled.span`
  font-family: InstrumentSansBold;
`;

const DeleteUserModal = ({isOpen, onCancel, onSubmit, username}) => {
  return (
    <Dialog maxWidth="sm" open={isOpen}>
      <DialogTitle>Supprimer un utilisateur</DialogTitle>
      <Divider sx={{my: 0.5}} />
      <DialogContent>
        <Typography>
          Vous êtes sur le point de supprimer <BoldText>{username}</BoldText> de ce projet.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onCancel}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={onSubmit}>
          Supprimer l'utilisateur
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteUserModal.propTypes = {
  isOpen: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  username: string.isRequired
};

export default DeleteUserModal;
