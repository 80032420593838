import {useCallback, useEffect, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DotsIcon from '@mui/icons-material/MoreVertOutlined';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import {string} from 'prop-types';
import {useLocation} from 'react-router-dom';

import {SNACKBAR_ACTIONS, SPREADSHEET_TARGETS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import {formatSpreadsheetDataForUpdate, hasSpreadsheetDataChanged} from '../../../utils';
import Button from '../../form/buttons/Button';
import Spinner from '../../spinner/Spinner';
import AutoSaveButton from './AutoSaveButton';
import AutoSaveIcon from './AutoSaveIcon';
import AutoSaveTimerBeforeNextSave from './AutoSaveTimerBeforeNextSave';
import BudgetSpreadsheet from './BudgetSpreadsheet';
import ForecastSpreadsheet from './ForecastSpreadsheet';
import HiddenRowsToggler from './HiddenRowsToggler';
import SpreadsheetMenu from './SpreadsheetMenu';

// eslint-disable-next-line complexity
const SpreadsheetModal = ({target = SPREADSHEET_TARGETS.FORECAST}) => {
  const {
    autoSaveDisabled,
    getSpreadsheetData,
    selectedReport,
    saveUpdatedSpreadsheetData,
    spreadsheetDataLoaded,
    setSpreadsheetDataLoaded,
    spreadsheetMenuOpen,
    setSpreadsheetMenuOpen,
    selectedBudget,
    setAlreadyTakenBudgetNames,
    selectedProject: currentProject,
    setIsSpreadsheetModalOpen,
    isSpreadsheetModalOpen: isOpen
  } = useReport();
  const {showSnackbar} = useSnackbar();

  const siren = currentProject?.siren;

  const [forecastSpreadsheetData, setForecastSpreadsheetData] = useState(null);
  const [budgetSpreadsheetData, setBudgetSpreadsheetData] = useState(null);
  const [addLineModalOpen, setAddLineModalOpen] = useState(false);
  const [customColumnsWidth, setCustomColumnsWidth] = useState([]);
  const [lastDataSaved, setLastDataSaved] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [spreadsheetHasUnsavedData, setSpreadsheetHasUnsavedData] = useState(false);
  const [isSpreadsheetSaving, setIsSpreadsheetSaving] = useState(false);
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);
  const [isTotalRowVisible, setIsTotalRowVisible] = useState(false);
  const [isRatiosColumnVisible, setIsRatiosColumnVisible] = useState(false);
  const [lastReportRefreshTime, setLastReportRefreshTime] = useState(null);
  const [canPerformAutoSave, setCanPerformAutoSave] = useState(true);
  const [manualSaveRequested, setManualSaveRequested] = useState(false);
  const [now, setNow] = useState(dayjs());
  const [hiddenRows, setHiddenRows] = useState([]);
  const [hiddenRowsVisible, setHiddenRowsVisible] = useState(false);
  const [spreadsheetMenuAnchorEl, setSpreadsheetMenuAnchorEl] = useState(null);

  const location = useLocation();

  const getExistingBudgetNames = data => {
    const uniqueBudgetNames = (data || []).reduce((acc, current) => {
      if (!acc.has(current.budget_name)) {
        acc.add(current.budget_name);
      }
      return acc;
    }, new Set());

    return Array.from(uniqueBudgetNames);
  };

  const setSelectedSpreadsheetData = data => {
    if (target === SPREADSHEET_TARGETS.FORECAST) {
      setForecastSpreadsheetData(data);
    } else {
      setBudgetSpreadsheetData(data);
    }
  };

  const saveData = async () => {
    try {
      setIsSpreadsheetSaving(true);
      showSnackbar(SNACKBAR_ACTIONS.UPDATE_SPREADSHEET_DATA_IN_PROGRESS, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const formattedData = formatSpreadsheetDataForUpdate(lastDataSaved, editedData, target === SPREADSHEET_TARGETS.BUDGET ? selectedBudget : null);
      const saveResult = await saveUpdatedSpreadsheetData(currentProject.siren, target, formattedData, selectedBudget);
      setIsSpreadsheetSaving(false);
      if (saveResult.status === 200) {
        setLastDataSaved(editedData.map(e => ({...e})));
        setSpreadsheetHasUnsavedData(false);
        await selectedReport.refresh();
        setTimeout(() => {
          setLastReportRefreshTime(dayjs());
          setManualSaveRequested(false);
          showSnackbar(SNACKBAR_ACTIONS.UPDATE_SPREADSHEET_DATA_SUCCESS);
        }, 4000);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({e});
      setIsSpreadsheetSaving(false);
      showSnackbar(SNACKBAR_ACTIONS.UPDATE_SPREADSHEET_DATA_ERROR, {
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!spreadsheetDataLoaded) {
        const forecastResult = await getSpreadsheetData(siren, SPREADSHEET_TARGETS.FORECAST);
        const budgetResult = await getSpreadsheetData(siren, SPREADSHEET_TARGETS.BUDGET);

        setForecastSpreadsheetData(forecastResult.data);
        setBudgetSpreadsheetData(budgetResult.data);
        const budgetsNames = getExistingBudgetNames(budgetResult.data);
        setAlreadyTakenBudgetNames(budgetsNames);

        setSpreadsheetDataLoaded(true);
      }
    })();
  }, [spreadsheetDataLoaded]);

  useEffect(() => {
    setIsSpreadsheetSaving(false);
    setSpreadsheetHasUnsavedData(false);
  }, [isOpen]);

  useEffect(() => {
    if (location.pathname.startsWith('/reports/')) {
      setSpreadsheetDataLoaded(false);
    }
  }, [location.pathname]);

  // This hooks aims to perform initial setting of data + handle when switching between tabs
  useEffect(() => {
    if (spreadsheetDataLoaded && selectedBudget && target === SPREADSHEET_TARGETS.BUDGET) {
      const budgetData = (budgetSpreadsheetData || []).map(entry => ({...entry})).filter(e => e.budget_name === selectedBudget);
      setLastDataSaved(budgetData.map(entry => ({...entry})));
      setEditedData(budgetData.map(entry => ({...entry})));
    }

    if (spreadsheetDataLoaded && target === SPREADSHEET_TARGETS.FORECAST) {
      const forecastData = (forecastSpreadsheetData || []).map(entry => ({...entry}));
      setLastDataSaved(forecastData.map(entry => ({...entry})));
      setEditedData(forecastData.map(entry => ({...entry})));
    }
  }, [selectedBudget, forecastSpreadsheetData, budgetSpreadsheetData, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      let allData = [...editedData.map(e => ({...e}))];
      if (target === SPREADSHEET_TARGETS.BUDGET) {
        const otherBudgetsData = (budgetSpreadsheetData || []).filter(e => e.budget_name !== selectedBudget);
        allData = [...otherBudgetsData, ...allData];
      }

      setSelectedSpreadsheetData(allData);
      setEditedData([]);
      setHiddenRows([]);
    }
  }, [isOpen]);

  // This hook aims to handle spreadsheet data auto-saving
  useEffect(() => {
    // eslint-disable-next-line complexity
    (async () => {
      if (isOpen && spreadsheetDataLoaded && canPerformAutoSave && !autoSaveDisabled && (target === SPREADSHEET_TARGETS.FORECAST || selectedBudget !== 'Choisir un budget')) {
        try {
          const shouldSave = hasSpreadsheetDataChanged(lastDataSaved, editedData, target === SPREADSHEET_TARGETS.BUDGET ? selectedBudget : null);
          if (shouldSave && !isSpreadsheetSaving) {
            await saveData();
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log({errorAutoSave: e});
        }
      }
    })();
  }, [spreadsheetHasUnsavedData, isSpreadsheetSaving, canPerformAutoSave]);

  // This hook keeps track of saving actions. It prevents to perform a save :
  // - if a save has successfully occurred in last 15 seconds
  // - if a save is currently already in progress
  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        setNow(dayjs());
        if ((lastReportRefreshTime && now.diff(lastReportRefreshTime, 'seconds') < 15) || isSpreadsheetSaving) {
          setCanPerformAutoSave(false);
        } else if (!canPerformAutoSave) setCanPerformAutoSave(true);
      }, 1000);
    })();
  }, [manualSaveRequested, lastReportRefreshTime, now, isSpreadsheetSaving]);

  // This hook handle manual save requests. If spreadsheet is set to auto-save: disabled, and user requests a save before the 15 seconds limit is reached
  // Save action will be performed here when 15 seconds limit has passed.
  useEffect(() => {
    (async () => {
      if (manualSaveRequested && !isSpreadsheetSaving && canPerformAutoSave) {
        await saveData();
      }
    })();
  }, [manualSaveRequested, canPerformAutoSave, isSpreadsheetSaving]);

  // This hook handles spreadsheet data status (has unsaved data, is saving ...)
  useEffect(() => {
    setTimeout(async () => {
      const hasDataChanged = hasSpreadsheetDataChanged(lastDataSaved, editedData, target === SPREADSHEET_TARGETS.BUDGET ? selectedBudget : null);
      if (hasDataChanged) {
        setSpreadsheetHasUnsavedData(true);
      }
    }, 100);
  }, [editedData, spreadsheetHasUnsavedData]);

  const handleColumnResize = useCallback((columnId, widthAfterResize) => {
    setCustomColumnsWidth(prevColumnsWidth => {
      const columnIndex = prevColumnsWidth.findIndex(el => el.id === columnId);
      if (columnIndex === -1) {
        prevColumnsWidth.push({
          id: columnId,
          width: widthAfterResize
        });
      } else {
        const resizedColumn = prevColumnsWidth[columnIndex];
        const updatedColumn = {...resizedColumn, width: widthAfterResize};
        // eslint-disable-next-line no-param-reassign
        prevColumnsWidth[columnIndex] = updatedColumn;
      }
      return [...prevColumnsWidth];
    });
  }, []);

  const hideRows = useCallback(rowIds => {
    setHiddenRows(currentHiddenRows => {
      const newHiddenRows = currentHiddenRows.concat(rowIds);
      return [...new Set([...newHiddenRows])];
    });
  }, []);

  const showRows = useCallback(rowIds => {
    setHiddenRows(currentHiddenRows => {
      return [...currentHiddenRows.filter(r => !rowIds.includes(r))];
    });
  }, []);

  const renderAddLineButton = () => {
    return (
      <Button onClick={() => setAddLineModalOpen(true)} startIcon={<AddIcon />} size="small" variant="outlined" color="secondary">
        Ajouter une ligne
      </Button>
    );
  };

  const title = target === SPREADSHEET_TARGETS.FORECAST ? "Prévisionnel basé sur l'historique" : `Prévisionnel ${selectedBudget}`;

  const secondsElapsedSinceLastSave = now.diff(lastReportRefreshTime, 'seconds') > 15 ? 15 : now.diff(lastReportRefreshTime, 'seconds');
  const secondsUntilNextSave = 15 - secondsElapsedSinceLastSave;
  const shouldDisplayTimerIcon = (autoSaveDisabled && secondsUntilNextSave > 0 && manualSaveRequested) || (!autoSaveDisabled && spreadsheetHasUnsavedData && secondsUntilNextSave > 0);

  return (
    <Dialog
      fullScreen={isFullScreenEnabled}
      maxWidth="lg"
      open={isOpen}
      onClose={() => setIsSpreadsheetModalOpen(false)}
      PaperProps={{sx: {overflowY: 'visible', overflowX: 'scroll', p: 2, m: 1, width: '100%'}}}
    >
      <Grid container alignItems="center" justifyContent="space-between" sx={{pb: 1, px: isFullScreenEnabled ? 2 : 0, pt: isFullScreenEnabled ? 2 : 0}}>
        <Grid item>
          <Grid container flexDirection="row" alignItems="center" spacing={1}>
            <Grid item>
              <AutoSaveButton />
            </Grid>
            <Grid item>
              <AutoSaveIcon
                requestManualSave={() => setManualSaveRequested(true)}
                mustWaitBeforeNextRefresh={!canPerformAutoSave}
                saveData={saveData}
                isSaving={isSpreadsheetSaving}
                hasUnsavedData={spreadsheetHasUnsavedData}
              />
            </Grid>
            {shouldDisplayTimerIcon && (
              <Grid item sx={{marginTop: '2px'}}>
                <AutoSaveTimerBeforeNextSave progress={secondsUntilNextSave} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <DialogTitle
            sx={{
              fontWeight: 'bold',
              fontFamily: 'SoehneBreitKraftig',
              fontSize: 20,
              padding: 0,
              ml: shouldDisplayTimerIcon ? -4 : 0
            }}
          >
            {title}
          </DialogTitle>
        </Grid>
        <Grid item>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{cursor: 'pointer'}} item onClick={() => setIsFullScreenEnabled(!isFullScreenEnabled)}>
              {isFullScreenEnabled ? <FullscreenExitIcon sx={{fontSize: 30}} /> : <FullscreenIcon sx={{fontSize: 30}} />}
            </Grid>
            {target === SPREADSHEET_TARGETS.BUDGET && (
              <>
                <Grid item>
                  <Button
                    onClick={() => setIsRatiosColumnVisible(!isRatiosColumnVisible)}
                    startIcon={isRatiosColumnVisible ? <EyeIcon /> : <VisibilityOffIcon />}
                    size="small"
                    variant={isRatiosColumnVisible ? 'contained' : 'outlined'}
                    color="secondary"
                  >
                    Ratios
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => setIsTotalRowVisible(!isTotalRowVisible)}
                    startIcon={isTotalRowVisible ? <EyeIcon /> : <VisibilityOffIcon />}
                    size="small"
                    variant={isTotalRowVisible ? 'contained' : 'outlined'}
                    color="secondary"
                  >
                    Totaux
                  </Button>
                </Grid>
              </>
            )}

            <Grid item>
              <IconButton
                edge="end"
                aria-label="menu"
                onClick={e => {
                  setSpreadsheetMenuAnchorEl(e.currentTarget);
                  setSpreadsheetMenuOpen(true);
                }}
              >
                <DotsIcon sx={{fontSize: 25}} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{my: 0.5}} />

      <Grid container justifyContent="space-between" alignItems="center" pt={2} pb={1} pr={isFullScreenEnabled ? 2 : 0}>
        <Grid item>
          <HiddenRowsToggler
            disabled={hiddenRows.length === 0}
            checked={hiddenRowsVisible}
            toggle={() => {
              setHiddenRowsVisible(!hiddenRowsVisible);
            }}
          />
        </Grid>
        <Grid item>{editedData.length > 0 && renderAddLineButton()}</Grid>
      </Grid>

      <DialogContent
        sx={{
          px: 0,
          py: 1,
          overflowY: 'visible',
          overflowX: 'scroll',
          mx: isFullScreenEnabled ? 2 : 0
        }}
      >
        {editedData.length === 0 && (
          <Grid container flexDirection="column" alignItems="center" pt={2}>
            <Grid item>
              <Typography fontSize={16} fontFamily="InstrumentSans">
                Vous n'avez pas encore de lignes d'ajustement.
              </Typography>
            </Grid>
            <Grid item>{renderAddLineButton()}</Grid>
          </Grid>
        )}
        {!spreadsheetDataLoaded && <Spinner text="Chargement des données en cours" isLoading />}
        {spreadsheetDataLoaded && target === SPREADSHEET_TARGETS.FORECAST && (
          <ForecastSpreadsheet
            currentProject={currentProject}
            addLineModalOpen={addLineModalOpen && target === SPREADSHEET_TARGETS.FORECAST}
            setAddLineModalOpen={setAddLineModalOpen}
            customColumnsWidth={customColumnsWidth}
            handleColumnResize={handleColumnResize}
            data={editedData || []}
            setData={setEditedData}
            hideRows={hideRows}
            showRows={showRows}
            hiddenRows={hiddenRows}
            hiddenRowsVisible={hiddenRowsVisible}
          />
        )}
        {spreadsheetDataLoaded && target === SPREADSHEET_TARGETS.BUDGET && selectedBudget !== 'Choisir un budget' && (
          <BudgetSpreadsheet
            currentProject={currentProject}
            isDataLoading={!spreadsheetDataLoaded}
            addLineModalOpen={addLineModalOpen}
            setAddLineModalOpen={setAddLineModalOpen}
            customColumnsWidth={customColumnsWidth}
            handleColumnResize={handleColumnResize}
            data={editedData || []}
            setData={setEditedData}
            isTotalRowVisible={isTotalRowVisible}
            isRatiosColumnVisible={isRatiosColumnVisible}
            hideRows={hideRows}
            showRows={showRows}
            hiddenRows={hiddenRows}
            hiddenRowsVisible={hiddenRowsVisible}
          />
        )}
      </DialogContent>
      <Grid container justifyContent="flex-end">
        <Typography fontSize={12} color="text.primary">
          Ensemble des montants Hors Taxes (HT)
        </Typography>
      </Grid>

      <SpreadsheetMenu onClose={() => setSpreadsheetMenuOpen(false)} isOpen={spreadsheetMenuOpen} anchorEl={spreadsheetMenuAnchorEl} />
    </Dialog>
  );
};

SpreadsheetModal.propTypes = {
  target: string.isRequired
};

export default SpreadsheetModal;
