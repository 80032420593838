import {useState, useMemo} from 'react';

import {oneOfType, node, func} from 'prop-types';

import SnackbarContext from '../contexts/SnackbarContext';
import {getSnackbarMessage} from '../utils';

const defaultSnackbarOptions = {
  severity: 'success',
  duration: 4000,
  hasSpinner: false
};

const SnackbarProvider = ({children}) => {
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState(defaultSnackbarOptions.severity);
  const [duration, setDuration] = useState(defaultSnackbarOptions.duration);
  const [hasSpinner, setHasSpinner] = useState(defaultSnackbarOptions.hasSpinner);

  const showSnackbar = (action, options = defaultSnackbarOptions, data = {}) => {
    console.log({options});
    const snackbarMessage = getSnackbarMessage(action, data);
    setMessage(snackbarMessage);
    setSeverity(options.severity);
    setDuration(options.autoHide === false ? null : options.duration);
    setHasSpinner(options.hasSpinner);
    setIsOpen(true);
  };

  const closeSnackbar = () => {
    setIsOpen(false);
  };

  const value = useMemo(
    () => ({
      showSnackbar,
      message,
      isOpen,
      severity,
      duration,
      closeSnackbar,
      defaultSnackbarOptions,
      hasSpinner
    }),
    [showSnackbar, message, isOpen, severity, duration, closeSnackbar, defaultSnackbarOptions, hasSpinner]
  );

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

SnackbarProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default SnackbarProvider;
