import React from 'react';

import {DeleteForeverOutlined} from '@mui/icons-material';
import {ThemeProvider, Grid, Divider, Avatar, DialogContentText, Typography, DialogContent, Dialog, DialogTitle, DialogActions, useTheme, createTheme} from '@mui/material';
import {bool, func} from 'prop-types';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import Button from '../form/buttons/Button';
import Spinner from '../spinner/Spinner';

const createDialogTheme = parentTheme => {
  return createTheme({
    ...parentTheme, // spreading parent theme allows to keep all base stuff configured in it (eg: color palette)
    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            padding: 1
          }
        }
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            marginTop: 1,
            marginBottom: 24,
            textAlign: 'center',
            color: parentTheme.palette.error.main
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontWeight: 800,
            textAlign: 'center',
            fontSize: 16
          }
        }
      }
    }
  });
};
// eslint-disable-next-line complexity
const DeleteProjectModal = props => {
  const {onClose, open} = props;

  const projectsContext = useProjects();
  const {selectedProject: project} = projectsContext;
  const {siren} = project || {};
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [hasBeenDeleted, setHasBeenDeleted] = React.useState(false);

  const parentTheme = useTheme();
  const dialogTheme = createDialogTheme(parentTheme);

  const deleteProject = async () => {
    setIsSubmitting(true);
    const result = await projectsContext?.deleteProject(project);
    setIsSubmitting(false);

    if (result.status === 200) {
      setHasBeenDeleted(true);
    }
  };

  const handleClose = async (event, reason, projectDeleted = false) => {
    if (projectDeleted) {
      const projectToDeleteIndex = projectsContext.projects.findIndex(
        p => p.siren === siren && (p.schema_name === projectsContext.selectedReportTab || p.report_id === projectsContext.selectedReportTab)
      );
      console.log({
        projectToDeleteIndex,
        selectedReportTab: projectsContext.selectedReportTab,
        projects: projectsContext.projects
      });
      let newProjects = [...projectsContext.projects];
      newProjects.splice(projectToDeleteIndex, 1);

      if (projectsContext.selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion) {
        // Business rule: DeFi Previ projects must be deleted automatically when associated DeFi Gestion project is deleted.
        const previProjectIndex = newProjects.findIndex(p => p.siren === siren && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.previ);
        if (projectsContext.selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.gestion && previProjectIndex !== -1) {
          newProjects.splice(previProjectIndex, 1);
        }
        // Business rule : All associated projects in custom workspaces must be deleted after DeFi Gestion project deletion
        newProjects = newProjects.filter(p => {
          // if project has a different siren, we keep it
          if (p.siren !== siren) return true;
          // if project has the same siren and is not inside a custom workspace, we DO NOT keep it
          return Object.values(DEFAULT_PROJECTS_SCHEMAS).includes(p.schema_name);
        });
      }

      projectsContext.setProjects(newProjects);
    }

    // Prevent user from closing modal by clicking away while project is deleting
    // If modal is closed before deletion is done, he won't see modal displaying success deletion message
    if (reason === 'backdropClick') {
      return;
    }

    onClose();
  };

  const isDeFiGestionProject = project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion;

  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog maxWidth="sm" onClose={(event, reason) => handleClose(event, reason)} open={open} fullWidth>
        <DialogTitle>{hasBeenDeleted ? 'Dossier supprimé' : 'Supprimer le projet'}</DialogTitle>
        <Divider sx={{my: 0.5}} />
        <DialogContent>
          {hasBeenDeleted && (
            <Grid container direction="column" alignItems="center">
              <Avatar sx={{p: 5, bgcolor: `${parentTheme.palette.error.light}30`}}>
                <DeleteForeverOutlined sx={{fontSize: 60}} color="error" />
              </Avatar>
              <Typography sx={{mt: 5, mb: 1, fontSize: 15}} variant="inherit">
                Votre projet a bien été supprimé !
              </Typography>
            </Grid>
          )}
          {!isSubmitting && !hasBeenDeleted && <DialogContentText variant="inherit">Êtes-vous sûr de vouloir supprimer définitivement ce projet ?</DialogContentText>}
          {!isSubmitting && !hasBeenDeleted && isDeFiGestionProject && (
            <Typography color="text.secondary" fontSize={13} textAlign="center" fontWeight="bold">
              Ce projet sera également supprimé de DeFi Prévi et de vos rapports personnalisés s'il y est associé.
            </Typography>
          )}
          {isSubmitting && (
            <>
              <Spinner text="Suppression du projet en cours" isLoading />
              <Typography color="text.secondary" fontSize={13} textAlign="center">
                Selon la taille de votre projet, sa suppression complète peut prendre plus d'une minute.
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{mb: 4}}>
          {hasBeenDeleted ? (
            <Button size="large" variant="contained" color="secondary" onClick={(event, reason) => handleClose(event, reason, true)}>
              Retour à mes projets
            </Button>
          ) : (
            <>
              {!isSubmitting && (
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  Annuler
                </Button>
              )}
              <Button disabled={isSubmitting} variant="contained" color="error" onClick={deleteProject}>
                Supprimer
              </Button>
            </>
          )}
        </DialogActions>
        {projectsContext.deleteError && <Typography sx={{color: parentTheme.palette.error.main}} />}
      </Dialog>
    </ThemeProvider>
  );
};

DeleteProjectModal.propTypes = {
  onClose: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  open: bool.isRequired
};

export default DeleteProjectModal;
