import React, {useState, useEffect} from 'react';

import DescriptionIcon from '@mui/icons-material/Description'; // Importing a new icon for forms
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import QuestionMark from '@mui/icons-material/QuestionMark';
import SupportIcon from '@mui/icons-material/Support';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {Tooltip, MenuItem, Menu, ListItemText, ListItemIcon, Box, Modal, Backdrop} from '@mui/material';
import {NestedMenuItem} from 'mui-nested-menu';
import {bool, func} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import useProfile from 'hooks/providers/useProfile';
import {makeHumanFriendlyUrl} from 'utils/data-formatting';

import useAuth from '../../hooks/providers/useAuth';
import useLoading from '../../hooks/providers/useLoading';
import HeaderStyledIconButton from './HeaderStyledIconButton';

const primaryColor = process.env.REACT_APP_MAIN_COLOR;

const commonMenuItemStyle = {
  marginRight: 1,
  color: primaryColor,
  fontSize: '20px',
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '2px'
};

const HeaderContactButton = ({onClose, isMobile}) => {
  const {setIsContactFormOpen} = useAuth();
  const {getHelp, getHelpButtons} = useProfile();
  const {setCompletedApiCalls} = useLoading();

  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const navigate = useNavigate();

  const handleDesktopSubmenuHover = (event, category) => {
    setHoveredCategory(category);
    setOpenSubmenu(category);
  };

  const handleDesktopSubmenuClose = () => {
    setOpenSubmenu(null);
  };

  const handleMobileSubmenuClick = category => {
    setOpenSubmenu(openSubmenu === category ? null : category);
  };

  const handleClick = event => {
    if (isMobile) {
      if (anchorEl && anchorEl.contains(event.target)) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubmenu(null);
    if (onClose) onClose();
  };

  const handleLastItemClick = () => {
    setIsContactFormOpen(true);
    handleClose();
  };

  const handleOpenModal = link => {
    setModalContent(link);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent('');
  };

  const handleCanvaButtonClick = (documentGroup, document) => {
    const formattedGroupName = makeHumanFriendlyUrl(documentGroup);
    const formattedDocumentName = makeHumanFriendlyUrl(document);
    navigate(`/${formattedGroupName}/${formattedDocumentName}`);
    handleClose();
  };

  const handleHubspotButtonClick = (link, category) => {
    handleOpenModal(link);
    handleDesktopSubmenuClose(category);
    handleClose();
  };

  const handleMenuItemClick = (item, category) => {
    if (item.src_iframe === 'canva') {
      const subtitleUrl = makeHumanFriendlyUrl(item.subtitle);
      handleCanvaButtonClick(category, subtitleUrl);
      handleDesktopSubmenuClose(category);
    } else if (item.src_iframe === 'hubspot' || item.src_iframe === 'forms') {
      handleHubspotButtonClick(item.link, category);
    } else {
      handleClose();
    }
  };

  const renderSubmenuItems = (items, category) => {
    return items.map(item => (
      <MenuItem key={item.link} onClick={() => handleMenuItemClick(item, category)}>
        <ListItemText primary={item.src_iframe === 'hubspot' ? `Avec ${item.subtitle}` : item.subtitle} />
      </MenuItem>
    ));
  };

  const getCategoryIcon = categoryItems => {
    const hasContactItem = categoryItems.some(item => item.src_iframe === 'contact');
    const hasCanvaItem = categoryItems.some(item => item.src_iframe === 'canva');
    const hasHubspotItem = categoryItems.some(item => item.src_iframe === 'hubspot');
    const hasFormsItem = categoryItems.some(item => item.src_iframe === 'forms');

    if (hasContactItem) {
      return <EmailIcon sx={commonMenuItemStyle} />;
    }
    if (hasCanvaItem) {
      return <SupportIcon sx={commonMenuItemStyle} />;
    }
    if (hasHubspotItem) {
      return <SupportAgentIcon sx={commonMenuItemStyle} />;
    }
    if (hasFormsItem) {
      return <DescriptionIcon sx={commonMenuItemStyle} />;
    }
    return <KeyboardArrowRightIcon sx={{marginLeft: '8px', color: primaryColor, fontSize: 'small'}} />;
  };

  const renderMenuItems = () => {
    if (!getHelpButtons) return null;

    const categories = Object.keys(getHelpButtons);

    return categories.map(category => {
      const items = getHelpButtons[category];
      const isContactCategory = items.some(item => item.src_iframe === 'contact');

      if (isContactCategory) {
        return (
          <MenuItem key={category} onClick={handleLastItemClick}>
            <ListItemIcon>
              <EmailIcon sx={commonMenuItemStyle} />
            </ListItemIcon>
            <ListItemText primary={category} />
            <KeyboardArrowRightIcon sx={{visibility: 'hidden'}} />
          </MenuItem>
        );
      }

      return (
        <div key={category}>
          {isMobile ? (
            <MenuItem onClick={() => handleMobileSubmenuClick(category)} style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
              <ListItemIcon>{getCategoryIcon(items)}</ListItemIcon>
              <ListItemText primary={category} />
              {openSubmenu === category ? <KeyboardArrowRightIcon sx={{color: primaryColor}} /> : <KeyboardArrowRightIcon sx={{color: primaryColor, visibility: 'hidden'}} />}
            </MenuItem>
          ) : (
            <NestedMenuItem
              key={category}
              parentMenuOpen
              label={category}
              leftIcon={getCategoryIcon(items)}
              onMouseEnter={event => handleDesktopSubmenuHover(event, category)}
              onMouseLeave={() => handleDesktopSubmenuClose(category)}
              sx={{
                paddingLeft: '16px',
                '& .MuiListItemText-root': {marginLeft: '8px'}
              }}
            >
              {renderSubmenuItems(items, category)}
            </NestedMenuItem>
          )}
          {isMobile && (
            <Menu
              anchorEl={anchorEl}
              open={openSubmenu === category}
              onClose={handleClose}
              anchorReference="anchorEl"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  marginTop: '16px',
                  maxHeight: '70vh',
                  overflowY: 'auto'
                }
              }}
            >
              {renderSubmenuItems(items, category)}
            </Menu>
          )}
        </div>
      );
    });
  };

  if (isMobile) {
    return (
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <QuestionMark fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Aide</ListItemText>
        <Menu
          id="contact-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              marginTop: '16px',
              maxHeight: '70vh',
              overflowY: 'auto'
            }
          }}
          keepMounted
        >
          {renderMenuItems()}
        </Menu>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            onClick: handleCloseModal,
            sx: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '70%',
              height: '90vh',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <iframe src={modalContent} width="100%" height="100%" title="Prendre rendez-vous" style={{border: 'none'}} />
          </Box>
        </Modal>
      </MenuItem>
    );
  }

  return (
    <div>
      <HeaderStyledIconButton onClick={handleClick}>
        <Tooltip title="Nous contacter">
          <QuestionMark />
        </Tooltip>
      </HeaderStyledIconButton>
      <Menu
        id="contact-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            marginTop: '16px',
            maxHeight: '70vh',
            overflowY: 'auto'
          }
        }}
        keepMounted
      >
        {renderMenuItems()}
      </Menu>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          onClick: handleCloseModal,
          sx: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            height: '90vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <iframe src={modalContent} width="100%" height="100%" title="Prendre rendez-vous" style={{border: 'none'}} />
        </Box>
      </Modal>
    </div>
  );
};

HeaderContactButton.defaultProps = {
  onClose: null
};

HeaderContactButton.propTypes = {
  onClose: func,
  isMobile: bool
};

export default HeaderContactButton;
