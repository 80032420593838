import {memo, useEffect, useState} from 'react';

import {ReactGrid} from '@silevis/reactgrid';
import {arrayOf, bool, func, number, shape, string} from 'prop-types';

import '@silevis/reactgrid/styles.scss';
import './styles/index.scss';
import useReport from '../../../hooks/providers/useReport';
import {
  addContextMenuOption,
  getBudgetHeaderRow,
  getColumnsNames,
  getFiscalYearColumns,
  getMonthTotalCellCssClasses,
  getRoundedPercentage,
  getTotalColumnCssClasses,
  getYearTotalCellCssClasses,
  isDemoProject
} from '../../../utils';
import Spinner from '../../spinner/Spinner';
import CreateBudgetAccountingEntryModal from './CreateBudgetAccountingEntryModal';

/**
 * A single cell of BudgetSpreadSheet
 * @typedef {{
 *   type: string,
 *   text: string,
 *   parentId: number,
 *   hasChildren: boolean,
 *   isExpanded: boolean,
 *   indent: number,
 *   className: string | null,
 * }} BudgetSpreadSheetCell
 */

/**
 * A single row of BudgetSpreadSheet
 * @typedef {{
 *    rowId: number,
 *    category: string | null,
 *    isSubcategory: boolean,
 *    parentCategory: string | null,
 *    isAccountingEntryRow: boolean | null,
 *    cells: Array<BudgetSpreadSheetCell>
 *   }} BudgetSpreadSheetRow
 */

const BudgetSpreadsheet = ({
  data,
  setData,
  isDataLoading,
  currentProject,
  setAddLineModalOpen,
  addLineModalOpen,
  customColumnsWidth,
  handleColumnResize,
  isTotalRowVisible,
  isRatiosColumnVisible,
  hiddenRows,
  hiddenRowsVisible,
  showRows,
  hideRows
}) => {
  const {selectedBudget, selectedBudgetYears: budgetYears} = useReport();
  const [expandedRows, setExpandedRows] = useState([]);
  const [rows, setRows] = useState([]);

  const [expandedColumns, setExpandedColumns] = useState([]);
  const [newAccountingEntryCategory, setNewAccountingEntryCategory] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const isEditable = currentProject.is_owner || isDemoProject(currentProject);

  /**
   * Gets sheet columns objects used by ReactGrid component
   *
   * @returns {{resizable: boolean, columnId: *, width: *|number}[]}
   */
  const getBudgetColumns = () =>
    getColumnsNames(budgetYears, expandedColumns, currentProject.first_fiscal_month, isRatiosColumnVisible).map((col, i) => {
      const defaultColumnWidth = i === 0 ? 520 : 150;
      const columnWithCustomWidth = customColumnsWidth.find(c => c.id === col);
      return {
        columnId: col,
        width: columnWithCustomWidth ? columnWithCustomWidth.width : defaultColumnWidth,
        resizable: true
      };
    });

  /**
   * Builds a list of top level categories from incoming data (strings array containing categories labels)
   *
   * @returns {Array<string>}
   */
  const getUniqueCategories = () => {
    const orderedDataByCategoriesOrder = data.sort((a, b) => a.racine3_order - b.racine3_order);
    const uniqueCategories = [...new Set(orderedDataByCategoriesOrder.map(entry => entry.racine3_name))]; // racine3_name is the name of the category property sent by backend
    return uniqueCategories;
  };

  /**
   * Builds a list of subcategories from incoming data
   *
   * @returns {Array<{
   *     parentCategory: string,
   *     subCategory: string
   * }>}
   */
  const getUniqueSubCategories = () => {
    const uniqueSubCategories = [...new Set(data.map(entry => entry.racine3_subname))]; // racine3_subname is the name of the sub-category property sent by backend

    return uniqueSubCategories
      .filter(subCat => subCat !== null) // Some entries have null subcategories. This is due to not properly formatted backend data, see example sheet : https://github.com/Adrian1903/drivn-powerbi-embed/issues/373#issuecomment-1767104152
      .map(subCategory => {
        const parentCategory = data.find(item => item.racine3_subname === subCategory).racine3_name;
        return {
          parentCategory,
          subCategory
        };
      });
  };

  /**
   * This method returns the category object containing all needed information corresponding to given rowId in sheet
   *
   * @param rowId - rowId for which we want to know associated category
   * @returns {{
   *  category: string,
   *  parentCategory : string | null,
   *  isSubcategory: bool,
   *  rowId: number,
   * }}
   *
   */
  const getCategoryByRowId = rowId => {
    const row = rows.find(r => r.rowId === rowId);
    const selectedCategory = row
      ? {
          category: row.category,
          parentCategory: row.parentCategory,
          isSubcategory: row.isSubcategory,
          rowId
        }
      : null;
    return selectedCategory;
  };

  /**
   *
   * Determines if a given category has sub categories
   *
   * @param parentCategory {string} - label of category
   *
   * @example
   * const humanResourcesCategoryHasSubcategories = hasSubCategories('Human Resources');
   *
   * @returns {boolean}
   */
  const hasSubCategories = parentCategory => {
    const uniqueSubCategories = getUniqueSubCategories();
    const subCategories = uniqueSubCategories.find(c => c.parentCategory === parentCategory);
    return subCategories !== undefined;
  };

  /**
   * Gets the spent/granted amount for a single accounting entry, for a given month
   *
   * @param accountingEntry {object} - accounting entry from backend data
   * @param columnId {string} - label of column , composed by year and month, or only year (examples : 2023 / 01-2023 / 02-2023)
   *
   * @returns {number}
   */
  const getOneAccountingEntryAmountByMonth = (accountingEntry, columnId) => {
    const monthAmount = accountingEntry[columnId] ? parseFloat(accountingEntry[columnId]) : 0;
    return monthAmount;
  };

  /**
   * Gets all accounting entries in data that matches a given label , for a given data depth
   *
   * @param rowLabel {string} - label identifying the sheet row (category/subcategory label or accounting entry label)
   * @param rowDepth {number | null} - row depth , 1 = category, 2 = subcategory, 3 = simple accounting entry
   *
   * @example
   * const entriesForHumanResourcesCategory = getEntriesByRowLabelAndDepth('Human Resources', 1);
   * const entriesForSalariesSubCategory = getEntriesByRowLabelAndDepth('Salaries', 2);
   * const entriesForSomeAccountingEntry = getEntriesByRowLabelAndDepth('Libelle anonymisé 13849143', 3);
   *
   * @returns {Array<{}>}
   */
  const getEntriesByRowLabelAndDepth = (rowLabel, rowDepth = 3) => {
    let entries = [];
    const isCategoryOrSubcategory = rowDepth === 1 || rowDepth === 2; // For now, sheet is only 3 levels deep (category > subcategory > data)
    if (!isCategoryOrSubcategory) {
      entries = data.filter(entry => entry.label === rowLabel);
    } else {
      const propertyName = rowDepth === 2 ? 'racine3_subname' : 'racine3_name';
      entries = data.filter(entry => entry[propertyName] === rowLabel);
    }
    return entries;
  };

  /**
   * Returns the cell value for a given month (months are columns in the spreadsheet)
   *
   * @param rowLabel {string} - label identifying the sheet row (category/subcategory label or accounting entry label)
   * @param columnId {string} - label of column , composed by year and month, or only year (examples : 2023 / 01-2023 / 02-2023)
   * @param rowDepth {number | null} - row depth , 1 = category, 2 = subcategory, 3 = simple accounting entry
   *
   * @example
   * const januaryHumanResourcesTotal = getMonthCellTotal('Salaries', 1, 1);
   * const januarySalariesTotal = getMonthCellTotal('Salaries', 1, 2);
   *
   * @returns {number}
   */
  const getMonthCellTotal = (rowLabel, columnId, rowDepth = null) => {
    const entries = getEntriesByRowLabelAndDepth(rowLabel, rowDepth);

    return entries.reduce((accumulator, entry) => {
      const entryAmountForMonth = getOneAccountingEntryAmountByMonth(entry, columnId);
      return accumulator + entryAmountForMonth;
    }, 0);
  };

  /**
   * Returns the cell value for a given year (years are columns in the spreadsheet)
   *
   * @param rowLabel {string} - label identifying the sheet row (category/subcategory label or accounting entry label)
   * @param year {string} - fiscal year we want to get all related columns
   * @param rowDepth {number | null} - row depth , 1 = category, 2 = subcategory, 3 = simple accounting entry
   *
   * @example
   * const currentYearHumanResourcesTotal = getYearCellTotal('Human resources', 2023, 1);
   *
   * @returns {number}
   */
  const getYearCellTotal = (rowLabel, year, rowDepth = null) => {
    let total = 0;
    const yearColumns = getFiscalYearColumns(currentProject.first_fiscal_month, year, budgetYears);

    if (isRatiosColumnVisible) {
      yearColumns.unshift(year);
    }

    yearColumns.forEach(columnId => {
      const totalForMonth = getMonthCellTotal(rowLabel, columnId, rowDepth);
      total += totalForMonth;
    });

    return total;
  };

  /**
     /**
     * Returns sheet cells for a given row (row is identified by its `rowLobel`)
     *
     * @param rowLabel {string} - label identifying the sheet row (category/subcategory label or accounting entry label)
     * @param rowDepth {number | null} - row depth , 1 = category, 2 = subcategory, 3 = simple accounting entry
     * @param rowId {number | null} - an index to indicate row position in array. Used to add alternate background css class (is even: blue background, is odd : white background)
     *
     * @example
     * const januaryHumanResourcesCategoryTotal = getSingleRowCells('Human Resources', 1);
     * const januarySalariesSubCategoryTotal = getSingleRowCells('Salaries', 2);
     *
     * @returns {Array<{
     *     type: string,
     *     value: number
     * }>}
     */
  const getSingleRowCells = (rowLabel, rowDepth = null, rowId = null) => {
    const cells = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const year of budgetYears) {
      const yearCA = getYearCellTotal("Chiffre d'affaires", year, 1);
      const yearTotal = getYearCellTotal(rowLabel, year, rowDepth);
      const yearCellCssClass = getYearTotalCellCssClasses(year, yearTotal, expandedColumns);
      cells.push({
        type: 'number',
        value: yearTotal,
        rowLabel,
        className: yearCellCssClass,
        nonEditable: true
      });

      if (isRatiosColumnVisible) {
        const ratio = Math.abs(yearTotal / yearCA);
        const roundedPercentage = getRoundedPercentage(ratio);
        const boldCssClass = (rowDepth === 1 || rowDepth === 2) && expandedColumns.includes(year) ? 'cell-ratio-bold' : '';

        const yearRatioCell = {
          type: 'text',
          text: Number.isNaN(ratio) ? '0 %' : `${roundedPercentage} %`,
          className: `cell-ratio ${boldCssClass}`,
          nonEditable: true
        };
        cells.push(yearRatioCell);
      }

      if (expandedColumns.includes(year)) {
        const yearColumns = getFiscalYearColumns(currentProject.first_fiscal_month, year, budgetYears);

        yearColumns.forEach(columnId => {
          const total = getMonthCellTotal(rowLabel, columnId, rowDepth);
          const monthCellCssClass = getMonthTotalCellCssClasses(rowId, rowDepth, total, expandedRows);
          const isCalculatedCell = rowDepth === 1 || rowDepth === 2;
          const monthTotalCell = {
            type: 'number',
            value: total,
            rowLabel,
            propertyToEdit: columnId,
            className: monthCellCssClass,
            nonEditable: isCalculatedCell || !isEditable
          };
          cells.push(monthTotalCell);
        });
      }
    }

    return cells;
  };

  /**
   *
   * Returns all accounting entries rows for a given category / subcategory
   *
   * @param categoryLabel {string} - label identifying the category/subcategory
   * @param isSubcategory {boolean} - determines if category is parent or subcategory
   * @param categoryRowId {number} - sheet row id
   *
   * @example
   * const accountingSalariesSubcategoryEntries =  getCategoryAccountingEntriesRows('Salaries', true, 3);
   *
   * @returns {Array<BudgetSpreadSheetRow>}
   */
  const getCategoryAccountingEntriesRows = (categoryLabel, isSubcategory, categoryRowId) => {
    const propertyName = isSubcategory ? 'racine3_subname' : 'racine3_name';
    const entries = data.filter(entry => entry[propertyName] === categoryLabel);
    const uniqueSubCategories = getUniqueSubCategories();
    const parentCategoryLabel = isSubcategory ? uniqueSubCategories.find(c => c.subCategory === categoryLabel)?.parentCategory : null; // TODO Find parentcategory

    return entries.map((entry, index) => {
      const rowId = categoryRowId + index + 1;
      const accountingEntriesCells = getSingleRowCells(entry.label, 3, rowId);
      const backgroundCssClass = rowId % 2 === 0 ? 'constrast-bg-cell' : 'white-bg-cell';

      return {
        rowId,
        category: categoryLabel,
        isSubcategory,
        parentCategory: parentCategoryLabel,
        isAccountingEntryRow: true,
        height: 40,
        cells: [
          {
            type: 'chevron',
            text: entry.label,
            parentId: categoryRowId,
            hasChildren: false,
            isExpanded: false,
            indent: 2,
            className: `first-column-cell accounting-entry ${backgroundCssClass}`
          },
          ...accountingEntriesCells
        ]
      };
    });
  };

  /**
   *
   * Returns all subcategories rows for a given parent category
   *
   * @param parentRow {BudgetSpreadSheetRow} - parent row for which we want to get subcategories
   *
   * @example
   * const categoryRow = {
   *   rowId: 1,
   *   category: 'Human Resources',
   *   cells: []
   * };
   * const humanResourcesCategorySubCategories = getSubCategoriesRows(categoryRow);
   *
   * @returns {Array<BudgetSpreadSheetRow>}
   */
  const getSubCategoriesRows = parentRow => {
    const parentCategoryRowId = parentRow.rowId;
    const uniqueSubCategories = getUniqueSubCategories();
    const subCategoriesOfParentRow = uniqueSubCategories.filter(cat => cat.parentCategory === parentRow.category);

    let numberOfEntriesInLastCategory = 0;
    let allSubcategoriesEntries = [];

    const subCategoriesRows = subCategoriesOfParentRow.map((c, index) => {
      const rowId = parentCategoryRowId + numberOfEntriesInLastCategory + index + 1;
      const isSubCategoryExpanded = expandedRows?.find(r => r === rowId) !== undefined;
      const isSubCategoryHidden = hiddenRows.includes(rowId);
      const accountingEntries = isSubCategoryExpanded && (!isSubCategoryHidden || hiddenRowsVisible) ? getCategoryAccountingEntriesRows(c.subCategory, true, rowId) : [];
      allSubcategoriesEntries = [...allSubcategoriesEntries, ...accountingEntries];
      numberOfEntriesInLastCategory += accountingEntries.length;

      const cells = getSingleRowCells(c.subCategory, 2, rowId);

      return {
        rowId,
        category: c.subCategory,
        isSubcategory: true,
        parentCategory: parentRow.category,
        height: 40,
        cells: [
          {
            type: 'chevron',
            text: c.subCategory,
            parentId: parentCategoryRowId,
            hasChildren: true,
            indent: 1,
            isExpanded: isSubCategoryExpanded,
            className: 'first-column-cell sub-category-cell'
          },
          ...cells
        ]
      };
    });

    return [...subCategoriesRows, ...allSubcategoriesEntries];
  };

  /**
   *
   * Returns all rows for a given category
   *
   * @param category {string} - the category for which we want to get all children rows
   * @param index {number} - row index that will become rowId
   * @param returnEntriesEvenIfNotExpanded {boolean} - forces method to return children accouting entries rows for the given category
   *
   * @example
   * const achatCategoryRows = getRowsForCategory('Achat', 10);
   * @returns {Array<BudgetSpreadSheetRow>}
   */
  const getRowsForCategory = (category, index, returnEntriesEvenIfNotExpanded = false) => {
    const isCategoryExpanded = expandedRows?.find(r => r === index) !== undefined;
    const cells = getSingleRowCells(category, 1, index);
    const accountingEntriesForCategory = hasSubCategories(category) ? [] : getCategoryAccountingEntriesRows(category, false, index);

    const categoryRow = {
      rowId: index,
      height: 40,
      category,
      isSubcategory: false,
      parentCategory: null,
      cells: [
        {
          type: 'chevron',
          text: category,
          hasChildren: hasSubCategories(category) || accountingEntriesForCategory.length > 0, // a parent category can has either sub-categories, or directly accounting entries
          isExpanded: isCategoryExpanded,
          className: `first-column-cell category-cell ${expandedRows.length > 0 ? 'bold' : ''}`
        },
        ...cells
      ]
    };

    const subCategoriesRows = isCategoryExpanded ? getSubCategoriesRows(categoryRow) : [];
    const categoryRows = [categoryRow, ...subCategoriesRows, ...(isCategoryExpanded || returnEntriesEvenIfNotExpanded ? accountingEntriesForCategory : [])];
    return categoryRows;
  };

  /**
   *
   * Returns the total amount for ALL categories for a given year column
   *
   * @param year {number} - the year for which we want to get total amount
   * @example
   * const totalFor2023Year = getYearColumnTotal(2023);
   * @returns {number}
   */
  const getYearColumnTotal = year => {
    return data.reduce((accumulator, entry) => {
      const dateProperties = Object.keys(entry).filter(property => property.startsWith(year.toString()));
      let amount = 0;
      dateProperties.forEach(month => {
        amount += entry[month] ? parseFloat(entry[month]) : 0;
      });
      return accumulator + amount;
    }, 0);
  };

  /**
   *
   * Returns the total amount for ALL categories for a given month column
   *
   * @param columnId {string} - the month column id for which we want to get total amount (eg: '2023-01' for January 2023)
   * @example
   * const totalForJanuary2023 = getMonthColumnTotal('2023-01');
   * @returns {number}
   */
  const getMonthColumnTotal = columnId => {
    const monthEntries = data.filter(entry => entry[columnId] !== null);
    return monthEntries.reduce((accumulator, entry) => {
      const amount = entry[columnId];
      return accumulator + parseFloat(amount);
    }, 0);
  };

  /**
   *
   * Returns spreadsheet bottom row cells (the bottom row is a row containing total for each month / year column)
   *
   * @returns {Array<BudgetSpreadSheetCell>}
   */
  const getBottomTotalRowCells = () => {
    const cells = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const year of budgetYears) {
      const yearCA = getYearCellTotal("Chiffre d'affaires", year, 1);
      const yearTotal = getYearColumnTotal(year);
      const yearClassname = getTotalColumnCssClasses(yearTotal, true);
      cells.push({
        type: 'number',
        value: yearTotal,
        nonEditable: true,
        className: yearClassname
      });

      if (isRatiosColumnVisible) {
        const ratio = Math.abs(yearTotal / yearCA);
        const roundedPercentage = getRoundedPercentage(ratio);

        const ratioCell = {
          type: 'text',
          text: Number.isNaN(ratio) ? '0 %' : `${roundedPercentage} %`,
          nonEditable: true,
          className: 'cell-ratio cell-ratio-bold'
        };
        cells.push(ratioCell);
      }

      if (expandedColumns.includes(year)) {
        const yearColumns = getFiscalYearColumns(currentProject.first_fiscal_month, year, budgetYears);

        yearColumns.forEach(columnId => {
          const total = getMonthColumnTotal(columnId);
          const monthClassname = getTotalColumnCssClasses(total, false);
          const monthTotalCell = {
            type: 'number',
            value: total,
            propertyToEdit: columnId,
            nonEditable: true,
            className: monthClassname
          };
          cells.push(monthTotalCell);
        });
      }
    }

    return cells;
  };

  /**
   *
   * Returns spreadsheet bottom row (the bottom row is a row containing total for each month / year column)
   *
   * @returns {BudgetSpreadSheetRow}
   */
  const getBottomTotalRow = numberOfRows => {
    const cells = getBottomTotalRowCells();

    return {
      rowId: numberOfRows,
      height: 40,
      isSubcategory: false,
      category: null,
      parentCategory: null,
      isAccountingEntryRow: null,
      cells: [
        {
          type: 'text',
          text: 'Totaux',
          className: 'first-column-cell category-cell first-column-title'
        },
        ...cells
      ]
    };
  };

  /**
   *
   * Returns all sheet rows
   *
   * @returns {Array<BudgetSpreadSheetRow>}
   */
  const getRows = () => {
    const uniqueCategories = getUniqueCategories();
    // TODO Maybe we can remove index and use entry.line + hide children by looking at their parent category
    let index = 0;
    const allRows = uniqueCategories?.map(category => {
      const isRowVisible = !hiddenRows.includes(index);
      const shouldDisplayCategory = hiddenRowsVisible || isRowVisible;

      if (shouldDisplayCategory) {
        const categoryRows = getRowsForCategory(category, index);
        const numberOfRowsForCategory = categoryRows.length;
        index += numberOfRowsForCategory;
        return categoryRows.filter(r => !hiddenRows.includes(r.rowId) || hiddenRowsVisible);
      }
      index += 1;
      return [];
    });

    const flattenedRows = allRows.flat();

    if (isTotalRowVisible) {
      const maxRowId = Math.max(...flattenedRows.map(row => row.rowId));
      const totalRow = getBottomTotalRow(maxRowId + 1);
      flattenedRows.push(totalRow);
    }

    return flattenedRows;
  };

  const getAllRows = newExpandedColumns => [getBudgetHeaderRow(budgetYears, newExpandedColumns, currentProject.first_fiscal_month, isRatiosColumnVisible), ...getRows()];

  // Following functions handle interactions from user with the spreadsheet
  const handleCategoryOpenOrClose = sheetChange => {
    const {rowId, columnId, type} = sheetChange;
    const isOpenOrCloseDropdownEvent = type === 'chevron' && sheetChange.previousCell.isExpanded !== sheetChange.newCell.isExpanded;
    const hasBeenClosed = sheetChange.newCell.isExpanded === false;

    if (isOpenOrCloseDropdownEvent && rowId !== 'header') {
      setExpandedRows(prevExpandedRows => {
        const rowCategory = getCategoryByRowId(rowId);
        const numberOfItemsInCategory = rowCategory.isSubcategory
          ? getCategoryAccountingEntriesRows(rowCategory.category, true, rowId)?.length
          : // eslint-disable-next-line no-unsafe-optional-chaining
            getRowsForCategory(rowCategory.category, rowId, true)?.length - 1;
        const updatedExpandedRows = prevExpandedRows
          .filter(r => r !== rowId) // we remove old row id
          .map(r => {
            // we update all rowsIds to be up-to-date (if we close a category with 5 items, we have to withdraw 5 to all following row ids)
            if (r < rowId) {
              return r;
            }
            if (hasBeenClosed) {
              return r - numberOfItemsInCategory > 0 ? r - numberOfItemsInCategory : null;
            }
            return r + numberOfItemsInCategory;
          })
          .filter(item => item !== null);
        if (!hasBeenClosed) {
          updatedExpandedRows.push(rowId);
        }
        return updatedExpandedRows;
      });
    }

    if (isOpenOrCloseDropdownEvent && rowId === 'header') {
      setExpandedColumns(prevExpandedCols => (hasBeenClosed ? prevExpandedCols.filter(col => col !== columnId) : [...prevExpandedCols, columnId]));
    }
  };

  const handleAccountingEntryLabelEdit = sheetChange => {
    const accountingEntryLabel = sheetChange.previousCell.text;
    const newAccountingEntryLabel = sheetChange.newCell.text;
    const editedEntryIndex = data.findIndex(entry => entry.label === accountingEntryLabel && entry.budget_name === selectedBudget);
    const entryToEdit = {...data[editedEntryIndex]};

    if (entryToEdit) {
      const updatedData = [...data];
      entryToEdit.label = newAccountingEntryLabel;
      updatedData[editedEntryIndex] = entryToEdit;
      setData(updatedData);
    }
  };

  const handleAccountingEntryValueEdit = sheetChange => {
    const {type} = sheetChange;
    const accountingEntryValue = sheetChange.previousCell.value;
    const newAccountingEntryValue = sheetChange.newCell.value;
    const categoryOrSubcategoryLabelChanged = accountingEntryValue !== newAccountingEntryValue;

    if (type === 'number' && categoryOrSubcategoryLabelChanged) {
      const updatedData = [...data];
      const editedEntryIndex = data.findIndex(entry => entry.label === sheetChange.newCell.rowLabel && entry.budget_name === selectedBudget);
      const entryToEdit = {...data[editedEntryIndex]};
      entryToEdit[sheetChange.newCell.propertyToEdit] = newAccountingEntryValue;
      updatedData[editedEntryIndex] = entryToEdit;
      setData(updatedData);
    }
  };

  const isCategoryOrSubcategoryChange = change => {
    const {type} = change;
    const isCategoryOrSubcategoryCell = change.newCell.hasChildren && change.previousCell.hasChildren;
    const categoryOrSubcategoryLabelChanged = change.previousCell.text !== change.newCell.text;
    if (type === 'chevron' && isCategoryOrSubcategoryCell && categoryOrSubcategoryLabelChanged) {
      return true;
    }
    return false;
  };

  const applyChangesToEntries = changes => {
    // eslint-disable-next-line consistent-return
    changes.forEach(change => {
      // User should not be able to edit categories or subcategories cells but we cannot make chevron cell non editable
      // Due to a bug in reactgrid library (see: https://stackoverflow.com/questions/76727159/noneditable-prop-have-bug-with-chevron-cell)
      if (isCategoryOrSubcategoryChange(change)) {
        return false;
      }

      handleCategoryOpenOrClose(change);
      handleAccountingEntryLabelEdit(change);
      handleAccountingEntryValueEdit(change);
    });
  };

  const handleChanges = changes => {
    applyChangesToEntries(changes);
  };

  const handleAddNewAccountingEntry = newEntryData => {
    const datesProperties = budgetYears.map(year => getFiscalYearColumns(currentProject.first_fiscal_month, year, budgetYears)).flat();

    const newAccountingEntry = {
      budget_name: selectedBudget,
      ...newEntryData
    };
    datesProperties.forEach(date => {
      newAccountingEntry[date] = null;
    });

    setData(currentData => {
      currentData.push(newAccountingEntry);
      return [...currentData];
    });
  };

  const handleRemoveNewAccountingEntry = rowId => {
    const selectedRow = rows[rowId + 1]; // +1 because of header row
    const accountingEntryLabel = selectedRow?.cells[0]?.text;
    setData(currentData => {
      return [...currentData.filter(entry => entry.label !== accountingEntryLabel)]; // TODO HANDLE WITH LINE AS IN FORECASTSPREADSHEET ONCE ADRIAN UPDATED THE API ROUTE
    });
  };

  const openAddLineModal = category => {
    setNewAccountingEntryCategory(category);
    setAddLineModalOpen(true);
  };

  // eslint-disable-next-line complexity
  const handleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
    if (selectedRowId === null) {
      return [];
    }

    let options = [...menuOptions];
    const category = getCategoryByRowId(selectedRowIds[0]); // Using this method on a single accounting entry node results in undefined

    // eslint-disable-next-line consistent-return
    if (selectedRowIds.length === 1 && selectedRowIds[0] === 'header') return;

    // We only display 'Ajouter une ligne' when user selects a single row.
    // Selected row must be either a single data node OR a category without subcategories OR a subcategory itself.
    // We cannot add a line to a parent category having sub-categories to the root level of the parent category. (business need)
    const categoryHasSubcategories = hasSubCategories(category?.category);
    if (selectionMode === 'row' && selectedRowIds.length === 1 && !categoryHasSubcategories) {
      options = addContextMenuOption(options, 'addChildRow', 'Ajouter une ligne', () => openAddLineModal(category));
    }

    const selectedRow = rows[selectedRowIds[0] + 1]; // we have to add 1 because of header row
    // We only display 'Supprimer une ligne' when user selects a single row that is NOT a category or subcategory
    if (selectionMode === 'row' && selectedRowIds.length === 1 && selectedRow.isAccountingEntryRow) {
      options = addContextMenuOption(options, 'removeChildRow', 'Supprimer une ligne', () => handleRemoveNewAccountingEntry(selectedRowIds[0]));
    }

    // We only display 'Masquer une ligne' when user selects a single row OR he right-clicks on a cell (no rows selected)
    if (selectedRowIds.length <= 1 && !hiddenRows.includes(selectedRowId)) {
      options = addContextMenuOption(options, 'hideRows', 'Masquer la ligne', () => hideRows([selectedRowId]));
    }

    // We only display 'Montrer une ligne' when user selects a single row OR he right-clicks on a cell (no rows selected)
    if (selectedRowIds.length <= 1 && hiddenRows.includes(selectedRowId)) {
      options = addContextMenuOption(options, 'showRows', 'Démasquer la ligne', () => showRows([selectedRowId]));
    }

    if (selectedRowIds.length > 1) {
      options = addContextMenuOption(options, 'hideManyRows', 'Masquer ces lignes', () => hideRows(selectedRowIds));
      options = addContextMenuOption(options, 'showManyRows', 'Démasquer ces lignes', () => showRows(selectedRowIds));
    }

    return options;
  };

  // This hook listens for cell selection events to expand/close months columns in a custom way
  const onFocusLocationChanged = cellLocation => {
    setSelectedRowId(cellLocation.rowId);
    if (cellLocation.rowId === 'header' && budgetYears.includes(cellLocation.columnId)) {
      setExpandedColumns(prevExpandedCols => {
        let newExpandedCols = [...prevExpandedCols];
        if (expandedColumns.includes(cellLocation.columnId)) {
          newExpandedCols = newExpandedCols.filter(c => c !== cellLocation.columnId);
        } else {
          newExpandedCols.push(cellLocation.columnId);
        }
        return newExpandedCols;
      });
    }
  };

  // This hook aims to re-paint the whole spreadsheet when data is update, or rows are expanded/collapsed
  useEffect(() => {
    (async () => {
      const updatedRows = getAllRows(expandedColumns);
      setRows(updatedRows);
    })();
  }, [expandedRows, expandedColumns, data, isTotalRowVisible, isRatiosColumnVisible, hiddenRows, hiddenRowsVisible]);

  if (isDataLoading) {
    return <Spinner size={128} text="Chargement des données en cours" />;
  }

  const columns = getBudgetColumns();

  return (
    <div id={`reactgrid-${process.env.REACT_APP_COMPANY_NAME}`}>
      {data?.length > 1 && (
        <ReactGrid
          stickyLeftColumns={1}
          onCellsChanged={handleChanges}
          rows={rows?.sort((a, b) => parseInt(a.rowId, 10) - parseInt(b.rowId, 10))}
          columns={columns}
          onColumnResized={handleColumnResize}
          onContextMenu={handleContextMenu}
          enableFillHandle
          enableRowSelection
          onFocusLocationChanged={onFocusLocationChanged}
        />
      )}

      <CreateBudgetAccountingEntryModal
        categories={getUniqueCategories()}
        subcategories={getUniqueSubCategories()}
        category={newAccountingEntryCategory}
        closeModal={() => setAddLineModalOpen(false)}
        isOpen={addLineModalOpen}
        onSubmit={handleAddNewAccountingEntry}
      />
    </div>
  );
};

BudgetSpreadsheet.propTypes = {
  isDataLoading: bool.isRequired,
  currentProject: shape({}).isRequired,
  setAddLineModalOpen: func.isRequired,
  addLineModalOpen: bool.isRequired,
  customColumnsWidth: arrayOf(number).isRequired,
  handleColumnResize: func.isRequired,
  data: arrayOf(shape({})).isRequired,
  setData: func.isRequired,
  isTotalRowVisible: bool.isRequired,
  isRatiosColumnVisible: bool.isRequired,
  hiddenRows: arrayOf(number).isRequired,
  hiddenRowsVisible: bool.isRequired,
  showRows: func.isRequired,
  hideRows: func.isRequired
};

export default memo(BudgetSpreadsheet);
