import {useState, useMemo, useEffect} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_WORKSPACE, SNACKBAR_ACTIONS} from '../const';
import WorkspacesContext from '../contexts/WorkspacesContext';
import useHttp from '../hooks/misc/useHttp';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import {getFileB64} from '../utils';

const WorkspacesProvider = ({children}) => {
  const {loadReports, setReports} = useReport();

  const [workspaces, setWorkspaces] = useState([DEFAULT_WORKSPACE]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(DEFAULT_WORKSPACE);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(DEFAULT_WORKSPACE.workspace_id);
  const [createReportModalOpen, setCreateReportModalOpen] = useState(false);
  const [workspacesTabsMenuAchorEl, setWorkspacesTabsMenuAchorEl] = useState(null);
  const [importReportError, setImportReportError] = useState('');
  const [isCustomReportCreation, setIsCustomReportCreation] = useState(false);
  const [deleteWorkspaceOrReportModalOpen, setDeleteWorkspaceOrReportModalOpen] = useState(false);
  const [anchorElReportTabsMenu, setAnchorElReportTabsMenu] = useState(null);
  const [rightClickedReportTab, setRightClickedReportTab] = useState(null);
  const [isExistingWorkspaceEdition, setIsExistingWorkspaceEdition] = useState(false);
  const [createOrUpdateWorkspaceModalOpen, setCreateOrUpdateWorkspaceModalOpen] = useState(false);
  const [rightClickedWorkspace, setRightClickedWorkspace] = useState(null);

  const addReportToWorkspace = (workspaceId, reportName, reportId, isCustomReport = false) => {
    setWorkspaces(currentWorkspaces => {
      const newWorkspaces = [...currentWorkspaces];
      const currentWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
      const updatedWorkspace = newWorkspaces[currentWorkspaceIndex];
      updatedWorkspace.reports.push({
        report_name: reportName,
        report_id: reportId,
        isCustomModel: isCustomReport
      });
      newWorkspaces[currentWorkspaceIndex] = updatedWorkspace;
      return newWorkspaces;
    });
  };

  useEffect(() => {
    setSelectedWorkspace((workspaces || []).find(w => w.workspace_id === selectedWorkspaceId));
  }, [selectedWorkspaceId]);

  const openWorkspaceTabsMenu = event => {
    setWorkspacesTabsMenuAchorEl(event.currentTarget);
  };

  const closeWorkspaceTabsMenu = () => {
    setWorkspacesTabsMenuAchorEl(null);
  };

  const {_post, _get} = useHttp();
  const {showSnackbar, defaultSnackbarOptions} = useSnackbar();

  const getWorkspaces = async () => {
    const url = API_ENDPOINTS.workspaces.findAll;
    try {
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setWorkspaces([DEFAULT_WORKSPACE, ...data]);

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setWorkspaces([]);
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createWorkspace = async name => {
    const url = API_ENDPOINTS.workspaces.create;
    try {
      const {response, responseJson: data} = await _post(url, {
        workspace_name: name
      });

      if (response.status === 200) {
        closeWorkspaceTabsMenu();
        showSnackbar(SNACKBAR_ACTIONS.CREATE_WORKSPACE_SUCCESS, defaultSnackbarOptions, {
          workspaceName: data.workspace_name
        });

        setWorkspaces(currentWorkspaces => {
          const newWorkspaces = [...currentWorkspaces];
          newWorkspaces.push(data);
          return newWorkspaces;
        });
        await loadReports();

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteWorkspace = async workspaceId => {
    console.log('here');
    const url = API_ENDPOINTS.workspaces.delete;
    try {
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId
      });

      if (response.status === 200) {
        closeWorkspaceTabsMenu();
        showSnackbar(SNACKBAR_ACTIONS.DELETE_WORKSPACE_SUCCESS, defaultSnackbarOptions, {
          workspaceName: selectedWorkspace.workspace_name
        });
        const associatedReportsIds = selectedWorkspace.reports.map(r => r.report_id);
        setReports(reportsBeforeWorkspaceDeletion => reportsBeforeWorkspaceDeletion.filter(r => !associatedReportsIds.includes(r.report_id)));
        setWorkspaces(workspacesBeforeDeletion => workspacesBeforeDeletion.filter(w => w.workspace_id !== workspaceId));
        setSelectedWorkspaceId(DEFAULT_WORKSPACE.workspace_id);
        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const createReport = async (name, workspaceId) => {
    const url = API_ENDPOINTS.reports.create;
    try {
      const {response, responseJson: data} = await _post(url, {
        report_name: name,
        workspace_id: workspaceId
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_REPORT_SUCCESS, defaultSnackbarOptions, {
          reportName: name
        });

        addReportToWorkspace(workspaceId, name, data.report_id);

        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const deleteReport = async (workspaceId, reportId) => {
    const url = API_ENDPOINTS.reports.delete;
    try {
      const {response, responseJson: data} = await _post(url, {
        report_id: reportId,
        workspace_id: workspaceId
      });
      setWorkspaces(workspacesBeforeReportDeletion => {
        const newWorkspaces = [...workspacesBeforeReportDeletion];
        const deletedReportWorkspaceIndex = newWorkspaces.findIndex(w => w.workspace_id === workspaceId);
        const deletedReportWorkspace = newWorkspaces[deletedReportWorkspaceIndex];
        deletedReportWorkspace.reports = deletedReportWorkspace.reports.filter(r => r.report_id !== reportId);
        newWorkspaces[deletedReportWorkspaceIndex] = deletedReportWorkspace;
        return newWorkspaces;
      });

      if (response.status === 200) {
        await loadReports();
        showSnackbar(data);
        return {
          status: 200,
          success: true
        };
      }
      // eslint-disable-next-line no-console
      console.error({response, data});
      throw Error(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false,
        messsage: e.message
      };
    }
  };

  const importReport = async reportData => {
    const {file, workspaceId, role, isEffectiveIdentityRolesRequired} = reportData;
    setImportReportError('');
    try {
      const b64 = await getFileB64(file);

      const url = API_ENDPOINTS.reports.import;
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        filename: file.name,
        file_b64: b64.split('base64,')[1],
        isEffectiveIdentityRolesRequired,
        ...(Boolean(isEffectiveIdentityRolesRequired) && {role})
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }
      await loadReports();
      showSnackbar(SNACKBAR_ACTIONS.IMPORT_REPORT_SUCCESS);
      addReportToWorkspace(workspaceId, data.report_name, data.report_id, true);

      return {
        status: 200,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setImportReportError(e.message);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const exportReport = async (workspaceId, reportId) => {
    try {
      const url = API_ENDPOINTS.reports.export;
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        report_id: reportId
      });

      if (response.status !== 200) {
        throw Error(data?.message || data);
      }
      return {
        status: 200,
        data,
        success: true
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setImportReportError(e.message);
      return {
        message: e.message,
        success: false
      };
    }
  };

  const openCreateWorkspaceModal = () => {
    setIsExistingWorkspaceEdition(false);
    setCreateOrUpdateWorkspaceModalOpen(true);
    closeWorkspaceTabsMenu();
  };

  const memoizedValues = {
    getWorkspaces,
    workspaces,
    numberOfWorkspaces: workspaces.length,
    createWorkspace,
    deleteWorkspace,
    selectedWorkspace,
    selectedWorkspaceId,
    setSelectedWorkspaceId,
    createReport,
    deleteReport,
    createReportModalOpen,
    setCreateReportModalOpen,
    isDefaultWorkspace: selectedWorkspaceId === DEFAULT_WORKSPACE.workspace_id,
    workspacesTabsMenuAchorEl,
    openWorkspaceTabsMenu,
    closeWorkspaceTabsMenu,
    importReport,
    importReportError,
    setImportReportError,
    exportReport,
    isCustomReportCreation,
    setIsCustomReportCreation,
    setWorkspacesTabsMenuAchorEl,
    anchorElReportTabsMenu,
    setAnchorElReportTabsMenu,
    rightClickedReportTab,
    setRightClickedReportTab,
    openCreateWorkspaceModal,
    isExistingWorkspaceEdition,
    setIsExistingWorkspaceEdition,
    createOrUpdateWorkspaceModalOpen,
    setCreateOrUpdateWorkspaceModalOpen,
    deleteWorkspaceOrReportModalOpen,
    setDeleteWorkspaceOrReportModalOpen,
    rightClickedWorkspace,
    setRightClickedWorkspace
  };
  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <WorkspacesContext.Provider value={value}>{children}</WorkspacesContext.Provider>;
};

WorkspacesProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default WorkspacesProvider;
