/* eslint-disable react/jsx-props-no-spreading */
import {Tab} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

const StyledTab = muiStyled(({isReportTab, ...props}) => <Tab disableRipple {...props} />)(({theme, ...props}) => ({
  ...(props.isReportTab
    ? // Style specific to ReportTabs
      {
        fontSize: theme.typography.pxToRem(13),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minWidth: 70,
        [theme.breakpoints.down('sm')]: {
          minWidth: 60,
          paddingLeft: theme.spacing(0.5),
          paddingRight: theme.spacing(0.5),
          marginRight: theme.spacing(0.5),
          marginLeft: theme.spacing(0.5)
        },
        [theme.breakpoints.up('md')]: {
          minWidth: 90
        }
      }
    : // Style specific to WorkspacesTabs
      {
        flex: 1,
        lineHeight: 1.5,
        fontSize: theme.typography.pxToRem(18),
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
        // Tab separator
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          height: '50%', // Adjust the height as needed
          width: '1px',
          backgroundColor: '#eeeeee',
          right: 0,
          top: '25%' // Adjust the top position to vertically center the line
        },
        '&:last-of-type::before': {
          content: 'none' // Remove the separator for the last tab
        }
      }),

  // Common style to both ReportTabs and WorkspacesTabs
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.primary.main,
  fontFamily: 'SoehneBreitKraftig',
  '&.Mui-selected': {
    color: theme.palette.secondary.main
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.secondary.main
  },
  '&.Mui-indicator': {
    color: `${theme.palette.secondary.main}`
  }
}));

export default StyledTab;
