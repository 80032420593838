import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import useProjects from '../../../hooks/providers/useProjects';
import useReport from '../../../hooks/providers/useReport';
import EditableReportSaveAsModal from '../EditableReportSaveAsModal';
import EditReportButton from './EditReportButton';
import SaveReportButton from './SaveReportButton';

const EditableReportButtons = () => {
  const {isReportEditable, isDesktopLayout} = useReport();
  const {isGuestMode} = useProjects();

  const shouldDisplayButton = isReportEditable && isDesktopLayout && !isGuestMode;

  if (!shouldDisplayButton) return null;

  return (
    <Box sx={{position: 'fixed', bottom: 6}}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <EditReportButton />
        </Grid>
        <Grid item>
          <SaveReportButton />
        </Grid>
      </Grid>
      <EditableReportSaveAsModal />
    </Box>
  );
};

export default EditableReportButtons;
