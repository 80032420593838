import {useEffect} from 'react';

import ReportIcon from '@mui/icons-material/Report';
import {Grid, Typography} from '@mui/material';
import {useLocation, useNavigate, useRouteError} from 'react-router-dom';

import Button from '../components/form/buttons/Button';
import {APP_ROUTES} from '../const';
import useAuth from '../hooks/providers/useAuth';
import useProjects from '../hooks/providers/useProjects';

const ErrorComponent = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const location = useLocation();
  const projectsContext = useProjects();
  const auth = useAuth();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log({error});
    const subject = 'Drivn - Error reporting (front)';
    const message = `route front: ${location.pathname} ----  error message: ${error.message} --- error stack : ${error.stack}`;
    const isDevelopment = process.env.NODE_ENV === 'development';

    // We do not want to be notified of a front error for 404 or 401, or if it's a development context.
    // [TODO] replace this we proper error reporting solution, like Sentry for example
    if (!isDevelopment && error.status !== 404 && error.status !== 401) {
      projectsContext.sendContactMessage(subject, message, false);
    }
  }, []);

  const getErrorMessage = () => {
    let errorMessage = '';

    switch (error.status) {
      case 404:
        errorMessage = "La page que vous recherchez semble introuvable. Êtes-vous sûr d'accéder à la bonne adresse URL ?";
        break;
      case 401:
        errorMessage = auth.loggedIntoBadPartnerErrorMessage;
        break;
      default:
        errorMessage = "L'application a rencontré une erreur inattendue. Veuillez nous excuser pour la gêne occasionnée. Nous vous invitons à réactualiser la page ultérieurement.";
        break;
    }
    return errorMessage;
  };

  const buttonText = auth.loggedIntoBadPartnerErrorMessage ? "J'ai compris" : "Retourner à l'accueil";
  const onButtonClick = auth.loggedIntoBadPartnerErrorMessage ? null : () => navigate(APP_ROUTES.home);
  const buttonHref = auth.loggedIntoBadPartnerErrorMessage ? auth.loggedIntoBadPartnerRedirectUrl : null;

  return (
    <Grid sx={{height: '100vh', background: process.env.REACT_APP_MAIN_COLOR}} container justifyContent="center" alignItems="center" direction="column" flex={1}>
      <ReportIcon sx={{fontSize: 150, mb: 3}} color="warning" />
      <Typography variant="h3" fontWeight="bold" color="#FFF" mb={1}>
        {error.status === 404 ? 'Oups' : 'Erreur'}
      </Typography>
      <Typography variant="p" color="#FFF" sx={{maxWidth: 600}} py={2} textAlign="center" fontSize="large">
        {getErrorMessage()}
      </Typography>
      <Button href={buttonHref} sx={{mt: 2}} size="large" variant="contained" color="secondary" onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Grid>
  );
};

export default ErrorComponent;
