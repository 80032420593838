import {FormControlLabel, styled} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import {bool, func, node, oneOfType, string} from 'prop-types';

const CheckboxIcon = styled('span')(({theme}) => ({
  borderRadius: 4,
  width: 19,
  height: 19,
  boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`, // Use theme's primary color for border
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)'
  }
}));

const CheckboxCheckedIcon = styled(CheckboxIcon)(({theme}) => ({
  backgroundColor: '#FFF',
  backgroundImage: 'none',
  borderColor: theme.palette.primary.main,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    display: 'block',
    width: 11,
    height: 11,
    borderRadius: 2,
    backgroundColor: theme.palette.primary.main,
    content: '""'
  }
}));

const CustomCheckbox = ({disabled, checked, label, onChange, color, size = 'small'}) => {
  return (
    <FormControlLabel
      sx={{
        '& .MuiFormControlLabel-label': {
          fontFamily: 'InstrumentSansBold',
          fontSize: 12
        }
      }}
      control={
        <Checkbox
          sx={{
            borderRadius: 10
          }}
          checked={checked}
          disabled={disabled}
          size={size}
          color={color}
          onChange={onChange}
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxIcon />}
        />
      }
      label={label}
    />
  );
};

CustomCheckbox.defaultProps = {
  size: 'small',
  color: 'primary',
  disabled: false,
  checked: false
};

CustomCheckbox.propTypes = {
  onChange: func.isRequired,
  color: string,
  size: string,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool,
  // eslint-disable-next-line react/boolean-prop-naming
  checked: bool,
  label: oneOfType([node, string]).isRequired
};

export default CustomCheckbox;
