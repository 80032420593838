import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {bool} from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {APP_ROUTES, REPORT_LABELS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import {projectShape} from '../../../proptypes-shapes';
import {makeHumanFriendlyUrl} from '../../../utils';
import Button from '../../form/buttons/Button';

const SeeReportButton = ({project, disabled}) => {
  const {selectedReportTab, pennylaneDataIntegrationInProgress, pennylaneDataIntegrationSiren} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();
  const {reports} = useReport();

  const navigate = useNavigate();

  const getReportData = () => {
    let report;
    if (isDefaultWorkspace) {
      const name = REPORT_LABELS[selectedReportTab];
      report = {
        id: reports.find(r => r.report_name === name).report_id,
        name
      };
    } else {
      const rep = reports.find(r => r.report_id === selectedReportTab);
      report = {
        id: rep.report_id,
        name: rep.report_name
      };
    }
    return report;
  };

  const getReportUrl = () => {
    const reportName = getReportData().name;
    return makeHumanFriendlyUrl(APP_ROUTES.report(reportName));
  };

  return (
    <Button
      disabled={reports.length === 0 || disabled || (pennylaneDataIntegrationInProgress && pennylaneDataIntegrationSiren === project.siren)}
      endIcon={<ArrowForwardIcon sx={{marginTop: '1px'}} />}
      size="small"
      variant="contained"
      color="secondary"
      sx={{fontSize: 13}}
      onClick={() => {
        navigate(getReportUrl(), {
          state: {
            reportId: getReportData().id,
            projectSiren: project.siren
          }
        });
      }}
    >
      Voir le rapport
    </Button>
  );
};

SeeReportButton.propTypes = {
  project: projectShape.isRequired,
  disabled: bool.isRequired
};

export default SeeReportButton;
