import Box from '@mui/material/Box';

import LinkToRse from '../../spinner/LinkToRse';
import ReportSpinner from './ReportSpinner';

const ReportLoadingSpinner = () => {
  return (
    <ReportSpinner text="Chargement du rapport en cours">
      <Box mt={0.5}>
        <LinkToRse fontSize={13} />
      </Box>
    </ReportSpinner>
  );
};

export default ReportLoadingSpinner;
