import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';

import usePwa from '../../../hooks/providers/usePwa';
import {capitalizeWord, isMobile} from '../../../utils';
import Button from '../../form/buttons/Button';

// eslint-disable-next-line react/prop-types
const WebPwaPrompt = () => {
  const {webInstallPrompt, handleWebInstallAccept: onAccept} = usePwa();

  // User already has installed PWA
  if (navigator.standalone) {
    return null;
  }

  // User uses a mobile device
  if (isMobile()) {
    return null;
  }

  if (!webInstallPrompt) return null;

  const logo = `${process.env.PUBLIC_URL}/${process.env.REACT_APP_COMPANY_NAME}/manifest-icon-192.any.png`;
  const companyName = capitalizeWord(process.env.REACT_APP_COMPANY_NAME);

  return (
    <Grid container flexDirection="column" alignItems="center" mt={2}>
      <Typography color="primary" fontFamily="InstrumentSansBold" fontSize={16} mt={2} mb={2} variant="h1" align="center" gutterBottom>
        Installez l'application {companyName} sur votre ordinateur !
      </Typography>
      <img style={{borderRadius: 10}} width="64px" src={logo} alt="Icon" />
      <Grid my={2} container justifyContent="center">
        <Grid item sm={6} md={4}>
          <Button sx={{width: '100%'}} size="large" onClick={onAccept} variant="contained" color="primary">
            Installer {companyName}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WebPwaPrompt;
