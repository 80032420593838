import {useState} from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import {EMBED_EDITABLE_REPORT_SETTINGS, EMBED_REPORT_DEFAULT_SETTINGS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button';

const EditReportButton = () => {
  const {isReportInEditionMode, selectedReport, setIsReportInEditionMode} = useReport();
  const [isSwitching, setIsSwitching] = useState(false);

  const handleClick = async () => {
    setIsSwitching(true);

    await selectedReport.switchMode(isReportInEditionMode ? 'view' : 'edit');
    await selectedReport.updateSettings(isReportInEditionMode ? EMBED_REPORT_DEFAULT_SETTINGS : EMBED_EDITABLE_REPORT_SETTINGS);
    setIsReportInEditionMode(!isReportInEditionMode);
    setIsSwitching(false);
  };

  return (
    <Button disabled={isSwitching} onClick={handleClick} variant="contained" color="primary" startIcon={isReportInEditionMode ? <EditIcon /> : <EditOffIcon />}>
      {isReportInEditionMode ? 'Désactiver le mode édition' : 'Activer le mode édition'}
    </Button>
  );
};

export default EditReportButton;
