import {useEffect, useMemo, useRef, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, CHATBOT_MESSAGES_ROLES, CHATBOT_USER_MESSAGE_TYPES, MAXIMUM_NUMBER_OF_CHABOT_MESSAGES_IN_TRIAL, SNACKBAR_ACTIONS} from '../const';
import ChatbotContext from '../contexts/ChatbotContext';
import useHttp from '../hooks/misc/useHttp';
import useAuth from '../hooks/providers/useAuth';
import usePayment from '../hooks/providers/usePayment';
import useSnackbar from '../hooks/providers/useSnackbar';
import {createGptFormattedMessageForAssistant, createGptFormattedMessageForUser} from '../utils';

const ChatbotProvider = ({children}) => {
  const {_get, _post} = useHttp();
  const {showSnackbar} = useSnackbar();
  const auth = useAuth();
  const {isTrial} = usePayment();

  const [isChatbotSidebarOpen, setChatbotSidebarOpen] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [isWarningAboutDataPrivacyBackdropOpen, setWarningAboutDataPrivacyBackdropOpen] = useState(true);
  const [threadMessages, setThreadMessages] = useState([]);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [isThreadLoading, setIsThreadLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [shouldFetchThreadMessages, setShouldFetchThreadMessages] = useState(false);
  const [userHasScrolledUp, setUserHasScrolledUp] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [chatbotDataLoading, setChatbotDataLoading] = useState(false);
  const [isDeleteThreadModalOpen, setIsDeleteThreadModalOpen] = useState(false);
  const [threadIdToDelete, setThreadIdToDelete] = useState(null);
  const [sendableReportVisuals, setSendableReportVisuals] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [sendFileInProgress, setSendFileInProgress] = useState(false);
  const [isNavigatingToOtherReportPageToExportData, setIsNavigatingToOtherReportPageToExportData] = useState(false);
  const [isStreamingMessage, setIsStreamingMessage] = useState(false);
  const [streamedMessage, setStreamedMessage] = useState([]);
  const [isChatbotFeatureDisabled, setIsChatbotFeatureDisabled] = useState(false);

  const conversationContainerRef = useRef(null);

  const addNewThreadToThreadsList = ({title, threadId}) => {
    const threadCreationTime = Math.floor(new Date().getTime() / 1000);

    setThreads(currentThreads => [
      ...currentThreads,
      {
        creation_time: threadCreationTime,
        thread_name: title,
        thread_id: threadId
      }
    ]);
  };

  const scrollConversationToBottom = (behavior = 'instant') => {
    if (conversationContainerRef.current) {
      conversationContainerRef.current.scrollIntoView({behavior, block: 'end'});
      setUserHasScrolledUp(false);
    }
  };

  const addAssistantMessageToThread = message => {
    const gptFormattedAssistantMessage = createGptFormattedMessageForAssistant(message, selectedAssistant);
    setThreadMessages(currentMessages => [...currentMessages, gptFormattedAssistantMessage]);
    if (!userHasScrolledUp) {
      setTimeout(() => scrollConversationToBottom('instant'), 10);
    }
  };

  const addUserMessageToThread = (message, messageType = CHATBOT_USER_MESSAGE_TYPES.text) => {
    // We format user message to the same format of OpenAI API to ease its displaying in Message.js
    const gptFormattedUserMessage = createGptFormattedMessageForUser(message, messageType);
    // We directly add the user message to the thread, even before calling API.
    // If it errors in any way, we will still display the message, with an error, and propose the user to retry sending.
    setThreadMessages(currentMessages => [...currentMessages, gptFormattedUserMessage]);
    setTimeout(() => scrollConversationToBottom('instant'), 10);
  };

  const handleSSEMessage = dataString => {
    setIsStreamingMessage(true);

    setStreamedMessage(currentWords => {
      return [...currentWords, dataString];
    });
  };

  const getChatbotData = async () => {
    try {
      setChatbotDataLoading(true);
      const url = API_ENDPOINTS.chatbot.getAssistantsAndThreads;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 201) {
        setAssistants(data.assistants);
        setThreads(data.threads);
        return {
          success: true
        };
      }

      if (response.status === 403) {
        setIsChatbotFeatureDisabled(true);
        return {
          success: true
        };
      }

      throw Error(data?.message || data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    } finally {
      setChatbotDataLoading(false);
    }
  };

  const addMessageToThread = async (newMessage, threadId) => {
    console.log({fileIds});
    try {
      const url = API_ENDPOINTS.chatbot.addMessage;
      let isFirstThreadMessage = false;
      setStreamedMessage([]);

      if (!threadId) {
        // When threadId param is NOT present, it means we did not call addMessageToThread from createThread : we want to add user message to thread.
        addUserMessageToThread(newMessage);
      } else {
        // When threadId param is present, it means we called addMessageToThread from createThread : we want to set isFirstMessage to true
        isFirstThreadMessage = true;
      }

      return fetch(url, {
        method: 'POST',
        headers: {
          policy: process.env.REACT_APP_COMPANY_POLICY,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.tokenAad}`
        },
        body: JSON.stringify({
          first_message: isFirstThreadMessage,
          thread_id: currentThreadId || threadId,
          assistant_id: selectedAssistant.id,
          content: newMessage,
          file_ids: fileIds.length === 0 ? null : fileIds,
          textdeltas: true,
          env: process.env.REACT_APP_DB_ENV
        })
      })
        .then(async res => {
          if (res.body && res.body.pipeTo) {
            // eslint-disable-next-line no-undef
            const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
            const readStream = async () => {
              let streamingInProgress = true;
              while (streamingInProgress) {
                // eslint-disable-next-line no-await-in-loop
                const {value, done} = await reader.read();
                if (done) {
                  streamingInProgress = false;
                  break;
                }

                const finalValue = value?.replace(/\\n\\n/g, '\n\n')?.replace(/\\n/g, '\n');
                handleSSEMessage(finalValue);
              }
            };
            await readStream();

            setFileIds([]);
            return {
              success: true
            };
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error({error});
          // If an error has occurred , we delete message sent by user and display a snackbar
          setThreadMessages(currentMessages => currentMessages.slice(0, -1));
          showSnackbar(SNACKBAR_ACTIONS.SEND_CHATBOT_MESSAGE_ERROR, {severity: 'error'});
          return {
            success: true
          };
        })
        .finally(() => {
          console.warn('Finally called');
          setIsStreamingMessage(false);
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    }
  };

  const createThread = async firstMessage => {
    try {
      addUserMessageToThread(firstMessage);

      const url = API_ENDPOINTS.chatbot.create;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        const threadId = data;
        const temporaryTitle = firstMessage;

        setCurrentThreadId(threadId);
        addNewThreadToThreadsList({title: temporaryTitle, threadId});

        console.log({threadId});
        await addMessageToThread(firstMessage, threadId);
        return {
          data,
          success: true
        };
      }
      showSnackbar(SNACKBAR_ACTIONS.SEND_CHATBOT_MESSAGE_ERROR, {severity: 'error'});

      throw Error('error'); // TODO Check what API returns in case of error
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    }
  };

  const getThreadMessages = async threadId => {
    try {
      const url = API_ENDPOINTS.chatbot.getThreadMessages;
      const {response, responseJson: data} = await _post(url, {
        thread_id: threadId
      });

      if (response.status === 201) {
        const {files} = data;
        // We add filenames for each message to ease usage in component
        const messagesWithFiles = data.messages.map(m => {
          const messageWithFiles = {...m};
          if (m.file_ids.length > 0) {
            const associatedFiles = files.filter(f => m.file_ids.includes(f.file_id));
            messageWithFiles.files = associatedFiles;
          }

          return messageWithFiles;
        });

        setThreadMessages(messagesWithFiles);
        return {
          data,
          success: true
        };
      }
      throw Error(data?.message || data); // TODO Check what API returns in case of error
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    }
  };

  const deleteThread = async threadId => {
    try {
      const url = API_ENDPOINTS.chatbot.delete;
      const {response, responseJson: data} = await _post(url, {
        thread_id: threadId
      });

      if (response.status === 200) {
        setThreads(threadsBeforeDeletion => [...threadsBeforeDeletion.filter(t => t.thread_id !== threadId)]);

        // if we deleted the current selected conversation, set currentThreadId to null and empty thread messages
        if (threadId === currentThreadId) {
          setCurrentThreadId(null);
          setThreadMessages([]);
          setSelectedAssistant(null);
        }
        return {
          data,
          success: true
        };
      }
      throw Error(data?.message || data); // TODO Check what API returns in case of error
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    }
  };

  const sendAccountingFile = async (base64File, filename, threadId = null) => {
    try {
      addUserMessageToThread(filename, CHATBOT_USER_MESSAGE_TYPES.accountingFile);

      const url = API_ENDPOINTS.chatbot.sendFile;
      const {response, responseJson: data} = await _post(url, {
        thread_id: threadId,
        file_b64: base64File,
        filename
      });

      if (response.status === 200) {
        console.log('adding uploaded file id  to fileIds');
        // We add the file to the fileIds array, that keeps track of all file ids to associate to the next sent message
        setFileIds(prevIds => [...prevIds, data.file_id]);

        // The file has been sent in a new conversation, we set it as current thread
        if (!threadId) {
          setCurrentThreadId(data.thread_id);
          // We want this new thread to appear in the threads list
          addNewThreadToThreadsList({
            title: 'Data analysis',
            threadId: threadId || data.thread_id
          });
        }

        return {
          data,
          success: true
        };
      }

      // If an error has occured , we delete message sent by user and display a snackbar
      setThreadMessages(currentMessages => currentMessages.slice(0, -1));

      throw Error(data?.message || data); // TODO Check what API returns in case of error
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        message: e.message
      };
    } finally {
      setSendFileInProgress(false);
    }
  };

  const getLastAssistantMessage = messages => {
    let lastAssistantMessage = null;
    const orderedByDateMessages = messages.sort((a, b) => a.created_at - b.created_at);

    for (let i = orderedByDateMessages.length - 1; i >= 0; i--) {
      if (orderedByDateMessages[i].role === CHATBOT_MESSAGES_ROLES.assistant) {
        lastAssistantMessage = orderedByDateMessages[i];
        break; // Once we find the last assistant message, we can stop searching
      }
    }

    return lastAssistantMessage;
  };

  // This hook aims to :
  // - reset thread messages when user selects a new thread
  // - reset last used assistant when user selects a new thread
  useEffect(() => {
    (async () => {
      if (shouldFetchThreadMessages && currentThreadId) {
        setIsThreadLoading(true);
        const res = await getThreadMessages(currentThreadId);
        setShouldFetchThreadMessages(false);
        if (res.success) {
          const {messages} = res.data;
          const lastAssistantMessage = getLastAssistantMessage(messages);
          if (lastAssistantMessage) {
            const lastAssistant = assistants.find(a => a.id === lastAssistantMessage.assistant_id);
            setSelectedAssistant(lastAssistant);
          } else {
            // This case happens if a user message has not been answered for some reason, and the convo only has 1 message.
            // We have to reset some assistant if we cannot find a last assistant used
            setSelectedAssistant(assistants[0]);
          }
        }
        setIsThreadLoading(false);
      }
    })();
  }, [currentThreadId]);

  useEffect(() => {
    if (!isStreamingMessage && streamedMessage.length > 0) {
      console.warn('WILL addAssistantMessageToThread message');
      addAssistantMessageToThread(streamedMessage.join(''));
      setStreamedMessage([]);
    }
  }, [streamedMessage, isStreamingMessage]);

  // When coming from full-screen mode, the conversation might not be at the bottom automatically because of component smaller width
  // So this hook aims to automatically scroll to bottom of conversation when toggling from fullscreen mode
  useEffect(() => {
    if (!isFullScreenMode) {
      scrollConversationToBottom('instant');
    }
  }, [isFullScreenMode]);

  // Chatbot input is disabled for one of the following reasons :
  // - User has not checked the warning about data privacy
  // - User has not selected an assistant (eg: he just checked the warning privacy, but he must then select an assistant before sending a message)
  // - User has selected a conversation and the thread is currently loading
  // - User is in trial mode and has reached the limit of 25 messages
  const userHasReachedMaximumNumberOfMessages = isTrial && currentThreadId && threadMessages.filter(m => m.role === CHATBOT_MESSAGES_ROLES.user).length >= MAXIMUM_NUMBER_OF_CHABOT_MESSAGES_IN_TRIAL;
  const isTypingDisabled = isWarningAboutDataPrivacyBackdropOpen || !selectedAssistant || isThreadLoading || userHasReachedMaximumNumberOfMessages;

  const numberOfMessagesLeft = MAXIMUM_NUMBER_OF_CHABOT_MESSAGES_IN_TRIAL - threadMessages.filter(m => m.role === CHATBOT_MESSAGES_ROLES.user).length;

  const useMemoDeps = [
    isChatbotSidebarOpen,
    setChatbotSidebarOpen,
    getChatbotData,
    assistants,
    threads,
    selectedAssistant,
    setSelectedAssistant,
    isWarningAboutDataPrivacyBackdropOpen,
    setWarningAboutDataPrivacyBackdropOpen,
    isTypingDisabled,
    createThread,
    threadMessages,
    // addMessageToThread,
    isFullScreenMode,
    setIsFullScreenMode,
    currentThreadId,
    setCurrentThreadId,
    isThreadLoading,
    setThreadMessages,
    scrollConversationToBottom,
    conversationContainerRef,
    isSendingMessage,
    setIsSendingMessage,
    setShouldFetchThreadMessages,
    userHasScrolledUp,
    setUserHasScrolledUp,
    isAtBottom,
    setIsAtBottom,
    chatbotDataLoading,
    deleteThread,
    isDeleteThreadModalOpen,
    setIsDeleteThreadModalOpen,
    threadIdToDelete,
    setThreadIdToDelete,
    sendableReportVisuals,
    setSendableReportVisuals,
    sendAccountingFile,
    sendFileInProgress,
    setSendFileInProgress,
    isNavigatingToOtherReportPageToExportData,
    setIsNavigatingToOtherReportPageToExportData,
    fileIds,
    setFileIds,
    streamedMessage,
    isStreamingMessage,
    userHasReachedMaximumNumberOfMessages,
    numberOfMessagesLeft,
    isChatbotFeatureDisabled
  ];

  const value = useMemo(
    () => ({
      isChatbotSidebarOpen,
      setChatbotSidebarOpen,
      getChatbotData,
      assistants,
      threads,
      selectedAssistant,
      setSelectedAssistant,
      isWarningAboutDataPrivacyBackdropOpen,
      setWarningAboutDataPrivacyBackdropOpen,
      isTypingDisabled,
      createThread,
      threadMessages,
      addMessageToThread,
      isFullScreenMode,
      setIsFullScreenMode,
      currentThreadId,
      setCurrentThreadId,
      isThreadLoading,
      setThreadMessages,
      scrollConversationToBottom,
      conversationContainerRef,
      isSendingMessage,
      setIsSendingMessage,
      setShouldFetchThreadMessages,
      userHasScrolledUp,
      setUserHasScrolledUp,
      isAtBottom,
      setIsAtBottom,
      chatbotDataLoading,
      deleteThread,
      isDeleteThreadModalOpen,
      setIsDeleteThreadModalOpen,
      threadIdToDelete,
      setThreadIdToDelete,
      sendableReportVisuals,
      setSendableReportVisuals,
      sendAccountingFile,
      sendFileInProgress,
      setSendFileInProgress,
      isNavigatingToOtherReportPageToExportData,
      setIsNavigatingToOtherReportPageToExportData,
      fileIds,
      setFileIds,
      streamedMessage,
      isStreamingMessage,
      userHasReachedMaximumNumberOfMessages,
      numberOfMessagesLeft,
      isChatbotFeatureDisabled
    }),
    useMemoDeps
  );

  return <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>;
};

ChatbotProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ChatbotProvider;
