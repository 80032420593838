import {useState} from 'react';

import SaveIcon from '@mui/icons-material/Save';

import {SNACKBAR_ACTIONS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import useSnackbar from '../../../hooks/providers/useSnackbar';
import Button from '../../form/buttons/Button';

const SaveReportButton = () => {
  const {isReportInEditionMode, selectedReport} = useReport();
  const {showSnackbar} = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);

  const handleClick = async () => {
    setIsSaving(true);
    await selectedReport.save();
    const isReportSaved = await selectedReport.isSaved();
    if (isReportSaved) {
      showSnackbar(SNACKBAR_ACTIONS.REPORT_SUCCESSFULLY_SAVED);
    }
    setIsSaving(false);
  };

  if (isReportInEditionMode) {
    return (
      <Button disabled={isSaving} onClick={handleClick} variant="contained" color="success" startIcon={<SaveIcon />}>
        Sauvegarder
      </Button>
    );
  }
  return null;
};

export default SaveReportButton;
