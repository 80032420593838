import dayjs from 'dayjs';

const getHumanReadableSiren = siren => {
  const firstPart = siren.substring(0, 3);
  const secondPart = siren.substring(3, 6);
  const lastPart = siren.substring(6, 9);

  return `${firstPart} ${secondPart} ${lastPart}`;
};

const getHumanReadableSiret = siret => {
  const firstPart = siret.substring(0, 3);
  const secondPart = siret.substring(3, 6);
  const thirdPart = siret.substring(6, 9);
  const lastPart = siret.substring(9, 14);

  return `${firstPart} ${secondPart} ${thirdPart} ${lastPart}`;
};

const getHumanReadablePhoneNumber = phone => {
  const cleanedNumber = phone.replace(/\D/g, '');

  if (cleanedNumber === '') {
    return phone;
  }

  let formattedNumber = '';
  for (let i = 0; i < cleanedNumber.length; i += 2) {
    const isPhoneNumberEven = i + 2 <= cleanedNumber.length;
    const digitsToAdd = isPhoneNumberEven ? `${cleanedNumber.substring(i, i + 2)} ` : cleanedNumber.substring(i);
    formattedNumber += digitsToAdd;
  }

  return formattedNumber.trim();
};

const getTruncatedString = (string, maxLength = 20) => (string.length <= maxLength ? string : `${string.substring(0, maxLength)}...`);

const isProjectDemo = siren => {
  const demoSirenRegex = /^10000000\d[_\d\w-]*$/;
  return demoSirenRegex.test(siren);
};

const generateUniqueIdFromProjectName = str => {
  const charCodes = str.split('').map(char => char.charCodeAt(0));

  let hash = 0;
  for (let i = 0; i < charCodes.length; i++) {
    hash = (hash << 5) - hash + charCodes[i];
    hash |= 0;
  }

  return hash.toString();
};

const makeHumanFriendlyUrl = url => {
  let friendlyUrl = url;

  friendlyUrl = friendlyUrl.replace(/%20/g, '-');
  friendlyUrl = friendlyUrl.replace(/ /g, '-');
  friendlyUrl = friendlyUrl.toLowerCase();

  return friendlyUrl;
};

const getFrenchFormattedDate = date => {
  const options = {
    day: '2-digit',
    month: date instanceof Date ? '2-digit' : 'long',
    year: 'numeric'
  };
  if (date instanceof Date) {
    return date.toLocaleDateString('fr-FR', options);
  }
  const [day, month, year] = date.split('/');

  return new Date([month, day, year].join('/')).toLocaleDateString('fr-FR', options);
};

const getInternationalStandardDateFormat = dateInput => {
  let isoFormattedDate;

  if (typeof dateInput === 'string' && dateInput.includes('/')) {
    // If the input is a string and includes '/', assume it's in DD/MM/YYYY format
    const [day, month, year] = dateInput.split('/');
    [isoFormattedDate] = new Date(`${year}-${month}-${day}T00:00:00.000Z`).toISOString().split('T');
  } else {
    // If the input is not a string or doesn't include '/', assume it's in another format
    const inputDate = new Date(dateInput);
    [isoFormattedDate] = inputDate.toISOString().split('T');
  }

  return isoFormattedDate;
};

const isFrenchDateFormat = inputString => {
  const dateFormats = [/^\d{2}\/\d{2}\/\d{4}$/, /^\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} \w{3}\s\(\w+ \w+ \d{4}\)$/];

  const dateString = String(inputString);

  // eslint-disable-next-line no-restricted-syntax
  for (const dateFormat of dateFormats) {
    const match = dateString.match(dateFormat);
    if (match) {
      return true;
    }
  }

  return false;
};

const createDateObjectFromFrenchFormattedDate = dateString => {
  if (isFrenchDateFormat(dateString)) {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  return new Date(dateString);
};

const formatFrenchDateVerboseFromTimeStamp = timestamp => {
  dayjs.locale('fr'); // Set locale to French

  const date = dayjs.unix(timestamp); // Convert Unix timestamp to dayjs object
  return date.format('dddd D MMMM YYYY'); // Format the date according to your requirement
};

const getRoundedPercentage = (ratio, numberOfDecimals = 3) => {
  const roundedRatio = Number(ratio.toFixed(numberOfDecimals));
  const roundedPercentage = Number((roundedRatio * 100).toFixed(numberOfDecimals));
  return roundedPercentage;
};

const isTokenExpired = token => {
  return Date.now() > token.exp * 1000;
};

const getLastDayOfPreviousMonthFrenchFormatted = () => {
  const lastDayOfPreviousMonth = dayjs().subtract(1, 'month').endOf('month').format('DD/MM/YYYY');
  return lastDayOfPreviousMonth;
};

export {
  getHumanReadableSiren,
  getHumanReadableSiret,
  getTruncatedString,
  isProjectDemo,
  getHumanReadablePhoneNumber,
  generateUniqueIdFromProjectName,
  getFrenchFormattedDate,
  getInternationalStandardDateFormat,
  makeHumanFriendlyUrl,
  createDateObjectFromFrenchFormattedDate,
  getRoundedPercentage,
  isTokenExpired,
  formatFrenchDateVerboseFromTimeStamp,
  getLastDayOfPreviousMonthFrenchFormatted
};
