import React, {useEffect, useState} from 'react';

import {useLocation, useParams} from 'react-router-dom';

import useProfile from 'hooks/providers/useProfile';

import {makeHumanFriendlyUrl} from '../utils/data-formatting';

const IframeDisplay = () => {
  const location = useLocation();
  const {idDocument, idDocumentGroup} = useParams();
  const {getHelp, getHelpButtons} = useProfile();
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    if (getHelpButtons) {
      const categories = Object.keys(getHelpButtons).map(category => category);
      const documents = Object.values(getHelpButtons)
        .map(category => category)
        .flat();
      const categoryExists = categories.find(c => makeHumanFriendlyUrl(c) === idDocumentGroup);
      const documentExists = documents.find(d => makeHumanFriendlyUrl(d.subtitle) === idDocument);
      if (!categoryExists || !documentExists) {
        throw new Response('Not Found', {status: 404});
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!getHelpButtons) {
      getHelp();
    } else {
      let foundItem = null;

      Object.values(getHelpButtons).forEach(category => {
        category.forEach(item => {
          if (item.subtitle) {
            const formattedSubtitle = makeHumanFriendlyUrl(item.subtitle);
            if (formattedSubtitle === idDocument && item.src_iframe === 'canva') {
              foundItem = item;
            }
          }
        });
      });

      if (foundItem) {
        setIframeSrc(`${foundItem.link}?embed`);
      }
    }
  }, [getHelp, getHelpButtons, idDocument]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        paddingTop: '1.6em',
        paddingBottom: '0.9em'
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '90%',
          aspectRatio: '16/9',
          boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
          borderRadius: '8px'
        }}
      >
        {iframeSrc && (
          <iframe
            title="iframe"
            loading="lazy"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              border: 'none',
              padding: 0,
              margin: 0
            }}
            src={iframeSrc}
            allowFullScreen
            allow="fullscreen"
          />
        )}
      </div>
    </div>
  );
};

export default IframeDisplay;
