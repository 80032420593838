import {FormControl, InputLabel, Select} from '@mui/material';
import {arrayOf, bool, func, node, oneOfType, shape, string} from 'prop-types';

const FormSelect = ({sx, label, labelId, value, onChange, items, disabled}) => {
  return (
    <FormControl disabled={disabled} sx={sx}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select labelId={labelId} value={value} label={label} onChange={onChange}>
        {items}
      </Select>
    </FormControl>
  );
};

FormSelect.defaultProps = {
  sx: {},
  disabled: false
};

FormSelect.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool,
  sx: shape({}),
  label: string.isRequired,
  labelId: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  items: oneOfType([arrayOf(node), node]).isRequired
};

export default FormSelect;
