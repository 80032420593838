import {Typography} from '@mui/material';
import {func, oneOfType, string, node} from 'prop-types';

import Alert from '../alert/Alert';

const SubscriptionBanner = ({severity, title, children}) => {
  return (
    <Alert sx={{borderRadius: 0}} severity={severity} variant="filled">
      <Typography color="#fff" sx={{marginRight: '8px'}}>
        {title}
      </Typography>
      <div>{children}</div>
    </Alert>
  );
};

SubscriptionBanner.propTypes = {
  severity: string.isRequired,
  title: string.isRequired,
  children: oneOfType([node, func]).isRequired
};

export default SubscriptionBanner;
