import styled from 'styled-components';

import ChatbotWidget from './ChatbotWidget';

const WidgetsContainerDiv = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

// If we have more widgets in the future we can add an Accordion button especially for mobile
const WidgetsContainer = () => {
  return (
    <WidgetsContainerDiv>
      <ChatbotWidget />
    </WidgetsContainerDiv>
  );
};

export default WidgetsContainer;
