/* eslint-disable consistent-return */
import {useMemo, useState} from 'react';

import {models} from 'powerbi-client';
import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, DEFAULT_PROJECTS_SCHEMAS, POWER_BI_PAGES, SNACKBAR_ACTIONS, SPREADSHEET_TARGETS, SUMMARY_THEMES} from '../const';
import ReportContext from '../contexts/ReportContext';
import useHttp from '../hooks/misc/useHttp';
import useSnackbar from '../hooks/providers/useSnackbar';
import {getDistinctBudgetYearsFromData, getSelectedTabInChargesPage} from '../utils';

const ReportProvider = ({children}) => {
  const [reports, setReports] = useState([]);
  const [reportsFetchInProgress, setReportsFetchInProgress] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null); // Hack to prevent object from mutating for header select
  const [embedToken, setEmbedToken] = useState(null);
  const [isTokenError, setIsTokenError] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState(Date.now());
  const [createBudgetError, setCreateBudgetError] = useState('');
  const [autoSaveDisabled, setAutoSaveDisabled] = useState(JSON.parse(localStorage.getItem('autoSaveDisabled')));
  const [spreadsheetDataLoaded, setSpreadsheetDataLoaded] = useState(false);
  const [userCanUploadVisuals, setUserCanUploadVisuals] = useState(false);
  const [isCreatingOrUpdatingBudget, setIsCreatingOrUpdatingBudget] = useState(false);
  const [reportDataSuccessfullyUpdated, setReportDataSuccessfullyUpdated] = useState(false);
  const [isCreateOrUpdateBudgetFormOpen, setIsCreateOrUpdateBudgetFormOpen] = useState(false);
  const [isCreateBudgetForm, setIsCreateBudgetForm] = useState(false);
  const [isDeleteBudgetDialogOpen, setIsDeleteBudgetDialogOpen] = useState(false);
  const [spreadsheetMenuOpen, setSpreadsheetMenuOpen] = useState(false);
  const [isSpreadsheetModalOpen, setIsSpreadsheetModalOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedBudgetYears, setSelectedBudgetYears] = useState([]);
  const [alreadyTakenBudgetNames, setAlreadyTakenBudgetNames] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isReportInEditionMode, setIsReportInEditionMode] = useState(false);
  const [isEditableReportSaveAsModalOpen, setIsEditableReportSaveAsModalOpen] = useState(false);
  const [reportSelectedPage, setReportSelectedPage] = useState({});
  const [reportModule, setReportModule] = useState(null);
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [shouldApplyPreviousFiltersStepCompleted, setShouldApplyPreviousFiltersStepCompleted] = useState(false);
  const [isApplyPreviousFiltersModalOpen, setIsApplyPreviousFiltersModalOpen] = useState(false);
  const [isReportAudioPlaying, setIsReportAudioPlaying] = useState(false);
  const [summaryTheme, setSummaryTheme] = useState('');
  const [summaryHasBeenUpdated, setSummaryHasBeenUpdated] = useState(false);
  const [reportAudios, setReportAudios] = useState([]);
  const [currentBookmark, setCurrentBookmark] = useState(null);

  const {_post, _get} = useHttp();
  const {showSnackbar} = useSnackbar();

  const loadReports = async () => {
    if (reportsFetchInProgress) return;
    setReportsFetchInProgress(true);

    const url = API_ENDPOINTS.reports.getToken;
    const {response: tokenResponse, responseJson: tokenJson} = await _get(url);

    if (!tokenResponse.ok) {
      // eslint-disable-next-line no-console
      console.error('failed to get embed token from python');
      return {success: false};
    }

    try {
      if (tokenJson && tokenResponse.status === 200) {
        setIsTokenError(false);
        setEmbedToken(tokenJson.token);
        setReports(tokenJson.reports.sort((a, b) => a.report_name.localeCompare(b.report_name)));
        setTokenExpiration(tokenJson.expiration);
        setReportsFetchInProgress(false);
        return {success: true, tokenData: tokenJson};
      }
      if (tokenJson && tokenResponse.status === 201) {
        setReports([]);
        setReportsFetchInProgress(false);
        return {success: false};
      }
    } catch (_e) {
      setIsTokenError(true);
      setReportsFetchInProgress(false);
      return {success: false};
    }
  };

  // For more info on business need , see : https://github.com/Adrian1903/drivn-powerbi-embed/issues/316
  const createDefiPreviReportBudgetData = async (siren, {budgetName, budgetYear, budgetReferenceYear}) => {
    const url = API_ENDPOINTS.reports.createDefiPreviReportBudgetData;
    setCreateBudgetError('');
    try {
      const {response} = await _post(url, {
        siren,
        budget_name: budgetName,
        budget_year: budgetYear,
        reference_year: budgetReferenceYear
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_DEFI_PREVI_BUDGET_SUCCESS);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getSpreadsheetData = async (siren, target) => {
    const url = API_ENDPOINTS.reports.getSpreadsheetData;
    setCreateBudgetError('');
    setSelectedBudgetYears([]);
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        target // 'forecast' or 'budget'
      });

      if (target === SPREADSHEET_TARGETS.BUDGET) {
        const budgetYears = getDistinctBudgetYearsFromData(data);
        setSelectedBudgetYears(budgetYears);
      }

      return {data, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const saveUpdatedSpreadsheetData = async (siren, target, editedData, budget = null) => {
    const url = API_ENDPOINTS.reports.updateSpreadsheetData;
    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        target, // 'forecast' or 'budget',
        data: editedData,
        budget_name: budget
      });

      if (response.status === 400) {
        showSnackbar(data, {
          severity: 'error',
          duration: 5000
        });
      }

      return {status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const saveAsReport = async (workspaceId, schema, reportName) => {
    const url = API_ENDPOINTS.reports.saveAs;
    try {
      const {response, responseJson: data} = await _post(url, {
        workspace_id: workspaceId,
        schema,
        report_name: reportName
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.REPORT_SUCCESSFULLY_SAVED);
      }

      return {status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const enableOrDisableAutoSave = () => {
    localStorage.setItem('autoSaveDisabled', !autoSaveDisabled);
    setAutoSaveDisabled(!autoSaveDisabled);
  };

  const createOrUpdateBudget = async ({budgetName: newBudgetName, budgetYear, budgetReferenceYear, siren}) => {
    setIsCreatingOrUpdatingBudget(true);
    const result = await createDefiPreviReportBudgetData(siren, {
      budgetName: newBudgetName,
      budgetYear,
      budgetReferenceYear
    });
    if (result.status === 200) {
      selectedReport.refresh();
      setSpreadsheetDataLoaded(false);
      setIsCreateOrUpdateBudgetFormOpen(false);
      setSpreadsheetMenuOpen(false);
    }
    setIsCreatingOrUpdatingBudget(false);
  };

  const deleteBudget = async ({budgetName, budgetYear = null, siren}) => {
    const url = API_ENDPOINTS.reports.deleteDefiPreviReportBudgetData;
    try {
      const {response} = await _post(url, {
        siren,
        budget_name: budgetName,
        ...(budgetYear && {budget_year: parseInt(budgetYear, 10)})
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.DELETE_DEFI_PREVI_BUDGET_SUCCESS);
        selectedReport.refresh();
        setSpreadsheetDataLoaded(false);
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const updateSummary = async ({newSummary, theme, siren}) => {
    const url = API_ENDPOINTS.reports.updateSummary;
    setSummaryHasBeenUpdated(false);
    try {
      showSnackbar(SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY, {
        severity: 'warning',
        autoHide: false,
        hasSpinner: true
      });

      const {response} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        theme,
        summary: newSummary
      });

      if (response.status === 200) {
        setSummaryHasBeenUpdated(true);
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_REPORT_SUMMARY_SUCCESS);
        selectedReport.refresh();
      }

      return {success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getReportThemeAudio = async ({theme, siren}) => {
    const url = API_ENDPOINTS.reports.getReportThemeAudio;
    try {
      const {response, responseJson: data} = await _post(url, {
        // TODO : API might evolve but for now, only DeFi Gestion has this feature so schema is static
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        theme
      });

      if (response.status === 200) {
        const newAudio = {data, theme, siren};
        setReportAudios(currentAudios => {
          const newReportAudios = [...currentAudios];
          const existingAudioIndex = newReportAudios.findIndex(a => a.siren === siren && a.theme === theme);
          if (existingAudioIndex !== -1) {
            newReportAudios.splice(existingAudioIndex, 1);
          }
          newReportAudios.push(newAudio);
          return newReportAudios;
        });
      }

      return {data, success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const getSummaryTheme = async report => {
    const currentPage = await report.getActivePage();
    let theme;

    if (currentPage.name === POWER_BI_PAGES.reportHome.id) {
      theme = SUMMARY_THEMES.ca;
    }

    if (currentPage.name === POWER_BI_PAGES.reportCharges.id) {
      theme = await getSelectedTabInChargesPage(currentPage);
    }
    return theme;
  };

  const sendAccountantDocumentRequest = async ({siren, data, userId, userEmail}) => {
    const url = API_ENDPOINTS.reports.sendDocumentRequest;
    try {
      const {response, responseJson} = await _post(url, {
        schema: DEFAULT_PROJECTS_SCHEMAS.gestion,
        siren,
        content: data,
        ...(userId && {to_user_id: userId}),
        ...(userEmail && {to_usermail: userEmail})
      });

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.SEND_DOCUMENT_REQUEST_SUCCESS);
      }

      return {data, success: response.status === 200, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {success: false};
    }
  };

  const isReportEditable = reports?.find(r => r.report_id === selectedReportId)?.isEditable || false;
  const isDesktopLayout = selectedReport?.config.settings.layoutType === models.LayoutType.Master;

  const value = useMemo(
    () => ({
      reports,
      setReports,
      selectedReport,
      setSelectedReport,
      selectedReportId,
      setSelectedReportId,
      embedToken,
      loadReports,
      isTokenError,
      tokenExpiration,
      setTokenExpiration,
      createDefiPreviReportBudgetData,
      createBudgetError,
      setCreateBudgetError,
      getSpreadsheetData,
      saveUpdatedSpreadsheetData,
      enableOrDisableAutoSave,
      autoSaveDisabled,
      spreadsheetDataLoaded,
      setSpreadsheetDataLoaded,
      saveAsReport,
      userCanUploadVisuals,
      setUserCanUploadVisuals,
      isCreatingOrUpdatingBudget,
      setIsCreatingOrUpdatingBudget,
      reportDataSuccessfullyUpdated,
      setReportDataSuccessfullyUpdated,
      isCreateOrUpdateBudgetFormOpen,
      setIsCreateOrUpdateBudgetFormOpen,
      createOrUpdateBudget,
      isCreateBudgetForm,
      setIsCreateBudgetForm,
      isDeleteBudgetDialogOpen,
      setIsDeleteBudgetDialogOpen,
      deleteBudget,
      spreadsheetMenuOpen,
      setSpreadsheetMenuOpen,
      isSpreadsheetModalOpen,
      setIsSpreadsheetModalOpen,
      selectedBudget,
      setSelectedBudget,
      selectedBudgetYears,
      alreadyTakenBudgetNames,
      setAlreadyTakenBudgetNames,
      updateSummary,
      selectedProject,
      setSelectedProject,
      isReportInEditionMode,
      setIsReportInEditionMode,
      isEditableReportSaveAsModalOpen,
      setIsEditableReportSaveAsModalOpen,
      reportSelectedPage,
      setReportSelectedPage,
      reportModule,
      setReportModule,
      isReportEditable,
      filtersInitialized,
      setFiltersInitialized,
      filtersLoading,
      setFiltersLoading,
      shouldApplyPreviousFiltersStepCompleted,
      setShouldApplyPreviousFiltersStepCompleted,
      isApplyPreviousFiltersModalOpen,
      setIsApplyPreviousFiltersModalOpen,
      isReportAudioPlaying,
      setIsReportAudioPlaying,
      getReportThemeAudio,
      summaryTheme,
      setSummaryTheme,
      getSummaryTheme,
      summaryHasBeenUpdated,
      reportAudios,
      setReportAudios,
      isDesktopLayout,
      currentBookmark,
      setCurrentBookmark,
      sendAccountantDocumentRequest
    }),
    [
      reports,
      setReports,
      selectedReport?.config.id,
      selectedReportId,
      setSelectedReport,
      embedToken,
      isTokenError,
      loadReports,
      createDefiPreviReportBudgetData,
      createBudgetError,
      setCreateBudgetError,
      getSpreadsheetData,
      saveUpdatedSpreadsheetData,
      enableOrDisableAutoSave,
      autoSaveDisabled,
      spreadsheetDataLoaded,
      setSpreadsheetDataLoaded,
      saveAsReport,
      userCanUploadVisuals,
      setUserCanUploadVisuals,
      isCreatingOrUpdatingBudget,
      setIsCreatingOrUpdatingBudget,
      reportDataSuccessfullyUpdated,
      setReportDataSuccessfullyUpdated,
      isCreateOrUpdateBudgetFormOpen,
      setIsCreateOrUpdateBudgetFormOpen,
      createOrUpdateBudget,
      isCreateBudgetForm,
      setIsCreateBudgetForm,
      isDeleteBudgetDialogOpen,
      setIsDeleteBudgetDialogOpen,
      deleteBudget,
      spreadsheetMenuOpen,
      setSpreadsheetMenuOpen,
      isSpreadsheetModalOpen,
      setIsSpreadsheetModalOpen,
      selectedBudget,
      setSelectedBudget,
      selectedBudgetYears,
      alreadyTakenBudgetNames,
      setAlreadyTakenBudgetNames,
      updateSummary,
      selectedProject,
      setSelectedProject,
      isReportInEditionMode,
      setIsReportInEditionMode,
      isEditableReportSaveAsModalOpen,
      setIsEditableReportSaveAsModalOpen,
      reportSelectedPage,
      setReportSelectedPage,
      reportModule,
      setReportModule,
      isReportEditable,
      filtersInitialized,
      setFiltersInitialized,
      filtersLoading,
      setFiltersLoading,
      shouldApplyPreviousFiltersStepCompleted,
      setShouldApplyPreviousFiltersStepCompleted,
      isApplyPreviousFiltersModalOpen,
      setIsApplyPreviousFiltersModalOpen,
      isReportAudioPlaying,
      setIsReportAudioPlaying,
      getReportThemeAudio,
      summaryTheme,
      setSummaryTheme,
      getSummaryTheme,
      summaryHasBeenUpdated,
      reportAudios,
      setReportAudios,
      isDesktopLayout,
      currentBookmark,
      setCurrentBookmark,
      sendAccountantDocumentRequest
    ]
  );

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
};
ReportProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ReportProvider;
