import CloseIcon from '@mui/icons-material/Close';
import {Alert, Snackbar, IconButton, Grid, createTheme, ThemeProvider} from '@mui/material';
import Lottie from 'lottie-react';
import {bool, func, number, string} from 'prop-types';

import animationFile from '../../lotties/spinner/whiteSpinnerAnimation.json';
import parentTheme from '../../styles/mui-theme';

const theme = createTheme({
  ...parentTheme,
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        },
        message: {
          display: 'flex',
          alignItems: 'center',
          color: 'white'
        }
      }
    }
  }
});

const CustomSnackbar = ({isOpen, onClose, duration, text, severity, hasSpinner}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={isOpen} autoHideDuration={duration} onClose={handleClose}>
      <div>
        <ThemeProvider theme={theme}>
          <Alert
            variant="filled"
            severity={severity}
            icon={hasSpinner ? false : undefined}
            action={
              !hasSpinner ? (
                <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : null
            }
          >
            {hasSpinner && (
              <Grid mr={1.5}>
                <Lottie style={{height: 32, width: 32}} loop animationData={animationFile} />
              </Grid>
            )}

            {text}
          </Alert>
        </ThemeProvider>
      </div>
    </Snackbar>
  );
};

CustomSnackbar.defaultProps = {
  duration: 4000,
  severity: 'success',
  isOpen: false,
  hasSpinner: false
};

CustomSnackbar.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  duration: number,
  text: string.isRequired,
  severity: string,
  hasSpinner: bool
};

export default CustomSnackbar;
