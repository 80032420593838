import {isResponsive} from '../../utils';
import useBreakpoint from '../dom/useBreakpoint';

const useCreateReportOrWorkspaceButton = ({maxLimitReached, onClick, tooltipTitle}) => {
  const currentBreakpoint = useBreakpoint();
  const responsive = isResponsive(currentBreakpoint);

  const handleClick = () => {
    if (maxLimitReached) return;
    if (onClick) onClick();
  };

  return {
    responsive,
    handleClick,
    tooltipTitle: maxLimitReached ? tooltipTitle : ''
  };
};

export default useCreateReportOrWorkspaceButton;
