import {Link as ReactRouterDomLink} from 'react-router-dom';

import {APP_ROUTES, MAXIMUM_NUMBER_OF_CHABOT_THREADS} from '../../../const';
import useChatbot from '../../../hooks/providers/useChatbot';
import usePayment from '../../../hooks/providers/usePayment';
import Alert from '../../alert/Alert';

// eslint-disable-next-line react/prop-types
const TrialModeMessagesBanner = ({children, severity}) => (
  <Alert
    sx={{
      position: 'sticky',
      top: 54,
      width: 'calc(100% - 32px)',
      zIndex: 9999,
      py: 1
    }}
    variant="filled"
    severity={severity}
  >
    {children}
  </Alert>
);

// eslint-disable-next-line react/prop-types
const MaximumNumberOfTrialMessagesBanner = ({handleDrawerClose}) => {
  const {numberOfMessagesLeft, threads, isThreadLoading, currentThreadId} = useChatbot();
  const {isTrial} = usePayment();
  const userHasReachedMaximumNumberOfConversations = isTrial && threads.length >= MAXIMUM_NUMBER_OF_CHABOT_THREADS;
  const userHasReachedMaximumNumberOfMessages = numberOfMessagesLeft === 0;
  const numberOfConversationsLeft = MAXIMUM_NUMBER_OF_CHABOT_THREADS - threads.length;
  const messageWord = numberOfMessagesLeft > 1 ? 'messages' : 'message';
  const conversationWord = numberOfConversationsLeft > 1 ? 'conversations' : 'conversation';

  const getAlertSeverity = () => {
    if (userHasReachedMaximumNumberOfConversations && userHasReachedMaximumNumberOfMessages) {
      return 'warning';
    }
    return 'success';
  };

  const getAlertContent = () => {
    if (userHasReachedMaximumNumberOfConversations && userHasReachedMaximumNumberOfMessages) {
      return (
        <>
          Vous avez atteint la limite de messages et de conversations. Pour débloquer cette limite,&nbsp;
          <ReactRouterDomLink to={APP_ROUTES.profile} onClick={handleDrawerClose}>
            sélectionnez un abonnement
          </ReactRouterDomLink>
        </>
      );
    }

    if (userHasReachedMaximumNumberOfMessages && !userHasReachedMaximumNumberOfConversations) {
      return (
        <span>
          Il vous reste 0 message et {numberOfConversationsLeft} {conversationWord}. Créez une conversation pour envoyer plus de messages
        </span>
      );
    }

    if (!userHasReachedMaximumNumberOfMessages && !userHasReachedMaximumNumberOfConversations) {
      return (
        <span>
          Il vous reste {numberOfMessagesLeft} {messageWord} et {numberOfConversationsLeft} {conversationWord}. Créez une conversation pour envoyer plus de messages.
        </span>
      );
    }

    if (!userHasReachedMaximumNumberOfMessages && userHasReachedMaximumNumberOfConversations) {
      return (
        <>
          Il vous reste {numberOfMessagesLeft} {messageWord}.&nbsp;
          <ReactRouterDomLink to={APP_ROUTES.profile} onClick={handleDrawerClose}>
            Choisissez un abonnement
          </ReactRouterDomLink>
          &nbsp; pour débloquer plus de conversations
        </>
      );
    }
  };

  if (!isTrial || !currentThreadId || isThreadLoading) {
    return null;
  }

  return (
    <TrialModeMessagesBanner severity={getAlertSeverity()}>
      <div>
        <b>Version d'essai.</b>
        &nbsp;
        {getAlertContent()}
      </div>
    </TrialModeMessagesBanner>
  );
};

export default MaximumNumberOfTrialMessagesBanner;
