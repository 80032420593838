import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, TIIME_OAUTH_CALLBACK_REDIRECT_URI} from '../const';
import TiimeProjectsContext from '../contexts/TiimeProjectsContext';
import useHttp from '../hooks/misc/useHttp';
import useProjects from '../hooks/providers/useProjects';
import {generateTiimeLoginRedirectUrl} from '../utils';

const TiimeProjectsProvider = ({children}) => {
  const {_post, _get} = useHttp();
  const {setCreateError, setIsSubmitting, fetchProjects} = useProjects();

  const [isRedirectingToTiimeAuthenticationServer, setIsRedirectingToTiimeAuthenticationServer] = useState(false);
  const [tiimeCompanies, setTiimeCompanies] = useState([]);
  const [tiimeProjectToLink, setTiimeProjectToLink] = useState(null);
  const [tiimeProjectCreationError, setTiimeProjectCreationError] = useState('');
  const [tiimeProjectSuccessfullyImported, setTiimeProjectSuccessfullyImported] = useState(false);
  const [isGeneratingToken, setIsGeneratingToken] = useState(false);
  const [checkingTokenDimmerText, setCheckingTokenDimmerText] = useState('');

  // We pass a param isCompaniesFetching to determine wether we want to check token + get companies
  // Or we already checked the token but user requested a companies list refresh. We use this to generate a different spinner message.
  const checkTiimeToken = async (isCompaniesFetching = false) => {
    const url = API_ENDPOINTS.projects.tiime.checkToken;
    setTiimeCompanies([]);
    setCheckingTokenDimmerText(isCompaniesFetching ? 'Mise à jour des entreprises' : 'Connexion à Tiime');
    try {
      setIsRedirectingToTiimeAuthenticationServer(true);
      const {response, responseJson: data} = await _get(url);
      console.log({data, response});

      // Tiime token is expired or invalid. Open Popup window to redirect user to Tiime login
      if (response.status === 400) {
        const loginUrl = generateTiimeLoginRedirectUrl();
        window.location.href = loginUrl;

        return {
          success: false
        };
      }

      // Token is valid, we get Tiime data
      if (response.status === 200) {
        setIsRedirectingToTiimeAuthenticationServer(false);
        setTiimeCompanies(data);
        return {
          success: true
        };
      }
      throw Error('Error during checkTiimeToken');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false
      };
    }
  };

  const generateTiimeRefreshToken = async code => {
    const url = API_ENDPOINTS.projects.tiime.createToken;

    try {
      const {response, responseJson: data} = await _post(url, {
        code,
        redirect_uri: TIIME_OAUTH_CALLBACK_REDIRECT_URI
      });

      if (response.status === 200) {
        setTiimeCompanies(data);
        return {
          success: true,
          data
        };
      }
      throw Error('Error during generateTiimeRefreshToken');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      return {
        success: false
      };
    }
  };

  const createTiimeFolder = async siren => {
    setTiimeProjectCreationError('');
    setCreateError('');
    setIsSubmitting(true);
    setTiimeProjectSuccessfullyImported(false);
    const url = API_ENDPOINTS.projects.create.tiime;
    const companyId = tiimeCompanies.find(c => c.siren === siren)?.company_id;

    try {
      const {response, responseJson: data} = await _post(url, {
        siren,
        company_id: companyId
      });

      if (response.status === 200) {
        await fetchProjects();
        setTiimeProjectSuccessfullyImported(true);
        setTiimeProjectToLink(null);
        return {
          success: true
        };
      }

      if (response.status === 400) {
        setTiimeProjectCreationError(data);
        return {
          success: false
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({e});
      setCreateError(e);
      return {
        success: false
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  const memoizedValues = {
    checkTiimeToken,
    generateTiimeRefreshToken,
    isRedirectingToTiimeAuthenticationServer,
    setIsRedirectingToTiimeAuthenticationServer,
    tiimeCompanies,
    setTiimeCompanies,
    tiimeProjectToLink,
    setTiimeProjectToLink,
    createTiimeFolder,
    setTiimeProjectCreationError,
    tiimeProjectCreationError,
    tiimeProjectSuccessfullyImported,
    setTiimeProjectSuccessfullyImported,
    isGeneratingToken,
    setIsGeneratingToken,
    checkingTokenDimmerText
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <TiimeProjectsContext.Provider value={value}>{children}</TiimeProjectsContext.Provider>;
};

TiimeProjectsProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default TiimeProjectsProvider;
