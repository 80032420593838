import {Box, Fab, Tooltip} from '@mui/material';

import chatbotIconSvg from 'img/chatbot-icon.svg';

import useChatbot from '../../hooks/providers/useChatbot';

// eslint-disable-next-line react/prop-types
const FabContainer = ({disabled, ...props}) => {
  const WrapperComponent = disabled ? Tooltip : Box;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrapperComponent {...props} />;
};

const ChatbotWidget = () => {
  const {setChatbotSidebarOpen, isChatbotFeatureDisabled, chatbotDataLoading} = useChatbot();
  const disabled = isChatbotFeatureDisabled || chatbotDataLoading;

  return (
    <FabContainer disabled={disabled} title={disabled ? 'Contactez-nous pour activer Copilote' : ''} placement="left">
      <div>
        <Fab disabled={disabled} color="secondary" onClick={() => setChatbotSidebarOpen(true)}>
          <img alt="chatbot icon" src={chatbotIconSvg} style={{maxWidth: '60%'}} />
        </Fab>
      </div>
    </FabContainer>
  );
};

export default ChatbotWidget;
