import {MAXIMUM_NUMBER_OF_WORKSPACES} from '../../const';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import CreateReportOrWorkspaceButton from './CreateReportOrWorkspaceButton';

const CreateWorkspaceButton = () => {
  const {numberOfWorkspaces, openCreateWorkspaceModal} = useWorkspaces();
  const maximumNumberOfWorkspacesReached = numberOfWorkspaces >= MAXIMUM_NUMBER_OF_WORKSPACES;

  const handleButtonClick = maximumNumberOfWorkspacesReached ? null : openCreateWorkspaceModal;

  return (
    <CreateReportOrWorkspaceButton
      maxLimitReached={maximumNumberOfWorkspacesReached}
      onClick={handleButtonClick}
      tooltipTitle="Vous avez atteint la limite d'espaces personnels que vous pouvez créer."
      label="Ajouter un espace"
      size="medium"
    />
  );
};

export default CreateWorkspaceButton;
